import axios from 'axios';

const httpGetTemplates = (params) => {
  return axios.get('/api/v2/usertemplate', { params });
};

const httpSearchTemplates = (payload) => {
  return axios.post('/api/v2/usertemplate/search', payload);
};

const httpGetSystemTemplates = (type, params) => {
  return axios.get(`/api/v2/systemtemplate/type/${type}/status/published`, {
    params,
  });
};

const httpGetDeletedSystemTemplates = (type, params) => {
  return axios.get(`/api/v2/systemtemplate/type/${type}/status/deleted`, {
    params,
  });
};

const httpDeleteTemplates = (type, payload) => {
  return axios.delete(`/api/v2/usertemplate/type/${type}`, {
    data: payload,
  });
};

const httpDeleteSystemTemplate = (id, deleteType) => {
  return axios.delete(`/api/v2/systemtemplate/${id}`, {
    headers: {
      deleteType,
    },
  });
};

const httpGetTemplate = (id) => {
  return axios.get(`/api/v2/usertemplate/${id}`);
};

const httpGetSystemTemplate = (id) => {
  return axios.get(`/api/v2/systemtemplate/${id}`);
};

const httpSearchSystemTemplates = (payload) => {
  return axios.post('/api/v2/systemtemplate/search', payload);
};

const httpCreateTemplate = (type, payload) => {
  return axios.post(`/api/v2/usertemplate/type/${type}`, payload);
};

const httpCreateSystemTemplate = (type, payload) => {
  return axios.post(`/api/v2/systemtemplate/type/${type}`, payload);
};

const httpUpdateTemplate = (id, payload) => {
  return axios.patch(`/api/v2/usertemplate/${id}`, payload);
};

const httpUpdateSystemTemplate = (id, payload) => {
  return axios.patch(`/api/v2/systemtemplate/${id}`, payload);
};

const httpDeleteTemplate = (id) => {
  return axios.delete(`/api/v2/usertemplate/${id}`);
};

const httpDuplicateTemplate = (id, copySharedInfo, params) => {
  return axios.post(
    `/api/v2/usertemplate/${id}/clone?copySharedInfo=${copySharedInfo}`,
    null,
    { params },
  );
};

const httpChangeStatusTemplate = (id, status) => {
  return axios.put(`/api/v2/usertemplate/${id}/status/${status}`);
};

const httpSendTestEmails = (id, payload) => {
  return axios.post(`/api/v2/usertemplate/sendtestmail/${id}`, payload);
};

const httpGetTemplateSize = (payload) => {
  return axios.post('/api/v2/usertemplate/calculate', payload);
};

const httpBulkUnpublishTemplates = (payload) => {
  return axios.patch('/api/v2/usertemplate/status/draft', payload);
};

const httpBulkSoftDeleteTemplates = (payload) => {
  return axios.patch('/api/v2/usertemplate/status/deleted', payload);
};

const httpBulkSoftDeleteSystemTemplates = (payload) => {
  return axios.delete('/api/v2/systemtemplate', {
    data: payload,
    headers: {
      deleteType: 'soft_delete',
    },
  });
};

const httpBulkHardDeleteSystemTemplates = (payload) => {
  return axios.delete('/api/v2/systemtemplate', {
    data: payload,
    headers: {
      deleteType: 'hard_delete',
    },
  });
};

const httpGetSharedTemplates = (params) => {
  return axios.get('/api/v2/usertemplate/sharing', { params });
};

const httpUpdateSharedTemplateCollaborators = (id, payload) => {
  return axios.patch(`/api/v2/usertemplate/sharing/${id}`, payload);
};

const httpUpdateSharedTemplateLockStatus = ({ id, lockStatus }) => {
  return axios.patch(`/api/v2/usertemplate/sharing/${id}/lock/${lockStatus}`);
};

const httpSendUnlockRequestSharedTemplate = ({
  collaboratorId,
  templateId,
}) => {
  return axios.post(
    `/api/v2/usertemplate/sharing/email/unlock/${collaboratorId}/${templateId}`,
  );
};

export {
  httpGetTemplates,
  httpSearchTemplates,
  httpGetSystemTemplates,
  httpGetDeletedSystemTemplates,
  httpDeleteTemplates,
  httpDeleteSystemTemplate,
  httpGetTemplate,
  httpGetSystemTemplate,
  httpSearchSystemTemplates,
  httpCreateTemplate,
  httpCreateSystemTemplate,
  httpUpdateTemplate,
  httpUpdateSystemTemplate,
  httpDeleteTemplate,
  httpDuplicateTemplate,
  httpChangeStatusTemplate,
  httpSendTestEmails,
  httpGetTemplateSize,
  httpBulkUnpublishTemplates,
  httpBulkSoftDeleteTemplates,
  httpBulkSoftDeleteSystemTemplates,
  httpBulkHardDeleteSystemTemplates,
  httpGetSharedTemplates,
  httpUpdateSharedTemplateCollaborators,
  httpUpdateSharedTemplateLockStatus,
  httpSendUnlockRequestSharedTemplate,
};
