import React from 'react';
import { SketchPicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
import { useOutsideListener } from 'hooks';
import { useEditorContext } from 'routes/Editor/EditorContext';

function EmailBody() {
  const {
    generalSettings: { width, padding, bgColor },
    onChangeGeneralSettings,
  } = useEditorContext();

  const [colorPickerRef, showColorPicker, setShowColorPicker] =
    useOutsideListener(false);

  return (
    <div className="general-settings-modal__content">
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.email-body-tab.email-width.range-title" />
            : {width}px
          </div>
          <div className="general-settings-modal__form-item range-wrapper">
            200px
            <input
              className="general-settings-modal__form-item range"
              type="range"
              name="width"
              min="200"
              max="700"
              step="10"
              value={width}
              onChange={(e) =>
                onChangeGeneralSettings(e.target.name, e.target.value)
              }
            />
            700px
          </div>
          <div className="general-settings-modal__form-item-info">
            <FormattedMessage id="general-settings-modal.email-body-tab.email-width.range-description" />
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.email-body-tab.email-width.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.email-body-tab.email-width.helper-description" />
          </p>
        </div>
      </div>
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.email-body-tab.default-padding.selector-title" />
          </div>
          <div className="general-settings-modal__form-item number-range">
            <button
              type="button"
              onClick={() => onChangeGeneralSettings('padding', padding - 1)}>
              -
            </button>
            <div>{padding}px</div>
            <button
              type="button"
              onClick={() => onChangeGeneralSettings('padding', padding + 1)}>
              +
            </button>
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.email-body-tab.default-padding.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.email-body-tab.default-padding.helper-description" />
          </p>
        </div>
      </div>
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.email-body-tab.body-bg-color.color-picker-title" />
          </div>
          <div className="general-settings-modal__form-item color-picker">
            <div className="color-picker__input">
              <input
                type="text"
                name="bgColor"
                value={bgColor}
                onChange={(e) =>
                  onChangeGeneralSettings(e.target.name, e.target.value)
                }
              />
              <button
                style={{
                  backgroundColor: bgColor,
                }}
                type="button"
                onClick={() => setShowColorPicker((prev) => !prev)}
              />
            </div>
            {showColorPicker && (
              <div className="color-picker__popup" ref={colorPickerRef}>
                <SketchPicker
                  disableAlpha
                  color={bgColor}
                  onChange={(color) =>
                    onChangeGeneralSettings('bgColor', color.hex)
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.email-body-tab.body-bg-color.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.email-body-tab.body-bg-color.helper-description" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default EmailBody;
