import { tableStyle, cellStyle } from 'appConstants';

export default function (editor, opt = {}) {
  const bm = editor.BlockManager;

  bm.add('column48', {
    label: opt.labelColumn48,
    category: opt.category,
    content: `
      <table data-type="section" style="${tableStyle}">
        <tr>
          <td style="${cellStyle} width: 33%"></td>
          <td style="${cellStyle} width: 66%"></td>
        </tr>
      </table>
    `,
  });

  bm.add('column84', {
    label: opt.labelColumn84,
    category: opt.category,
    content: `
      <table data-type="section" style="${tableStyle}">
        <tr>
          <td style="${cellStyle} width: 66%"></td>
          <td style="${cellStyle} width: 33%"></td>
        </tr>
      </table>
    `,
  });

  bm.add('column39', {
    label: opt.labelColumn39,
    category: opt.category,
    content: `
      <table data-type="section" style="${tableStyle}">
        <tr>
          <td style="${cellStyle} width: 25%"></td>
          <td style="${cellStyle} width: 75%"></td>
        </tr>
      </table>
    `,
  });

  bm.add('column93', {
    label: opt.labelColumn93,
    category: opt.category,
    content: `
      <table data-type="section" style="${tableStyle}">
        <tr>
          <td style="${cellStyle} width: 75%"></td>
          <td style="${cellStyle} width: 25%"></td>
        </tr>
      </table>
    `,
  });

  bm.add('column3-7', {
    label: opt.labelColumn37,
    category: opt.category,
    content: `
    <table data-type="section" style="${tableStyle}">
      <tr>
        <td style="${cellStyle} width:30%"></td>
        <td style="${cellStyle} width:70%"></td>
      </tr>
      </table>
    `,
  });

  bm.add('column4', {
    label: opt.labelColumn4,
    category: opt.category,
    content: `
      <table data-type="section" style="${tableStyle}">
        <tr>
          <td style="${cellStyle} width: 25%"></td>
          <td style="${cellStyle} width: 25%"></td>
          <td style="${cellStyle} width: 25%"></td>
          <td style="${cellStyle} width: 25%"></td>
        </tr>
      </table>
    `,
  });

  bm.add('root', {
    label: 'Page Wrapper',
    attributes: { class: 'bpreview-page-wrapper' },
    category: opt.category,
    content: `
      <table
        data-type="root"
        style="
          width: 100%;
          padding: 0 20px;
          max-width: 700px;
          height: 100%;
          margin: 0 auto;
          background-color: #ffffff;
        ">
        <tbody data-type="root-body">
          <tr data-type="root-row">
            <td
              data-type="root-cell"
              style="vertical-align: top;
              width: 100%; height: 100%;"
            ></td>
          </tr>
        </tbody>
      </table>
    `,
  });
}
