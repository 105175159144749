import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { useToggleTypes } from 'hooks';
import { useSelector } from 'react-redux';
import { isAdminSelector } from 'store/selectors';
import Input from 'components/Forms/Input';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import './EditorContent.sass';

function EditorContent({
  editor,
  searchBlocksTerm,
  onChangeSearchBlocksTerm,
  onShow,
  blocksTab,
  customModuleGroupsCount,
  setModuleGroupTempCategory,
  setIsComponentSettingsCollapsed,
  isComponentSettingsCollapsed,
}) {
  const intl = useIntl();

  const isAdmin = useSelector(isAdminSelector);

  const [isBlocksCollapsed, setIsBlocksCollapsed] = useState(false);
  const [isTraitsCollapsed, setIsTraitsCollapsed] = useState(false);
  const [disablePointerEvents, setDisablePointerEvents] = useState(false);

  const toggleComponentSettings = () => {
    setIsComponentSettingsCollapsed(!isComponentSettingsCollapsed);
  };

  const toggleBlocksCollapsing = () => {
    setIsBlocksCollapsed((prevIsBlocksCollapsed) => !prevIsBlocksCollapsed);
    setDisablePointerEvents(true);
  };

  const toggleTraitsCollapsing = () => {
    setIsTraitsCollapsed((prevIsTraitsCollapsed) => !prevIsTraitsCollapsed);
    setDisablePointerEvents(true);
  };

  const handleTransitionEnd = (e) => {
    if (e.target === e.currentTarget) {
      setDisablePointerEvents(false);
      editor.refresh();
    }
  };

  return (
    <div className="editor-content">
      <div
        className={cx('editor-content__column', 'editor-content__blocks', {
          collapsed: isBlocksCollapsed,
          disabled: disablePointerEvents,
        })}
        data-testid="blocks sidebar"
        onTransitionEnd={handleTransitionEnd}>
        <div className="custom-module-preview">
          <img
            className="custom-module-preview__image"
            src="/images/image-loading.svg"
            alt=""
          />
          <hr className="custom-module-preview__divider" />
          <h1 className="custom-module-preview__title">Custom module title</h1>
          <p className="custom-module-preview__description">
            Custom module Description
          </p>
        </div>
        <Button
          aria-label="collapse blocks sidebar"
          variant="outlined"
          color="primary"
          className="editor-content__collapse-button"
          onClick={toggleBlocksCollapsing}>
          {isBlocksCollapsed ? (
            <span className="icon icon-chevron-right" />
          ) : (
            <span className="icon icon-chevron-left" />
          )}
        </Button>
        <div className="editor-content__panel-blocks-tabs" />
        <div className="editor-content__blocks-list-wrapper">
          <Input
            aria-label="search blocks"
            className="editor-content__blocks-search-input"
            placeholder={intl.formatMessage({
              id: 'editor.search-input-placeholder',
            })}
            value={searchBlocksTerm}
            onChange={onChangeSearchBlocksTerm}
            after={<span className="icon icon-search" />}
          />
          {isAdmin && blocksTab === 'modules' && (
            <div className="editor-content__blocks-list-wrapper__top-action">
              <div>
                {`${customModuleGroupsCount} ${intl.formatMessage({
                  id: 'editor.custom-modules-group-label',
                })}`}
              </div>
              <div className="editor-content__blocks-list-wrapper__creat-group-btn">
                <span>
                  {intl.formatMessage({
                    id: 'custom-modules-category.add-action-title',
                  })}
                </span>
                <IconButton
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    onShow(useToggleTypes.showCustomModulCategoryModal);
                    setModuleGroupTempCategory(null);
                  }}>
                  <span className="icon icon-add" />
                </IconButton>
              </div>
            </div>
          )}
          <div className="editor-content__blocks-list" />
        </div>
      </div>
      <div className="editor-content__column editor-content__canvas-wrapper">
        <div className="editor-content__canvas-actions">
          <div className="editor-content__panel-canvas-controls" />
          <div className="editor-content__panel-canvas-history" />
          <div className="editor-content__panel-zoom-actions">
            <div className="editor-content__panel-zoom-actions__btn" />
            <div className="editor-content__panel-zoom-actions__view">
              <span>100</span>%
            </div>
          </div>
          <div className="editor-content__panel-responsive-actions" />
        </div>
        <div id="canvas" className="editor-content__canvas" />
      </div>
      <div
        className={cx('editor-content__column', 'editor-content__traits', {
          collapsed: isTraitsCollapsed,
          disabled: disablePointerEvents,
        })}
        data-testid="traits sidebar"
        onTransitionEnd={handleTransitionEnd}>
        <Button
          aria-label="collapse traits sidebar"
          variant="outlined"
          color="primary"
          className="editor-content__collapse-button"
          onClick={toggleTraitsCollapsing}>
          {isTraitsCollapsed ? (
            <span className="icon icon-chevron-left" />
          ) : (
            <span className="icon icon-chevron-right" />
          )}
        </Button>
        <div className="editor-content__panel-traits-tabs" />
        <div className="editor-content__traits-container">
          <div
            id="component-settings"
            className={cx('component-settings', {
              open: isComponentSettingsCollapsed,
            })}>
            <div
              role="button"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();

                  toggleComponentSettings();
                }
              }}
              onClick={toggleComponentSettings}
              className="component-settings__header">
              <FormattedMessage id="editor-settings.component-settings-title" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

EditorContent.propTypes = {
  editor: PropTypes.object,
  searchBlocksTerm: PropTypes.string.isRequired,
  onChangeSearchBlocksTerm: PropTypes.func.isRequired,
  onShow: PropTypes.func,
  blocksTab: PropTypes.string,
  customModuleGroupsCount: PropTypes.number,
  setModuleGroupTempCategory: PropTypes.func,
  setIsComponentSettingsCollapsed: PropTypes.func,
  isComponentSettingsCollapsed: PropTypes.bool,
};

export default EditorContent;
