import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import './EmptyContent.sass';

function EmptyContent({ status = '' }) {
  return (
    <div className="empty-content">
      <div className="empty-content__wrapper">
        <h3 className="empty-content__title">
          <div>
            {status === 'shared' ? (
              <FormattedMessage id="home.empty-shared-title" />
            ) : (
              <FormattedMessage id="home.empty-title" />
            )}
          </div>
        </h3>
        <p className="empty-content__subtitle">
          {status === 'shared' ? (
            <FormattedMessage id="home.empty-shared-subtitle" />
          ) : (
            <FormattedMessage id="home.empty-subtitle" />
          )}
        </p>
      </div>
    </div>
  );
}

EmptyContent.propTypes = {
  status: PropTypes.string,
};

export default EmptyContent;
