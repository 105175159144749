import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { generateId } from 'helpers';
import './Input.sass';

const Input = React.forwardRef(function Input(
  {
    id,
    error,
    type = 'text',
    multiline,
    className,
    fullWidth,
    before,
    after,
    disabled,
    ...rest
  },
  ref,
) {
  // generating unique id for input
  const inputId = useRef(generateId('dnd-input'));

  const input = (
    <input
      id={id || inputId.current}
      className="dnd-input__input"
      type={type}
      disabled={disabled}
      aria-invalid={error}
      {...rest}
      ref={ref}
    />
  );
  const textarea = (
    <textarea
      id={id || inputId.current}
      className="dnd-input__textarea"
      disabled={disabled}
      aria-invalid={error}
      {...rest}
      ref={ref}
    />
  );

  return (
    <div className={cx('dnd-input', className, { fullWidth, error, disabled })}>
      <div className="dnd-input__before">{before}</div>
      {multiline ? textarea : input}
      <div className="dnd-input__after">{after}</div>
    </div>
  );
});

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  id: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['text', 'textarea', 'number', 'email']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  before: PropTypes.node,
  after: PropTypes.node,
  disabled: PropTypes.bool,
};

export default Input;
