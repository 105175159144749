import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { VERSION } from 'appConstants';
import { closeEmbedEditor } from 'helpers';
import { useQuery, useModal } from 'hooks';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import EditorMode from 'components/EditorMode';
import BaseModal from 'components/Modals/BaseModal';
import SwitchUsers from 'components/Modals/SwitchUsers';

import './Header.sass';

const Header = ({ status, isSystem, setNewSessionId }) => {
  const { embed } = useQuery();

  const {
    isOpen: isOpenSwitchUsers,
    open: openSwitchUsers,
    close: closeSwitchUsers,
  } = useModal();

  return (
    <header className="templates-header">
      <Link to={{ pathname: '/' }}>
        <Logo className="templates-header__logo" />
      </Link>
      <div className="templates-header__content">
        <Breadcrumb system={isSystem} status={status} />
        <div className="templates-header__buttons">
          <EditorMode version={VERSION} type="header" />
          {embed && (
            <Button
              type="button"
              className="templates-header__cancel-btn"
              onClick={() => closeEmbedEditor()}>
              <FormattedMessage id="button.cancel" />
            </Button>
          )}
          {(window.location.hostname === 'localhost' ||
            window.location.hostname === 'mail-editor-dev.waymore.io') && (
            <Button
              type="button"
              className="templates-header__switch-users-btn"
              onClick={openSwitchUsers}>
              <FormattedMessage id="button.switch-users" />
            </Button>
          )}
        </div>
      </div>
      {(window.location.hostname === 'localhost' ||
        window.location.hostname === 'mail-editor-dev.waymore.io') && (
        <BaseModal
          isOpen={isOpenSwitchUsers}
          maxWidth="500px"
          headerTitle="modal-switch-users.modal-header"
          onClose={closeSwitchUsers}>
          <SwitchUsers
            setNewSessionId={setNewSessionId}
            closeSwitchUsers={closeSwitchUsers}
          />
        </BaseModal>
      )}
    </header>
  );
};

Header.propTypes = {
  isSystem: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  setNewSessionId: PropTypes.func.isRequired,
};

export default injectIntl(Header);
