import { FONTS, PARAGRAPH_SELECTOR, HEADER_SELECTOR } from 'appConstants';
import { showErrorMessage, setRuleStyle } from 'helpers';
import { useToggleTypes } from 'hooks';

export default (editor) => {
  const cmd = editor.Commands;

  cmd.add('open-general-settings', {
    run(editor) {
      const cssComposer = editor.CssComposer;
      const model = editor.getModel();
      const wrapper = editor.getWrapper();
      const pageTableWrapper = wrapper.findType('root')[0];
      const textStyles = cssComposer?.getRule('p')?.getStyle();
      const linkStyles = cssComposer?.getRule('a')?.getStyle();
      const h1Styles = cssComposer?.getRule('h1')?.getStyle();
      const h2Styles = cssComposer?.getRule('h2')?.getStyle();
      const h3Styles = cssComposer?.getRule('h3')?.getStyle();
      const h4Styles = cssComposer?.getRule('h4')?.getStyle();
      const h5Styles = cssComposer?.getRule('h5')?.getStyle();
      const h6Styles = cssComposer?.getRule('h6')?.getStyle();
      const onShow = model.get('onShow');
      const onSetGeneralSettings = model.get('onSetGeneralSettings');

      // default settings
      const settings = {
        width: '700',
        padding: 20,
        bgColor: '#ffffff',
        headerFonts: FONTS[0].value,
        paragraphFonts: FONTS[0].value,
        lineSpacing: '100%',
        paragraphBottomSpace: false,
        underlineLinks: false,
        h1HeadingFontSize: 40,
        h1HeadingFontColor: '#000',
        h1HeadingBold: false,
        h1HeadingItalic: false,
        h1HeadingBottomSpace: 18,
        h2HeadingFontSize: 32,
        h2HeadingFontColor: '#000',
        h2HeadingBold: false,
        h2HeadingItalic: false,
        h2HeadingBottomSpace: 15,
        h3HeadingFontSize: 28,
        h3HeadingFontColor: '#000',
        h3HeadingBold: false,
        h3HeadingItalic: false,
        h3HeadingBottomSpace: 12,
        h4HeadingFontSize: 24,
        h4HeadingFontColor: '#000',
        h4HeadingBold: false,
        h4HeadingItalic: false,
        h4HeadingBottomSpace: 10,
        h5HeadingFontSize: 20,
        h5HeadingFontColor: '#000',
        h5HeadingBold: false,
        h5HeadingItalic: false,
        h5HeadingBottomSpace: 8,
        h6HeadingFontSize: 16,
        h6HeadingFontColor: '#000',
        h6HeadingBold: false,
        h6HeadingItalic: false,
        h6HeadingBottomSpace: 8,
      };

      const wrapperStyle = wrapper.getStyle();

      // Headings font size
      if (h1Styles && 'font-size' in h1Styles) {
        settings.h1HeadingFontSize = Number(
          h1Styles['font-size'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h2Styles && 'font-size' in h2Styles) {
        settings.h2HeadingFontSize = Number(
          h2Styles['font-size'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h3Styles && 'font-size' in h3Styles) {
        settings.h3HeadingFontSize = Number(
          h3Styles['font-size'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h4Styles && 'font-size' in h4Styles) {
        settings.h4HeadingFontSize = Number(
          h4Styles['font-size'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h5Styles && 'font-size' in h5Styles) {
        settings.h5HeadingFontSize = Number(
          h5Styles['font-size'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h6Styles && 'font-size' in h6Styles) {
        settings.h6HeadingFontSize = Number(
          h6Styles['font-size'].replace(/[^\d.-]/g, ''),
        );
      }

      // Headings bottom space
      if (h1Styles && 'margin-bottom' in h1Styles) {
        settings.h1HeadingBottomSpace = Number(
          h1Styles['margin-bottom'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h2Styles && 'margin-bottom' in h2Styles) {
        settings.h2HeadingBottomSpace = Number(
          h2Styles['margin-bottom'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h3Styles && 'margin-bottom' in h3Styles) {
        settings.h3HeadingBottomSpace = Number(
          h3Styles['margin-bottom'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h4Styles && 'margin-bottom' in h4Styles) {
        settings.h4HeadingBottomSpace = Number(
          h4Styles['margin-bottom'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h5Styles && 'margin-bottom' in h5Styles) {
        settings.h5HeadingBottomSpace = Number(
          h5Styles['margin-bottom'].replace(/[^\d.-]/g, ''),
        );
      }

      if (h6Styles && 'margin-bottom' in h6Styles) {
        settings.h6HeadingBottomSpace = Number(
          h6Styles['margin-bottom'].replace(/[^\d.-]/g, ''),
        );
      }

      // Headings font color
      if (h1Styles && 'color' in h1Styles) {
        settings.h1HeadingFontColor = h1Styles['color'];
      }

      if (h2Styles && 'color' in h2Styles) {
        settings.h2HeadingFontColor = h2Styles['color'];
      }

      if (h3Styles && 'color' in h3Styles) {
        settings.h3HeadingFontColor = h3Styles['color'];
      }

      if (h4Styles && 'color' in h4Styles) {
        settings.h4HeadingFontColor = h4Styles['color'];
      }

      if (h5Styles && 'color' in h5Styles) {
        settings.h5HeadingFontColor = h5Styles['color'];
      }

      if (h6Styles && 'color' in h6Styles) {
        settings.h6HeadingFontColor = h6Styles['color'];
      }

      // Headings font weight & style
      settings.h1HeadingBold =
        (h1Styles &&
          'font-weight' in h1Styles &&
          h1Styles['font-weight'] === '700') ||
        false;

      settings.h1HeadingItalic =
        (h1Styles &&
          'font-style' in h1Styles &&
          h1Styles['font-style'] === 'italic') ||
        false;

      settings.h2HeadingBold =
        (h2Styles &&
          'font-weight' in h2Styles &&
          h2Styles['font-weight'] === '700') ||
        false;

      settings.h2HeadingItalic =
        (h2Styles &&
          'font-style' in h2Styles &&
          h2Styles['font-style'] === 'italic') ||
        false;

      settings.h3HeadingBold =
        (h3Styles &&
          'font-weight' in h3Styles &&
          h3Styles['font-weight'] === '700') ||
        false;

      settings.h3HeadingItalic =
        (h3Styles &&
          'font-style' in h3Styles &&
          h3Styles['font-style'] === 'italic') ||
        false;

      settings.h4HeadingBold =
        (h4Styles &&
          'font-weight' in h4Styles &&
          h4Styles['font-weight'] === '700') ||
        false;

      settings.h4HeadingItalic =
        (h4Styles &&
          'font-style' in h4Styles &&
          h4Styles['font-style'] === 'italic') ||
        false;

      settings.h5HeadingBold =
        (h5Styles &&
          'font-weight' in h5Styles &&
          h5Styles['font-weight'] === '700') ||
        false;
      settings.h5HeadingItalic =
        (h5Styles &&
          'font-style' in h5Styles &&
          h5Styles['font-style'] === 'italic') ||
        false;

      settings.h6HeadingBold =
        (h6Styles &&
          'font-weight' in h6Styles &&
          h6Styles['font-weight'] === '700') ||
        false;

      settings.h6HeadingItalic =
        (h6Styles &&
          'font-style' in h6Styles &&
          h6Styles['font-style'] === 'italic') ||
        false;

      if (textStyles && 'line-height' in textStyles) {
        settings.lineSpacing = textStyles['line-height'];
      }

      settings.paragraphBottomSpace =
        (textStyles &&
          'padding-bottom' in textStyles &&
          textStyles['padding-bottom'] === '1%') ||
        false;

      settings.underlineLinks =
        (linkStyles &&
          'text-decoration' in linkStyles &&
          linkStyles['text-decoration'] === 'underline') ||
        false;

      if (wrapperStyle['background-color']) {
        settings.bgColor = wrapperStyle['background-color'];
      }

      if (pageTableWrapper) {
        const style = pageTableWrapper.getStyle();

        if (style['max-width']) {
          settings.width = style['max-width'].replace('px', '');
        }

        // checking only left padding because it has the same value
        // as right padding
        if (style['padding-left']) {
          settings.padding = parseInt(style['padding-left']);
        }

        if (style['line-height']) {
          settings.lineSpacing = style['line-height'];
        }
      }

      const headerRule = cssComposer.getRule(HEADER_SELECTOR);

      if (headerRule) {
        const style = headerRule.get('style');

        if (style['font-family']) {
          settings.headerFonts = style['font-family'];
        }
      }

      const paragraphRule = cssComposer.getRule(PARAGRAPH_SELECTOR);

      if (paragraphRule) {
        const style = paragraphRule.get('style');

        if (style['font-family']) {
          settings.paragraphFonts = style['font-family'];
        }
      }

      // this is generalSettings state here src\routes\Editor\Grapes\Grapes.js
      onSetGeneralSettings(settings);

      // toggle general settings modal
      onShow(useToggleTypes.showGeneralSettings);
    },
  });

  cmd.add('set-general-settings', {
    run(
      editor,
      sender,
      {
        width,
        bgColor,
        padding,
        lineSpacing,
        headerFonts,
        paragraphFonts,
        paragraphBottomSpace,
        underlineLinks,
        h1HeadingFontSize,
        h1HeadingFontColor,
        h1HeadingBold,
        h1HeadingItalic,
        h1HeadingBottomSpace,
        h2HeadingFontSize,
        h2HeadingFontColor,
        h2HeadingBold,
        h2HeadingItalic,
        h2HeadingBottomSpace,
        h3HeadingFontSize,
        h3HeadingFontColor,
        h3HeadingBold,
        h3HeadingItalic,
        h3HeadingBottomSpace,
        h4HeadingFontSize,
        h4HeadingFontColor,
        h4HeadingBold,
        h4HeadingItalic,
        h4HeadingBottomSpace,
        h5HeadingFontSize,
        h5HeadingFontColor,
        h5HeadingBold,
        h5HeadingItalic,
        h5HeadingBottomSpace,
        h6HeadingFontSize,
        h6HeadingFontColor,
        h6HeadingBold,
        h6HeadingItalic,
        h6HeadingBottomSpace,
      },
    ) {
      const cssComposer = editor.CssComposer;
      const wrapper = editor.getWrapper();
      const pageTableWrapper = wrapper.findType('root')[0];

      // showing user error message if page-wrapper component isn't on the page
      if (!pageTableWrapper) {
        showErrorMessage(
          'You cannot apply settings to the template. Please add page wrapper to canvas and all setting will be applied to it',
        );

        return;
      }

      pageTableWrapper.addStyle({
        'max-width': `${width}px`,
        'padding-right': `${padding}px`,
        'padding-left': `${padding}px`,
      });

      setRuleStyle(editor, 'h1', {
        'font-size': `${h1HeadingFontSize}px`,
        'margin-bottom': `${h1HeadingBottomSpace}px`,
        color: `${h1HeadingFontColor}`,
        'font-weight': `${h1HeadingBold ? 700 : 'normal'}`,
        'font-style': `${h1HeadingItalic ? 'italic' : 'normal'}`,
      });

      setRuleStyle(editor, 'h2', {
        'font-size': `${h2HeadingFontSize}px`,
        'margin-bottom': `${h2HeadingBottomSpace}px`,
        color: `${h2HeadingFontColor}`,
        'font-weight': `${h2HeadingBold ? 700 : 'normal'}`,
        'font-style': `${h2HeadingItalic ? 'italic' : 'normal'}`,
      });

      setRuleStyle(editor, 'h3', {
        'font-size': `${h3HeadingFontSize}px`,
        'margin-bottom': `${h3HeadingBottomSpace}px`,
        color: `${h3HeadingFontColor}`,
        'font-weight': `${h3HeadingBold ? 700 : 'normal'}`,
        'font-style': `${h3HeadingItalic ? 'italic' : 'normal'}`,
      });

      setRuleStyle(editor, 'h4', {
        'font-size': `${h4HeadingFontSize}px`,
        'margin-bottom': `${h4HeadingBottomSpace}px`,
        color: `${h4HeadingFontColor}`,
        'font-weight': `${h4HeadingBold ? 700 : 'normal'}`,
        'font-style': `${h4HeadingItalic ? 'italic' : 'normal'}`,
      });

      setRuleStyle(editor, 'h5', {
        'font-size': `${h5HeadingFontSize}px`,
        'margin-bottom': `${h5HeadingBottomSpace}px`,
        color: `${h5HeadingFontColor}`,
        'font-weight': `${h5HeadingBold ? 700 : 'normal'}`,
        'font-style': `${h5HeadingItalic ? 'italic' : 'normal'}`,
      });

      setRuleStyle(editor, 'h6', {
        'font-size': `${h6HeadingFontSize}px`,
        'margin-bottom': `${h6HeadingBottomSpace}px`,
        color: `${h6HeadingFontColor}`,
        'font-weight': `${h6HeadingBold ? 700 : 'normal'}`,
        'font-style': `${h6HeadingItalic ? 'italic' : 'normal'}`,
      });

      setRuleStyle(editor, 'p', {
        'line-height': `${lineSpacing}`,
        'padding-bottom': `${paragraphBottomSpace ? '1%' : '0'}`,
      });

      setRuleStyle(editor, 'a', {
        'text-decoration': `${underlineLinks ? 'underline' : 'none'}`,
      });

      cssComposer.setRule(HEADER_SELECTOR, {
        'font-family': headerFonts,
      });

      cssComposer.setRule(PARAGRAPH_SELECTOR, {
        'font-family': paragraphFonts,
      });

      wrapper.addStyle({
        'background-color': bgColor,
      });

      // workaround in order to update styles of component
      const selected = editor.getSelected();
      editor.selectToggle(selected);
      editor.select(selected);
    },
  });
};
