import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  httpChangeStatusTemplate,
  httpCreateTemplate,
  httpCreateSystemTemplate,
  httpDeleteSystemTemplate,
  httpDeleteTemplate,
  httpDeleteTemplates,
  httpDuplicateTemplate,
  httpGetSystemTemplates,
  httpGetDeletedSystemTemplates,
  httpGetTemplate,
  httpGetSystemTemplate,
  httpGetTemplates,
  httpSearchTemplates,
  httpSearchSystemTemplates,
  httpSendTestEmails,
  httpUpdateSystemTemplate,
  httpUpdateTemplate,
  httpBulkUnpublishTemplates,
  httpBulkSoftDeleteTemplates,
  httpBulkSoftDeleteSystemTemplates,
  httpBulkHardDeleteSystemTemplates,
  httpGetSharedTemplates,
  httpUpdateSharedTemplateCollaborators,
  httpUpdateSharedTemplateLockStatus,
  httpSendUnlockRequestSharedTemplate,
} from 'api';
import { SORTBYLIST, TEMPLATE_STATUS } from 'appConstants';
import { getErrorText, showErrorMessage, showSuccessMessage } from 'helpers';

const getTemplates = createAsyncThunk(
  'template/getTemplates',
  async ({
    type = 'email',
    sortBy = SORTBYLIST[0],
    status = null,
    limit = 20,
    offset = 0,
    hideHtml = true,
  } = {}) => {
    try {
      const params = { type, sortBy, status, limit, offset, hideHtml };

      const { data } = await httpGetTemplates(params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getFavoritedTemplates = createAsyncThunk(
  'template/getFavoritedTemplates',
  async ({
    type = 'email',
    limit = 20,
    offset = 0,
    sortBy = SORTBYLIST[0],
    orderDirection = 'DESC',
  } = {}) => {
    try {
      const body = {
        limit,
        offset,
        orderDirection,
        orderBy: sortBy,
        criteria: [
          {
            key: 'type',
            value: type,
            operation: 'EQUAL',
            operator: 'AND',
          },
          {
            key: 'tags',
            value: 'favorite',
            operation: 'CONTAINS',
            operator: 'OR',
          },
        ],
      };

      const { data } = await httpSearchTemplates(body);

      return { data };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const searchTemplates = createAsyncThunk(
  'template/searchTemplates',
  async ({
    type = 'email',
    limit = 20,
    offset = 0,
    sortBy = SORTBYLIST[0],
    searchTerm = '',
    orderDirection = 'DESC',
    status = '',
  } = {}) => {
    try {
      const body = {
        limit,
        offset,
        orderDirection,
        orderBy: sortBy,
        criteria: [
          {
            key: 'status',
            value: status,
            operation: 'EQUAL',
            operator: 'AND',
          },
          {
            key: 'type',
            value: type,
            operation: 'EQUAL',
            operator: 'AND',
          },
          {
            key: 'name',
            value: searchTerm,
            operation: 'CONTAINS',
            operator: 'OR',
          },
          {
            key: 'tags',
            value: searchTerm,
            operation: 'CONTAINS',
            operator: 'OR',
          },
        ],
      };

      const { data } = await httpSearchTemplates(body);

      return { status, data };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const searchSystemTemplates = createAsyncThunk(
  'template/searchSystemTemplates',
  async ({
    type = 'email',
    sortBy = SORTBYLIST[0],
    searchTerm = '',
    orderDirection = 'DESC',
    status = '',
  } = {}) => {
    try {
      const body = {
        orderDirection,
        orderBy: sortBy,
        criteria: [
          {
            key: 'status',
            value: status,
            operation: 'EQUAL',
            operator: 'AND',
          },
          {
            key: 'isSystem',
            value: 'true',
            operation: 'EQUAL',
            operator: 'AND',
          },
          {
            key: 'type',
            value: type,
            operation: 'EQUAL',
            operator: 'AND',
          },
          // {
          //   key: 'categories.id',
          //   value: '',
          //   operation: 'EQUAL',
          //   operator: 'AND',
          // },
          {
            key: 'name',
            value: searchTerm,
            operation: 'CONTAINS',
            operator: 'OR',
          },
          {
            key: 'tags',
            value: searchTerm,
            operation: 'CONTAINS',
            operator: 'OR',
          },
        ],
      };

      const { data } = await httpSearchSystemTemplates(body);

      return { status, data };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const setNextTemplatesPage = createAction('setNextTemplatesPage');

const setNextDeletedSystemTemplatesPage = createAction(
  'setNextDeletedSystemTemplatesPage',
);

const getDeletedSystemTemplates = createAsyncThunk(
  'template/getDeletedSystemTemplates',
  async ({
    type = 'email',
    sortBy = SORTBYLIST[0],
    hideHtml = true,
    offset = 0,
    limit = 20,
  } = {}) => {
    try {
      let params = { sortBy, hideHtml, offset, limit };

      const { data } = await httpGetDeletedSystemTemplates(type, params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteTemplates = createAsyncThunk(
  'template/deleteTemplates',
  async (type = 'email') => {
    try {
      await httpDeleteTemplates(type);
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getTemplate = createAsyncThunk(
  'template/getTemplate',
  async ({ id, currentUser }) => {
    const { data, status } = await httpGetTemplate(id);

    if (
      status === 200 &&
      data?.status === TEMPLATE_STATUS.DRAFT &&
      data?.isShared &&
      !data?.isLocked &&
      data?.userId !== currentUser
    ) {
      httpUpdateSharedTemplateLockStatus({ id, lockStatus: true });
    }

    return data;
  },
);

const getSystemTemplate = createAsyncThunk(
  'template/getSystemTemplate',
  async (id) => {
    const { data } = await httpGetSystemTemplate(id);

    return data;
  },
);

const createTemplate = createAsyncThunk(
  'template/createTemplate',
  async ({ type = 'email', payload } = {}) => {
    try {
      const { data } = await httpCreateTemplate(type, payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const createSystemTemplate = createAsyncThunk(
  'template/createSystemTemplate',
  async ({ type = 'email', payload } = {}) => {
    try {
      const { data } = await httpCreateSystemTemplate(type, payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateTemplate = createAsyncThunk(
  'template/updateTemplate',
  async ({ id, payload } = {}) => {
    try {
      const { data, status } = await httpUpdateTemplate(id, payload);

      if (status === 202) {
        const err = new Error(
          `The system only supports templates up to 10MB in size and the current draft template is ${
            Number.parseFloat((+data?.size / Math.pow(1024, 1)).toFixed(1)) || 0
          }MB. Please update your template accordingly. Thank you.`,
        );
        err.name = 'oversize';

        throw err;
      }

      return data;
    } catch (error) {
      showErrorMessage(
        error.name === 'oversize' ? error?.message : getErrorText(),
        { autoClose: false },
      );
      throw error;
    }
  },
);

const updateSystemTemplate = createAsyncThunk(
  'template/updateSystemTemplate',
  async ({ id, payload } = {}) => {
    try {
      const { data } = await httpUpdateSystemTemplate(id, payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteTemplate = createAsyncThunk(
  'template/deleteTemplate',
  async (id) => {
    try {
      const { data } = await httpDeleteTemplate(id);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const duplicateTemplate = createAsyncThunk(
  'template/duplicateTemplate',
  async ({ id, copySharedInfo, params } = {}) => {
    try {
      const { data } = await httpDuplicateTemplate(id, copySharedInfo, params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const changeStatusTemplate = createAsyncThunk(
  'template/changeStatusTemplate',
  async ({ id, status } = {}) => {
    const { data } = await httpChangeStatusTemplate(id, status);

    return data;
  },
);

const getSystemTemplates = createAsyncThunk(
  'template/getSystemTemplates',
  async (
    {
      type = 'email',
      sortBy = SORTBYLIST[0],
      hideHtml = true,
      group,
      categoryHidden,
    } = {},
    { getState },
  ) => {
    try {
      let params = { sortBy, hideHtml, categoryHidden };

      if (group) params = { ...params, group };

      const { data } = await httpGetSystemTemplates(type, params);

      const { role } = getState().user;

      return { data, group, role };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteSystemTemplate = createAsyncThunk(
  'template/deleteSystemTemplate',
  async ({ id, deleteType }) => {
    try {
      await httpDeleteSystemTemplate(id, deleteType);

      return { id };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const sendTestEmails = createAsyncThunk(
  'template/sendTestEmails',
  async ({ from, to, id } = {}) => {
    try {
      const payload = {
        from,
        to,
      };

      await httpSendTestEmails(id, payload);

      return { id };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const cleanTemplate = createAction('cleanTemplateData');

const bulkUnpublishTemplates = createAsyncThunk(
  'template/bulkUnpublishTemplates',
  async ({ payload } = {}) => {
    try {
      const { data } = await httpBulkUnpublishTemplates(payload);

      showSuccessMessage(
        `${payload?.length || '0'} template${
          payload?.length > 1 ? 's' : ''
        } have been drafted`,
      );

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    }
  },
);

const bulkSoftDeleteTemplates = createAsyncThunk(
  'template/bulkSoftDeleteTemplates',
  async ({ payload } = {}) => {
    try {
      const { data } = await httpBulkSoftDeleteTemplates(payload);

      showSuccessMessage(
        `${payload?.length || '0'} template${
          payload?.length > 1 ? 's' : ''
        } have been removed`,
      );

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    }
  },
);

const bulkHardDeleteTemplates = createAsyncThunk(
  'template/bulkHardDeleteTemplates',
  async ({ type = 'email', payload } = {}) => {
    try {
      const { data } = await httpDeleteTemplates(type, payload);

      showSuccessMessage(
        `${payload?.length || '0'} template${
          payload?.length > 1 ? 's' : ''
        } have been deleted forever`,
      );

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());

      throw error;
    }
  },
);

const bulkSoftDeleteSystemTemplates = createAsyncThunk(
  'template/bulkSoftDeleteSystemTemplates',
  async ({ payload }) => {
    try {
      const { data } = await httpBulkSoftDeleteSystemTemplates(payload);

      showSuccessMessage(
        `${payload?.length || '0'} system template${
          payload?.length > 1 ? 's' : ''
        } have been removed`,
      );

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const bulkHardDeleteSystemTemplates = createAsyncThunk(
  'template/bulkHardDeleteSystemTemplates',
  async ({ payload }) => {
    try {
      const { data } = await httpBulkHardDeleteSystemTemplates(payload);

      showSuccessMessage(
        `${payload?.length || '0'} system template${
          payload?.length > 1 ? 's' : ''
        } have been deleted forever`,
      );

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const getSharedTemplates = createAsyncThunk(
  'template/getSharedTemplates',
  async (
    {
      type = 'email',
      limit = 20,
      offset = 0,
      sortBy = SORTBYLIST[1],
      collaboratorId,
    } = {},
    { getState },
  ) => {
    try {
      let params = { type, limit, offset, sortBy, collaboratorId };

      params.collaboratorId = getState().user.principalId;

      const { data } = await httpGetSharedTemplates(params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateSharedTemplateCollaborators = createAsyncThunk(
  'template/updateSharedTemplateCollaborators',
  async ({ id, payload } = {}) => {
    try {
      const { data } = await httpUpdateSharedTemplateCollaborators(id, payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateSharedTemplateLockStatus = createAsyncThunk(
  'template/updateSharedTemplateLockStatus',
  async ({ id, lockStatus } = {}) => {
    try {
      const { data } = await httpUpdateSharedTemplateLockStatus({
        id,
        lockStatus,
      });

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const sendUnlockRequestSharedTemplate = createAsyncThunk(
  'template/sendUnlockRequestSharedTemplate',
  async ({ collaboratorId, templateId } = {}) => {
    try {
      const { data } = await httpSendUnlockRequestSharedTemplate({
        collaboratorId,
        templateId,
      });

      showSuccessMessage('Unlock request has been sent successfully.');

      return data;
    } catch (error) {
      showErrorMessage(error?.response?.data?.error || getErrorText());
      throw error;
    }
  },
);

export {
  getTemplates,
  getFavoritedTemplates,
  searchTemplates,
  searchSystemTemplates,
  setNextTemplatesPage,
  setNextDeletedSystemTemplatesPage,
  getSystemTemplates,
  getDeletedSystemTemplates,
  deleteTemplates,
  deleteSystemTemplate,
  getTemplate,
  getSystemTemplate,
  createTemplate,
  createSystemTemplate,
  updateTemplate,
  updateSystemTemplate,
  deleteTemplate,
  duplicateTemplate,
  changeStatusTemplate,
  sendTestEmails,
  cleanTemplate,
  bulkUnpublishTemplates,
  bulkSoftDeleteTemplates,
  bulkHardDeleteTemplates,
  bulkSoftDeleteSystemTemplates,
  bulkHardDeleteSystemTemplates,
  getSharedTemplates,
  updateSharedTemplateCollaborators,
  updateSharedTemplateLockStatus,
  sendUnlockRequestSharedTemplate,
};
