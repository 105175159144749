import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { REDIRECT_URL } from 'appConstants';
import Button from 'components/Button';
import { RadioGroup, RadioButton } from 'components/Forms/Radio';
import Combobox from 'components/Combobox';
import CheckBox from '../../Forms/CheckBox';
import Header from '../Header';
import {
  useProducts,
  storeItemToString,
  categoryItemToString,
  productItemToString,
} from './useProducts';
import './WayMoreProductModal.sass';

const normalizeStoreItem = (item) => ({
  key: item.storeUuid,
  label: storeItemToString(item),
});

const normalizeCategoryItem = (item) => ({
  key: categoryItemToString(item),
  label: categoryItemToString(item),
});

const normalizeProductItem = (item) => ({
  key: item.productId,
  label: productItemToString(item),
});

function WayMoreProductModal({ editor, onSaveWayMoreProduct, onClose }) {
  const intl = useIntl();

  const {
    showEmptyContent,
    storesLoading,
    categoriesLoading,
    productsLoading,
    storeSelectedItem,
    productSelectedItem,
    categorySelectedItem,
    storeItems,
    categoryItems,
    productItems,
    layout,
    showCategory,
    showDescription,
    setStoreSearchTerm,
    setProductSearchTerm,
    setCategorySearchTerm,
    handleStoreSelectedItemChange,
    handleCategorySelectedItemChange,
    handleProductSelectedItemChange,
    handleChangeLayout,
    handleChangeShowCategory,
    handleChangeShowDescription,
  } = useProducts();

  const selected = editor?.getSelected();

  if (showEmptyContent) {
    return (
      <>
        <Header className="waymore-product-modal__header" onClose={onClose}>
          <FormattedMessage id="waymore-product-modal.title" />
        </Header>
        <div className="waymore-product-modal__empty-content">
          <div className="waymore-product-modal__empty-content-message">
            <FormattedMessage
              id="waymore-product-modal.empty-message"
              values={{
                link: (
                  <a
                    href={REDIRECT_URL}
                    target="_blank"
                    rel="noopener noreferrer">
                    {REDIRECT_URL}
                  </a>
                ),
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header className="waymore-product-modal__header" onClose={onClose}>
        <FormattedMessage id="waymore-product-modal.title" />
      </Header>
      <div className="waymore-product-modal__content-wrapper">
        <Combobox
          label={intl.formatMessage({
            id: 'waymore-product-modal.store-label',
          })}
          placeholder={intl.formatMessage({
            id: 'waymore-product-modal.store-placeholder',
          })}
          loading={storesLoading}
          items={storeItems}
          selectedItem={storeSelectedItem}
          itemToString={storeItemToString}
          normalizeItem={normalizeStoreItem}
          onInputValueChange={({ inputValue }) => {
            setStoreSearchTerm(inputValue);
          }}
          onSelectedItemChange={({ selectedItem }) => {
            handleStoreSelectedItemChange(selectedItem);
          }}
          fullWidth
        />
        <Combobox
          label={intl.formatMessage({
            id: 'waymore-product-modal.category-label',
          })}
          placeholder={intl.formatMessage({
            id: 'waymore-product-modal.category-placeholder',
          })}
          disabled={!storeSelectedItem}
          loading={categoriesLoading}
          items={categoryItems}
          selectedItem={categorySelectedItem}
          itemToString={categoryItemToString}
          normalizeItem={normalizeCategoryItem}
          onInputValueChange={({ inputValue }) => {
            setCategorySearchTerm(inputValue);
          }}
          onSelectedItemChange={({ selectedItem }) => {
            handleCategorySelectedItemChange(selectedItem);
          }}
          fullWidth
        />
        <Combobox
          label={intl.formatMessage({
            id: 'waymore-product-modal.product-label',
          })}
          placeholder={intl.formatMessage({
            id: 'waymore-product-modal.product-placeholder',
          })}
          items={productItems}
          selectedItem={productSelectedItem}
          disabled={!storeSelectedItem}
          loading={productsLoading}
          itemToString={productItemToString}
          normalizeItem={normalizeProductItem}
          itemHeight={56}
          renderOption={({
            imageLink,
            productName,
            productCategories,
            price,
          }) => (
            <div className="waymore-product-modal__product-item">
              <img
                src={imageLink}
                alt={productName}
                className="waymore-product-modal__product-item__img"
              />
              <div className="waymore-product-modal__product-item__info">
                <div className="waymore-product-modal__product-item__name">
                  {productName}
                </div>
                {productCategories && (
                  <div className="waymore-product-modal__product-item__category">
                    {productCategories.join(' / ')}
                  </div>
                )}
              </div>
              <div className="waymore-product-modal__product-item__price">
                {price}
              </div>
            </div>
          )}
          onInputValueChange={({ inputValue }) => {
            setProductSearchTerm(inputValue);
          }}
          onSelectedItemChange={({ selectedItem }) => {
            handleProductSelectedItemChange(selectedItem);
          }}
          fullWidth
        />
        <div className="waymore-product-modal__options">
          <CheckBox
            label={intl.formatMessage({
              id: 'waymore-product-modal.product-show-category',
            })}
            checked={showCategory}
            onChange={(e) => handleChangeShowCategory(e.target.checked)}
          />
          <CheckBox
            label={intl.formatMessage({
              id: 'waymore-product-modal.product-show-description',
            })}
            checked={showDescription}
            onChange={(e) => handleChangeShowDescription(e.target.checked)}
          />
        </div>
        <div className="waymore-product-modal__buttons">
          <RadioGroup
            name="layout"
            value={layout}
            onChange={handleChangeLayout}>
            <RadioButton
              label={<span className="icon icon-vertical-split" />}
              value="horizontal"
            />
            <RadioButton
              label={<span className="icon icon-horizontal-split" />}
              value="vertical"
            />
          </RadioGroup>
          <Button
            type="button"
            variant="contained"
            disabled={!productSelectedItem}
            onClick={() =>
              onSaveWayMoreProduct(
                layout,
                showCategory,
                showDescription,
                productSelectedItem,
              )
            }>
            {intl.formatMessage({
              id: selected?.view?.el.innerHTML
                ? 'button.replace'
                : 'button.add',
            })}
          </Button>
        </div>
      </div>
    </>
  );
}

WayMoreProductModal.propTypes = {
  editor: PropTypes.object,
  onSaveWayMoreProduct: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WayMoreProductModal;
