import { useEffect, useCallback, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { LOAD_STATUS } from 'appConstants';
import {
  clearIconFinder,
  getIconsCategories,
  getIconsStyles,
  searchIcons,
  setIconFinderNextPage,
  setIconFinderSearchTerm,
} from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  iconFinderSelector,
  iconsCategoriesSelector,
  iconsStylesSelector,
} from 'store/selectors';

export const useIconFinder = ({ vector = false } = {}) => {
  const state = useSelector(iconFinderSelector);
  const iconsCategories = useSelector(iconsCategoriesSelector);
  const iconsStyles = useSelector(iconsStylesSelector);

  const dispatch = useDispatch();

  const debouncedValue = useMemo(
    () => ({
      searchTerm: state.searchTerm,
      offset: state.offset,
      category: state.category,
      style: state.style,
    }),
    [state.offset, state.searchTerm, state.category, state.style],
  );

  // debouncing offset to prevent bugs when user clears input
  // when user clears input, offset sets to 1 and because of this
  // effect is running with old debounced searchTerm and offset = 0
  const [{ searchTerm, offset, category, style }] = useDebounce(
    debouncedValue,
    500,
  );

  useEffect(() => {
    dispatch(getIconsCategories());
    dispatch(getIconsStyles());

    return () => {
      dispatch(clearIconFinder());
    };
  }, [dispatch]);

  useEffect(() => {
    let promise;

    promise = dispatch(
      searchIcons({
        query: searchTerm || '',
        vector,
        offset,
        count: state.count,
        category,
        style,
        premium: false,
        license: `commercial-nonattribution`,
      }),
    );

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [state.count, searchTerm, offset, vector, dispatch, category, style]);

  const setSearchTerm = useCallback(
    (searchTerm, category, style) => {
      dispatch(
        setIconFinderSearchTerm({
          searchTerm,
          category,
          style,
          // setting status manually to show loader immediately for better UX
          status: LOAD_STATUS.LOADING,
        }),
      );
    },
    [dispatch],
  );

  const loadNextPage = useCallback(() => {
    dispatch(setIconFinderNextPage());
  }, [dispatch]);

  return {
    state,
    setSearchTerm,
    loadNextPage,
    iconsCategories,
    iconsStyles,
  };
};
