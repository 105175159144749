import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Tooltip from '@reach/tooltip';

import { useModal } from 'hooks';
import { showErrorMessage } from 'helpers';
import { LOAD_STATUS } from 'appConstants';
import { deleteCategory, updateCategory } from 'store/actions';
import IconButton from 'components/IconButton';
import CategoryMenu from 'components/CategoryMenu';
import { FormattedMessage, useIntl } from 'react-intl';
import ConfirmModal from 'components/Modals/ConfirmModal';
import BaseModal from 'components/Modals/BaseModal';

const CATEGORY_LOAD_STATUS = {
  DELETE_LOADING: 'delete loading',
  HIDE_LOADING: 'hide loading',
};

function SidebarLinkCategoryButtons({ category, categoriesNames }) {
  const intl = useIntl();

  const dispatch = useDispatch();

  const {
    isOpen: isConfirmDeleteModalOpen,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModal();

  const [status, setStatus] = useState(LOAD_STATUS.IDLE);

  const handleDeleteCategory = async () => {
    try {
      setStatus(CATEGORY_LOAD_STATUS.DELETE_LOADING);
      await dispatch(deleteCategory(category.id)).unwrap();
      // here we don't set status to resolved and not closing modal, because
      // this component is rendered inside category item. After successful deleting
      // of category category item will be removed from DOM along with this component
    } catch {
      setStatus(LOAD_STATUS.REJECTED);
    }
  };

  const handleHideCategoryClick = async () => {
    try {
      setStatus(CATEGORY_LOAD_STATUS.HIDE_LOADING);
      const payload = {
        id: category.id,
        hidden: !category.hidden,
      };

      await dispatch(updateCategory(payload));
      setStatus(LOAD_STATUS.RESOLVED);
    } catch {
      setStatus(LOAD_STATUS.REJECTED);
      showErrorMessage(intl.formatMessage({ id: 'toast.error-message' }));
    }
  };

  const isHideLoading = status === CATEGORY_LOAD_STATUS.HIDE_LOADING;

  return (
    <>
      {(category.hidden || isHideLoading) && (
        <Tooltip
          label={intl.formatMessage({ id: 'category.hidden-button-tooltip' })}>
          <IconButton
            aria-label={`show ${category.name}`}
            color="secondary"
            loading={isHideLoading}
            disabled={isHideLoading}
            onClick={handleHideCategoryClick}>
            <span className="icon icon-visibility-off" />
          </IconButton>
        </Tooltip>
      )}
      <CategoryMenu
        category={category}
        categoriesNames={categoriesNames}
        onHideCategoryClick={handleHideCategoryClick}
        onDeleteCategoryClick={openConfirmDeleteModal}
      />
      <span className="sidebar__link-item__number">{category.count}</span>
      <BaseModal
        isOpen={isConfirmDeleteModalOpen}
        headerTitle="category-menu.confirm-modal-header"
        maxWidth="700px"
        onClose={closeConfirmDeleteModal}>
        <ConfirmModal
          loading={status === CATEGORY_LOAD_STATUS.DELETE_LOADING}
          buttonsColor="secondary"
          onClose={closeConfirmDeleteModal}
          onConfirm={handleDeleteCategory}>
          <FormattedMessage id="category-menu.confirm-modal-description" />
        </ConfirmModal>
      </BaseModal>
    </>
  );
}

SidebarLinkCategoryButtons.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    hidden: PropTypes.bool,
    count: PropTypes.number.isRequired,
  }).isRequired,
  categoriesNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SidebarLinkCategoryButtons;
