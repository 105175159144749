import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { useOutsideListener } from 'hooks';
import Switch from 'react-switch';

function HeadingsItem({
  headingTitle,
  headingFontSize,
  headingFontColor,
  headingBold,
  headingItalic,
  headingBottomSpace,
  headingFontSizeSettingName,
  headingFontColorSettingName,
  headingBoldSettingName,
  headingItalicSettingName,
  headingBottomSpaceSettingName,
  onChangeGeneralSettings,
}) {
  const [colorPickerRef, showColorPicker, setShowColorPicker] =
    useOutsideListener(false);

  return (
    <div className="general-settings-modal__content-item">
      <div className="general-settings-modal__content-item__title">
        <FormattedMessage id={`${headingTitle}`} />
      </div>
      <div className="general-settings-modal__options-wrapper">
        <div className="general-settings-modal__options-wrapper__option-item">
          <div className="general-settings-modal__options-wrapper__option-item__label">
            <FormattedMessage id="general-settings-modal.headings-tab.heading.font-size-title" />
          </div>
          <div className="general-settings-modal__form-item number-range">
            <button
              type="button"
              onClick={() =>
                onChangeGeneralSettings(
                  headingFontSizeSettingName,
                  headingFontSize - 1,
                )
              }>
              -
            </button>
            <div>{headingFontSize}</div>
            <button
              type="button"
              onClick={() =>
                onChangeGeneralSettings(
                  headingFontSizeSettingName,
                  headingFontSize + 1,
                )
              }>
              +
            </button>
          </div>
        </div>
        <div className="general-settings-modal__options-wrapper__option-item">
          <div className="general-settings-modal__options-wrapper__option-item__label">
            <FormattedMessage id="general-settings-modal.headings-tab.heading.color-title" />
          </div>
          <div className="general-settings-modal__form-item color-picker">
            <div className="color-picker__input">
              <input
                type="text"
                name="bgColor"
                value={headingFontColor}
                onChange={(e) =>
                  onChangeGeneralSettings(e.target.name, e.target.value)
                }
              />
              <button
                style={{
                  backgroundColor: headingFontColor,
                }}
                type="button"
                onClick={() => setShowColorPicker((prev) => !prev)}
              />
            </div>
            {showColorPicker && (
              <div className="color-picker__popup" ref={colorPickerRef}>
                <SketchPicker
                  disableAlpha
                  color={headingFontColor}
                  onChange={(color) =>
                    onChangeGeneralSettings(
                      headingFontColorSettingName,
                      color.hex,
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="general-settings-modal__options-wrapper__option-item">
          <div className="general-settings-modal__options-wrapper__option-item__label">
            <FormattedMessage id="general-settings-modal.headings-tab.heading.bold-title" />
          </div>
          <div className="general-settings-modal__form-item">
            <Switch
              onChange={(checked) =>
                onChangeGeneralSettings(headingBoldSettingName, checked)
              }
              checked={headingBold}
              onColor="#f1f9ff"
              onHandleColor="#cbe9ff"
              boxShadow="0 1px 2px rgba(0, 0, 0, 0.3)"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </div>
        </div>
        <div className="general-settings-modal__options-wrapper__option-item">
          <div className="general-settings-modal__options-wrapper__option-item__label">
            <FormattedMessage id="general-settings-modal.headings-tab.heading.italic-title" />
          </div>
          <div className="general-settings-modal__form-item">
            <Switch
              onChange={(checked) =>
                onChangeGeneralSettings(headingItalicSettingName, checked)
              }
              checked={headingItalic}
              onColor="#f1f9ff"
              onHandleColor="#cbe9ff"
              boxShadow="0 1px 2px rgba(0, 0, 0, 0.3)"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </div>
        </div>
        <div className="general-settings-modal__options-wrapper__option-item">
          <div className="general-settings-modal__options-wrapper__option-item__label">
            <FormattedMessage id="general-settings-modal.headings-tab.heading.bottom-space" />
          </div>
          <div className="general-settings-modal__form-item number-range">
            <button
              type="button"
              onClick={() =>
                onChangeGeneralSettings(
                  headingBottomSpaceSettingName,
                  headingBottomSpace - 1,
                )
              }>
              -
            </button>
            <div>{headingBottomSpace}</div>
            <button
              type="button"
              onClick={() =>
                onChangeGeneralSettings(
                  headingBottomSpaceSettingName,
                  headingBottomSpace + 1,
                )
              }>
              +
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

HeadingsItem.propTypes = {
  headingTitle: PropTypes.string.isRequired,
  headingFontSize: PropTypes.number.isRequired,
  headingFontColor: PropTypes.string.isRequired,
  headingBold: PropTypes.bool.isRequired,
  headingItalic: PropTypes.bool.isRequired,
  headingBottomSpace: PropTypes.number.isRequired,
  headingFontSizeSettingName: PropTypes.string.isRequired,
  headingFontColorSettingName: PropTypes.string.isRequired,
  headingBoldSettingName: PropTypes.string.isRequired,
  headingItalicSettingName: PropTypes.string.isRequired,
  headingBottomSpaceSettingName: PropTypes.string.isRequired,
  onChangeGeneralSettings: PropTypes.func.isRequired,
};

export default HeadingsItem;
