export const FONTS = [
  {
    name: 'Arial',
    value: 'Arial, Helvetica, sans-serif',
  },
  {
    name: 'Arial Black',
    value: '"Arial Black", Gadget, sans-serif',
  },
  {
    name: 'Brush Script MT',
    value: '"Brush Script MT", sans-serif',
  },
  {
    name: 'Comic Sans MS',
    value: '"Comic Sans MS", cursive',
  },
  {
    name: 'Courier New',
    value: '"Courier New", Courier, monospace',
  },
  {
    name: 'Gerogia',
    value: 'Georgia, serif',
  },
  {
    name: 'Helvetica',
    value: 'Helvetica, sans-serif',
  },
  {
    name: 'Impact',
    value: 'Impact, Charcoal, sans-serif',
  },
  {
    name: 'Lucida Sans Unicode',
    value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma, Geneva, sans-serif',
  },
  {
    name: 'Times New Roman',
    value: '"Times New Roman", Times, serif',
  },
  {
    name: 'Trebuchet MS',
    value: '"Trebuchet MS", Helvetica, sans-serif',
  },
  {
    name: 'Verdana',
    value: 'Verdana, Geneva, sans-serif',
  },
];

export const CUSTOM_FIELDS = [
  {
    value: '#{firstName}#',
    name: 'First Name',
  },
  { value: '#{lastName}#', name: 'Last Name' },
  {
    value: '#{currentDate}#',
    name: 'Current Date',
  },
  { value: '#{email}#', name: 'Email' },
  {
    value: '#{browser_link}#',
    name: 'View in browser',
  },
  {
    value: '#{unsubscribeProfileLink}#',
    name: 'Unsubscribe profile',
  },
  {
    value: '#{unsubscribeLink}#',
    name: 'Unsubscribe',
  },
  {
    value: '#{contactsPrefix}#',
    name: 'Prefix (Mr. Mrs. Dr,)',
  },
  {
    value: '#{country}#',
    name: 'Country',
  },
  {
    value: '#{dest:sms}#',
    name: 'SMS number',
  },
  {
    value: '#{dest:viber}#',
    name: 'Viber number',
  },
  {
    value: '#{landline}#',
    name: 'Landline number',
  },
];

export const BLOCKS_CATEGORIES = {
  blocks: ['Basic', 'Navigation', 'Forms', 'Sections', 'Extra', 'Social'],
  waymore: ['WayMore Forms', 'WayMore Products'],
};

export const PREVIEW_SIZES = ['pc', 'tablet', 'mobile'];

export const HEADER_SELECTOR = 'h1, h2, h3, h4, h5, h6';
export const PARAGRAPH_SELECTOR = 'p, span, div';

export let tableStyle = `
  height: 150px;
  margin: 0 auto 10px auto;
  padding: 5px 5px 5px 5px;
  width: 100%;
`;

export let cellStyle = `
  padding: 0;
  margin: 0;
  vertical-align: top;
`;

export const COMPONENT_CHANGE_STATUSES = {
  SELECTED: 'selected',
};

export const RTE_BUTTON_STATE = {
  ACTIVE: 1,
  INACTIVE: 0,
  DISABLED: -1,
};

export const imageTextPlaceholer = `
  <img src="https://cdn11.waymore.io/dnd/images/image_placeholder.jpg" alt="image" style="width: 100%; height: auto;" />
`;

export const linkedImagePlaceholer = `
  <img
    data-gjs-editable="true"
    data-gjs-collection="false"
    data-gjs-droppable="false"
    data-gjs-draggable="false"
    data-gjs-badgable="false"
    data-gjs-copyable="false"
    data-gjs-removable="false"
    data-gjs-selectable="false"
    data-gjs-hoverable="false"
    data-gjs-resizable="false"
    src="https://cdn11.waymore.io/dnd/images/image_placeholder.jpg" data-type="linked-image-image"
    alt="image"
    style="width: 100%; height: auto;"
  />
`;

export const imagePlaceholer = `
  <img data-gjs-type="image" data-type="image" src="data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNzYgNzYiPgogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJtMjMuNDE3IDQ0LjI1IDEwLjQxNiAxMi41TDQ4LjQxNyAzOGwxOC43NSAyNUg4LjgzM003NS41IDY3LjE2N1Y4LjgzM0E4LjMzMyA4LjMzMyAwIDAgMCA2Ny4xNjcuNUg4LjgzM0M0LjI1LjUuNSA0LjI1LjUgOC44MzN2NTguMzM0Qy41IDcxLjc1IDQuMjUgNzUuNSA4LjgzMyA3NS41aDU4LjMzNGM0LjU4MyAwIDguMzMzLTMuNzUgOC4zMzMtOC4zMzNaIi8+CiAgICA8dGV4dCBmaWxsPSIjRkZGIiBmb250LWZhbWlseT0iUm9ib3RvLVJlZ3VsYXIsIFJvYm90byIgZm9udC1zaXplPSI2LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNSAuNSkiPgogICAgICAgICAgICAgICAgPHRzcGFuIHg9IjUuODM3IiB5PSIxNy40MjMiPkRvdWJsZSBjbGljayB0byBjaGFuZ2U8L3RzcGFuPgogICAgICAgICAgICA8L3RleHQ+CiAgPC9nPgo8L3N2Zz4=" alt="image" style="width: 100%; height: auto;" />
`;

export const iconPlaceholer = `
  <img data-gjs-type="icon-wrapper" data-type="icon-wrapper" src="data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNzYgNzYiPgogIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICA8cGF0aCBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9Ii4xNSIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJtMjMuNDE3IDQ0LjI1IDEwLjQxNiAxMi41TDQ4LjQxNyAzOGwxOC43NSAyNUg4LjgzM003NS41IDY3LjE2N1Y4LjgzM0E4LjMzMyA4LjMzMyAwIDAgMCA2Ny4xNjcuNUg4LjgzM0M0LjI1LjUuNSA0LjI1LjUgOC44MzN2NTguMzM0Qy41IDcxLjc1IDQuMjUgNzUuNSA4LjgzMyA3NS41aDU4LjMzNGM0LjU4MyAwIDguMzMzLTMuNzUgOC4zMzMtOC4zMzNaIi8+CiAgICA8dGV4dCBmaWxsPSIjRkZGIiBmb250LWZhbWlseT0iUm9ib3RvLVJlZ3VsYXIsIFJvYm90byIgZm9udC1zaXplPSI2LjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0uNSAuNSkiPgogICAgICAgICAgICAgICAgPHRzcGFuIHg9IjUuODM3IiB5PSIxNy40MjMiPkRvdWJsZSBjbGljayB0byBjaGFuZ2U8L3RzcGFuPgogICAgICAgICAgICA8L3RleHQ+CiAgPC9nPgo8L3N2Zz4=" alt="icon" style="width: 128px; height: 128px;" />
`;

export const FLOAT_OPTIONS = [
  { value: 'none', className: 'icon icon-close' },
  { value: 'left', className: 'icon icon-align-left' },
  { value: 'inherit', className: 'icon icon-align-center' },
  { value: 'right', className: 'icon icon-align-right' },
];

export const CURSOR_OPTIONS = [
  { name: 'alias', value: 'alias' },
  { name: 'all-scroll', value: 'all-scroll' },
  { name: 'auto', value: 'auto' },
  { name: 'cell', value: 'cell' },
  { name: 'col-resize', value: 'col-resize' },
  { name: 'context-menu', value: 'context-menu' },
  { name: 'copy', value: 'copy' },
  { name: 'crosshair', value: 'crosshair' },
  { name: 'default', value: 'default' },
  { name: 'e-resize', value: 'e-resize' },
  { name: 'ew-resize', value: 'ew-resize' },
  { name: 'help', value: 'help' },
  { name: 'move', value: 'move' },
  { name: 'n-resize', value: 'n-resize' },
  { name: 'ne-resize', value: 'ne-resize' },
  { name: 'nesw-resize', value: 'nesw-resize' },
  { name: 'ns-resize', value: 'ns-resize' },
  { name: 'nw-resize', value: 'nw-resize' },
  { name: 'nwse-resize', value: 'nwse-resize' },
  { name: 'no-drop', value: 'no-drop' },
  { name: 'none', value: 'none' },
  { name: 'not-allowed', value: 'not-allowed' },
  { name: 'pointer', value: 'pointer' },
  { name: 'progress', value: 'progress' },
  { name: 'row-resize', value: 'row-resize' },
  { name: 's-resize', value: 's-resize' },
  { name: 'se-resize', value: 'se-resize' },
  { name: 'sw-resize', value: 'sw-resize' },
  { name: 'text', value: 'text' },
  { name: 'w-resize', value: 'w-resize' },
  { name: 'wait', value: 'wait' },
  { name: 'zoom-in', value: 'zoom-in' },
  { name: 'zoom-out', value: 'zoom-out' },
];

export const VISIBILITY_OPTIONS = [
  { name: 'Visible', value: 'visible' },
  { name: 'Hidden', value: 'hidden' },
  { name: 'Collapse', value: 'collapse' },
];

export const TEXT_DECORATION_OPTIONS = [
  { value: 'none', name: 'None', className: 'icon icon-close' },
  {
    value: 'underline',
    name: 'underline',
    className: 'icon icon-format-underlined',
  },
  {
    value: 'line-through',
    name: 'Line-through',
    className: 'icon icon-strikethrough',
  },
];

export const VERTICAL_ALIGN_OPTIONS = [
  {
    value: 'baseline',
    title: 'Baseline',
    className: 'icon icon-close',
  },
  {
    value: 'top',
    title: 'Top',
    className: 'icon icon-align-items-flex-start',
  },
  {
    value: 'middle',
    title: 'Middle',
    className: 'icon icon-align-items-center',
  },
  {
    value: 'bottom',
    title: 'Bottom',
    className: 'icon icon-align-items-flex-end',
  },
];

export const TEXT_ALIGN_OPTIONS = [
  {
    value: 'left',
    name: 'Left',
    className: 'icon icon-align-left',
  },
  {
    value: 'center',
    name: 'Center',
    className: 'icon icon-align-center',
  },
  {
    value: 'right',
    name: 'Right',
    className: 'icon icon-align-right',
  },
  {
    value: 'justify',
    name: 'Justify',
    className: 'icon icon-align-justify',
  },
];
