import React from 'react';
import PropTypes from 'prop-types';
import { buildScreenshotUrl } from 'helpers';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import './TemplateLayout.sass';

const Template = ({
  classes,
  clickHandler,
  name,
  templateId,
  lastModified,
  description,
}) => {
  return (
    <div
      className={cx(classes)}
      onClick={clickHandler}
      role="button"
      tabIndex="0"
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          clickHandler();
        }
      }}>
      <div data-tip data-for={templateId} className="new-template__frame">
        <img src={buildScreenshotUrl(templateId, lastModified)} alt="Preview" />
      </div>
      <h4
        data-tip
        data-for={`new-template-${templateId}`}
        className="new-template__title">
        {name}
      </h4>
      {description && (
        <ReactTooltip
          id={templateId}
          type="dark"
          aria-haspopup="true"
          place="top"
          effect="float"
          backgroundColor="#000000">
          <div style={{ width: '180px', textAlign: 'center' }}>
            {description.length <= 70 ? description : description.slice(0, 70)}
          </div>
        </ReactTooltip>
      )}
      <ReactTooltip
        id={`new-template-${templateId}`}
        class="title-tooltip"
        type="dark"
        aria-haspopup="true"
        place="bottom"
        clickable={false}
        delayShow={500}
        border={false}
        effect="solid">
        <span>{name}</span>
      </ReactTooltip>
    </div>
  );
};

Template.propTypes = {
  classes: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  lastModified: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
};

export default Template;
