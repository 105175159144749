import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'timeago.js';
import { useEditorContext } from 'routes/Editor/EditorContext';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { principalIdSelector, principalListSelector } from 'store/selectors';
import { getPrincipal } from 'store/actions';
import { useModal } from 'hooks';
import { useForm } from 'react-hook-form';
import TextField from 'components/Forms/TextField';
import Button from 'components/Button';
import IconButton from 'components/IconButton';
import {
  showSuccessMessage,
  showErrorMessage,
  getErrorText,
  getListUsersFullName,
} from 'helpers';
import {
  httpGetNotes,
  httpUpdateNotes,
  httpDeleteNote,
  httpDeleteAllNotes,
} from 'api/notes.api';
import LoadingSpinner from 'components/LoadingSpinner';
import ConfirmModal from 'components/Modals/ConfirmModal';
import BaseModal from 'components/Modals/BaseModal';
import { LOAD_STATUS } from 'appConstants';
import './PersonalNotes.sass';
import cx from 'classnames';

const PERSONAL_NOTES_LOAD_STATUS = {
  DELETE_LOADING: 'delete loading',
  HIDE_LOADING: 'hide loading',
};

export const PersonalNotes = ({ onClose }) => {
  const {
    template: { id: templateId, isShared, userId: ownerId },
  } = useEditorContext();

  const dispatch = useDispatch();

  const intl = useIntl();

  const currentUserId = useSelector(principalIdSelector);
  const principalList = useSelector(principalListSelector);

  const [status, setStatus] = useState(LOAD_STATUS.IDLE);

  const isTemplateOwner = ownerId === currentUserId;
  const showDeleteNoteButton = (userId) => {
    if (isTemplateOwner) {
      return true;
    } else {
      if (userId === currentUserId) {
        return true;
      }
    }

    return false;
  };

  const {
    isOpen: isConfirmDeleteModalOpen,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModal();

  const messages = defineMessages({
    addingSuccessMessage: {
      id: 'personal-notes.adding-success-message',
    },
    noteEmptyError: {
      id: 'personal-notes.empty-note-message',
    },
    subTitle: { id: 'personal-notes.sub-title' },
    noNotes: { id: 'personal-notes.no-notes' },
    emptyNote: { id: 'personal-notes.empty-note-message' },
    NotePlaceholder: { id: 'personal-notes.note-placeholder' },
    NoteDescription: { id: 'personal-notes.note-description' },
    closeButton: { id: 'button.close' },
    addButton: { id: 'button.add' },
    deleteAllNotesButton: { id: 'personal-notes.delete-all-notes' },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [noteId, setNoteId] = useState();
  const [notes, setNotes] = useState(null);

  const handleSave = async ({ note }) => {
    const resultNote = { templateId, text: note, userId: currentUserId };

    setSaveLoading(true);

    try {
      const res = await httpUpdateNotes(resultNote);

      showSuccessMessage(intl.formatMessage(messages.addingSuccessMessage));

      setNotes([
        ...notes,
        {
          id: res?.data.id,
          userId: currentUserId,
          templateId,
          text: note,
          createdAt: Date.now(),
        },
      ]);

      return res;
    } catch (error) {
      showErrorMessage(getErrorText());
      onClose();

      throw error;
    } finally {
      setSaveLoading(false);
      reset();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(handleSave)(event);
    }
  };

  const handleDeleteNote = async () => {
    try {
      setStatus(PERSONAL_NOTES_LOAD_STATUS.DELETE_LOADING);

      if (deleteStatus === 'all') {
        await httpDeleteAllNotes(templateId);
        setNotes([]);
      }

      if (deleteStatus === 'single' && noteId) {
        await httpDeleteNote(noteId);
        setNoteId('');
        setNotes(notes.filter((item) => item.id !== noteId));
      }

      setStatus(LOAD_STATUS.RESOLVED);
    } catch {
      setStatus(LOAD_STATUS.REJECTED);
    } finally {
      setDeleteStatus(null);
      closeConfirmDeleteModal();
    }
  };

  useEffect(() => {
    const getAllPrincipals = async () => {
      await dispatch(getPrincipal());
    };

    getAllPrincipals();
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);

    const getNotes = async () => {
      try {
        const { data } = await httpGetNotes(templateId);

        setNotes(data);
      } catch (error) {
        showErrorMessage(getErrorText());

        throw error;
      } finally {
        setIsLoading(false);
      }
    };

    getNotes();
  }, [setNotes, templateId]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <form
      className="personal-notes-modal__form"
      onSubmit={handleSubmit(handleSave)}>
      <div className="personal-notes-modal__sub-title">
        <span>{intl.formatMessage(messages.subTitle)}</span>
        {notes && notes.length > 0 && isTemplateOwner && (
          <Button
            className="personal-notes-modal__sub-title__delete-all-button"
            type="button"
            color="primary"
            onClick={() => {
              openConfirmDeleteModal();
              setDeleteStatus('all');
            }}>
            {intl.formatMessage(messages.deleteAllNotesButton)}
          </Button>
        )}
      </div>
      <div
        data-testid="notes-list"
        className={cx('personal-notes-modal__content', {
          'personal-notes-modal__no-content': notes && notes.length === 0,
        })}>
        {notes && notes.length > 0 ? (
          notes.reverse().map(({ id, text, createdAt, userId }) => {
            const noteCreatedTime = format(createdAt);
            const senderName =
              getListUsersFullName(principalList, [userId]) || userId;

            return (
              <div
                data-testid="notes-list-item"
                className="personal-notes-modal__content__note"
                key={`${id}${Date.now()}`}>
                <span
                  className={cx(
                    'personal-notes-modal__content__note__sender-name',
                    {
                      'sender-shared': isShared && ownerId !== userId,
                    },
                  )}>
                  {senderName?.toLowerCase()}
                </span>
                <time>{noteCreatedTime}</time>
                <p>{text}</p>
                {showDeleteNoteButton(userId) && (
                  <IconButton
                    className="personal-notes-modal__content__note__delete-button"
                    data-testid={`delete-note-${id}`}
                    aria-label="Delete a note"
                    onClick={() => {
                      openConfirmDeleteModal();
                      setDeleteStatus('single');
                      setNoteId(id);
                    }}>
                    <span className="icon icon-delete" />
                  </IconButton>
                )}
              </div>
            );
          })
        ) : (
          <div
            data-testid="notes-list-empty"
            className="personal-notes-modal__content__no-notes">
            <span className="icon icon-add-comment" />
            {intl.formatMessage(messages.noNotes)}
          </div>
        )}
      </div>
      <div className="personal-notes-modal__adding">
        <Button
          type="submit"
          variant="contained"
          loading={isSaveLoading}
          disabled={isSaveLoading}>
          {intl.formatMessage(messages.addButton)}
        </Button>
        <TextField
          {...register('note', {
            validate: (value) => {
              const trimmedValue = value;

              if (!trimmedValue) {
                return intl.formatMessage(messages.emptyNote);
              }
            },
          })}
          onKeyPress={handleKeyPress}
          error={Boolean(errors.note)}
          placeholder={intl.formatMessage(messages.NotePlaceholder)}
          helperText={
            errors.note?.message || intl.formatMessage(messages.NoteDescription)
          }
          fullWidth
        />
      </div>
      <BaseModal
        isOpen={isConfirmDeleteModalOpen}
        headerTitle="personal-notes.confirm-modal-header"
        maxWidth="600px"
        onClose={closeConfirmDeleteModal}>
        <ConfirmModal
          loading={status === PERSONAL_NOTES_LOAD_STATUS.DELETE_LOADING}
          buttonsColor="secondary"
          onClose={closeConfirmDeleteModal}
          onConfirm={handleDeleteNote}>
          <FormattedMessage id="personal-notes.confirm-modal-description" />
        </ConfirmModal>
      </BaseModal>
    </form>
  );
};

PersonalNotes.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PersonalNotes;
