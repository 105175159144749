import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import './TemplatesView.sass';

const TemplatesView = ({ templatesView, setTemplatesView }) => {
  const viewsList = [
    {
      type: 'grid-view',
      icon: 'icon-grid-on',
      title: 'Grid',
    },
    {
      type: 'small-grid-view',
      icon: 'icon-background-grid-small',
      title: 'Small Grid',
    },
    {
      type: 'list-view',
      icon: 'icon-table-rows',
      title: 'List',
    },
  ];

  return (
    <div className="templates-view">
      <div className="templates-view__action">
        {viewsList.map(({ type, icon, title }, index) => (
          <div
            key={`templates-view-${index}`}
            className="templates-view__action__item">
            <button
              tabIndex={0}
              data-tip
              data-for={`templates-view-${type}`}
              className={cx('templates-view__action__item__button', {
                active: templatesView === type,
              })}
              onClick={() => {
                setTemplatesView(type);
                localStorage.setItem('templatesViewType', type);
              }}>
              <span className={`icon ${icon}`} />
            </button>
            <ReactTooltip
              id={`templates-view-${type}`}
              class="title-tooltip"
              type="dark"
              aria-haspopup="true"
              place="top"
              clickable={false}
              delayShow={500}
              border={false}
              effect="solid">
              <span>{title}</span>
            </ReactTooltip>
          </div>
        ))}
      </div>
    </div>
  );
};

TemplatesView.propTypes = {
  templatesView: PropTypes.string.isRequired,
  setTemplatesView: PropTypes.func.isRequired,
};

export default TemplatesView;
