import { buildQueryString } from 'helpers';
import openStyleManagerCustom from './OpenStyleManager';
import custom from './Custom';
import {
  changeHrefTarget,
  addCustomTypes,
  linkTraits,
  iconTraits,
} from './custom-traits';

export default (editor, advanced) => {
  addCustomTypes(editor);

  openStyleManagerCustom(editor, advanced);

  editor.DomComponents.getTypes().forEach((type) => {
    if (type.id === 'link' || type.id === 'link-button') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            traits: [...linkTraits()],
          },
        },
      });
    }

    if (type.id === 'svg') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            traits: [...linkTraits()],
          },
          init() {
            if (this.get('tagName') === 'path') {
              this.removeTrait('href');
              this.removeTrait('target');
            } else {
              this.on('change:attributes:href', changeHrefTarget.bind(this));
              this.on('change:attributes:target', changeHrefTarget.bind(this));
            }
          },
        },
      });
    }

    if (type.id === 'image') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            traits: [
              ...linkTraits(),
              {
                name: 'alt',
              },
              {
                name: 'caption',
                label: 'Caption',
              },
              {
                type: 'full-width',
                name: 'width',
                label: 'Size',
              },
            ],
          },
          init() {
            this.on('change:attributes:caption', this.changeIMGCaption);
            this.on('change:attributes:href', changeHrefTarget.bind(this));
            this.on('change:attributes:target', changeHrefTarget.bind(this));
          },
          changeIMGCaption(el) {
            let model = el.getView().model;
            let parentModel = el.parent().getView().model;

            if (parentModel.get('tagName') !== 'figure') {
              let figcaption = {
                tagName: 'figcaption',
                content: `${el.changed.attributes.caption}`,
              };

              let figure = {
                tagName: 'figure',
                style: {
                  margin: 0,
                  width: 'fit-content',
                  height: 'fit-content',
                },
                components: [model.clone(), figcaption],
              };

              model.replaceWith(figure);
            } else {
              el.parent()
                .getView()
                .model.components()
                .models[1].set('content', el.changed.attributes.caption);
            }
          },
        },
      });
    }

    if (type.id === 'text') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            traits(component) {
              const tagName = component.attributes.tagName;
              let options = [];

              if (['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(tagName)) {
                options = [
                  { id: 'h1', name: 'H1' },
                  { id: 'h2', name: 'H2' },
                  { id: 'h3', name: 'H3' },
                  { id: 'h4', name: 'H4' },
                  { id: 'h5', name: 'H5' },
                  { id: 'h6', name: 'H6' },
                ];
              }

              if (['p', 'span'].includes(tagName)) {
                options = [
                  { id: 'p', name: 'P' },
                  { id: 'span', name: 'SPAN' },
                ];
              }

              return [
                ...linkTraits(),
                {
                  type: 'select',
                  label: 'HTML tag',
                  name: 'tag',
                  default: tagName,
                  options,
                },
              ];
            },
          },
          init() {
            this.on('change:attributes:tag', this.changeTag);
            this.on('change:attributes:href', changeHrefTarget.bind(this));
            this.on('change:attributes:target', changeHrefTarget.bind(this));
          },
          changeTag(el) {
            el.set('tagName', el.changed.attributes.tag);
          },
        },
      });
    }

    if (type.id === 'link') {
      editor.DomComponents.addType(type.id, {
        model: {
          defaults: {
            linkto: 'web',
            title: '',
            messagesubject: '',
            messagebody: '',
            email: '',
            anchorlink: '',
            tag: '',
            telnumber: '',
            skype: '',
            sms: '',
            viber: '',
            file: '',
            traits() {
              return [
                {
                  type: 'select',
                  name: 'linkto',
                  label: 'Link to',
                  default: 'web',
                  options: [
                    {
                      value: 'web',
                      name: 'Web address',
                    },
                    {
                      value: 'email',
                      name: 'Email address',
                    },
                    {
                      value: 'tel',
                      name: 'Tel',
                    },
                    {
                      value: 'skype',
                      name: 'Skype',
                    },
                    {
                      value: 'sms',
                      name: 'SMS',
                    },
                    {
                      value: 'viber',
                      name: 'Viber',
                    },
                    {
                      value: 'file',
                      name: 'File',
                    },
                    {
                      value: 'anchor',
                      name: 'Anchor link',
                    },
                    {
                      value: 'tag',
                      name: 'Tag',
                    },
                  ],
                  changeProp: true,
                },
                {
                  name: 'openimageassets',
                  type: 'button',
                  text: 'Select image file',
                  full: true,
                  changeProp: true,
                  command: (editor) => {
                    editor.runCommand('open-assets', { isLink: true });
                  },
                  attributes: { style: 'display: none' },
                },
                {
                  name: 'messagesubject',
                  label: 'Message subject',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  name: 'telnumber',
                  label: 'Tel number',
                  placeholder: '123-456-789',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  name: 'skype',
                  label: 'Skype',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  name: 'sms',
                  label: 'SMS',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  name: 'viber',
                  label: 'Viber',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  name: 'messagebody',
                  label: 'Message Body',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  type: 'email',
                  name: 'email',
                  label: 'Email address',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  name: 'anchorlink',
                  label: 'Anchor link',
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                {
                  type: 'select',
                  name: 'tag',
                  label: 'Tags',
                  default: '',
                  options: [
                    {
                      value: '',
                      name: 'Select a tag',
                    },
                    {
                      value: '#{email}#',
                      name: 'Email',
                    },
                    {
                      value: '#{unsubscribeProfileLink}#',
                      name: 'Unsubscribe profile',
                    },
                    {
                      value: '#{unsubscribeLink}#',
                      name: 'Unsubscribe',
                    },
                  ],
                  attributes: { style: 'display: none' },
                  changeProp: true,
                },
                ...linkTraits(),
                ...iconTraits(),
                {
                  name: 'title',
                  label: 'Title',
                },
              ];
            },
          },
          init() {
            const linkTo = this.get('linkto');
            const hrefTrait = this.getTrait('href');
            const messageSubjectTrait = this.getTrait('messagesubject');
            const messageBodyTrait = this.getTrait('messagebody');
            const emailTrait = this.getTrait('email');
            const anchorLinkTrait = this.getTrait('anchorlink');
            const tagTrait = this.getTrait('tag');
            const telTrait = this.getTrait('telnumber');
            const skypeTrait = this.getTrait('skype');
            const smsTrait = this.getTrait('sms');
            const viberTrait = this.getTrait('viber');
            const fileTrait = this.getTrait('openimageassets');

            if (hrefTrait) {
              hrefTrait.set('attributes', {
                style: `display: ${
                  linkTo === 'web' || linkTo === 'file' ? 'block' : 'none'
                }`,
              });
            }

            if (messageSubjectTrait) {
              messageSubjectTrait.set('attributes', {
                style: `display: ${linkTo === 'email' ? 'block' : 'none'}`,
              });
            }

            if (messageBodyTrait) {
              messageBodyTrait.set('attributes', {
                style: `display: ${linkTo === 'email' ? 'block' : 'none'}`,
              });
            }

            if (emailTrait) {
              emailTrait.set('attributes', {
                style: `display: ${linkTo === 'email' ? 'block' : 'none'}`,
              });
            }

            if (anchorLinkTrait) {
              anchorLinkTrait.set('attributes', {
                style: `display: ${linkTo === 'anchor' ? 'block' : 'none'}`,
              });
            }

            if (tagTrait) {
              tagTrait.set('attributes', {
                style: `display: ${linkTo === 'tag' ? 'block' : 'none'}`,
              });
            }

            if (telTrait) {
              telTrait.set('attributes', {
                style: `display: ${linkTo === 'tel' ? 'block' : 'none'}`,
              });
            }

            if (skypeTrait) {
              skypeTrait.set('attributes', {
                style: `display: ${linkTo === 'skype' ? 'block' : 'none'}`,
              });
            }

            if (smsTrait) {
              smsTrait.set('attributes', {
                style: `display: ${linkTo === 'sms' ? 'block' : 'none'}`,
              });
            }

            if (viberTrait) {
              viberTrait.set('attributes', {
                style: `display: ${linkTo === 'viber' ? 'block' : 'none'}`,
              });
            }

            if (fileTrait) {
              fileTrait.set('attributes', {
                style: `display: ${linkTo === 'file' ? 'block' : 'none'}`,
              });
            }

            this.on('remove', this.removeLink);
            this.on('change:linkto', this.changeLinkTo);
            this.on('change:tag', this.changeTag);
            this.on('change:attributes:title', this.changeTitle);
            this.on('change:email', this.changeEmail);
            this.on('change:messagesubject', this.changeMessageSubject);
            this.on('change:messagebody', this.changeMessageBody);
            this.on('change:anchorlink', this.changeAnchorLink);
            this.on('change:attributes:href', this.changeHref);
            this.on('change:telnumber', this.changeTelNumber);
            this.on('change:skype', this.changeSkype);
            this.on('change:sms', this.changeSMS);
            this.on('change:viber', this.changeViber);
            this.on(
              'change:attributes:data-button-icon-enable',
              this.updateButtonIconEnable,
            );
            this.on(
              'change:attributes:data-button-icon-align',
              this.updateButtonIconAlign,
            );
            this.on(
              'change:attributes:data-button-icon-size',
              this.updateButtonIconSize,
            );
            this.on(
              'change:attributes:data-button-icon-indent',
              this.updateButtonIconIndent,
            );
          },
          removeLink(selected) {
            const menuWrapper = selected.closestType('menu-wrapper');

            if (menuWrapper) {
              selected.parent().remove();

              const menuCells = menuWrapper.findType('cell');
              const menuItemLinksLen = menuCells?.length || 0;
              const newWidthSize = 100 / menuItemLinksLen;

              if (menuCells) {
                menuCells.forEach((cell) =>
                  cell.addAttributes({ width: `${Math.abs(newWidthSize)}%` }),
                );
              }
            }
          },
          changeHref(el, value) {
            if (el.get('linkto') === 'web') {
              let href = !/^https?:\/\//i.test(value)
                ? `http://${value}`
                : value;
              el.addAttributes({ href });
            } else {
              el.addAttributes({ href: value });
            }
          },
          changeLinkTo(el, type) {
            const { title, target } = el.getAttributes();

            const hrefTrait = this.getTrait('href');
            const messageSubjectTrait = this.getTrait('messagesubject');
            const messageBodyTrait = this.getTrait('messagebody');
            const emailTrait = this.getTrait('email');
            const anchorLinkTrait = this.getTrait('anchorlink');
            const tagTrait = this.getTrait('tag');
            const telTrait = this.getTrait('telnumber');
            const skypeTrait = this.getTrait('skype');
            const smsTrait = this.getTrait('sms');
            const viberTrait = this.getTrait('viber');
            const fileTrait = this.getTrait('openimageassets');

            hrefTrait.view.el.style.display =
              type === 'web' || type === 'file' ? 'block' : 'none';
            messageSubjectTrait.view.el.style.display =
              type === 'email' ? 'block' : 'none';
            messageBodyTrait.view.el.style.display =
              type === 'email' ? 'block' : 'none';
            emailTrait.view.el.style.display =
              type === 'email' ? 'block' : 'none';
            anchorLinkTrait.view.el.style.display =
              type === 'anchor' ? 'block' : 'none';
            tagTrait.view.el.style.display = type === 'tag' ? 'block' : 'none';
            telTrait.view.el.style.display = type === 'tel' ? 'block' : 'none';
            skypeTrait.view.el.style.display =
              type === 'skype' ? 'block' : 'none';
            smsTrait.view.el.style.display = type === 'sms' ? 'block' : 'none';
            viberTrait.view.el.style.display =
              type === 'viber' ? 'block' : 'none';
            fileTrait.view.el.style.display =
              type === 'file' ? 'block' : 'none';

            // Reset attributes
            el.setAttributes({
              href: '',
              target: target || '_self',
              title: title || '',
            });

            // Reset properties
            el.set('email', '');
            el.set('messagesubject', '');
            el.set('messagebody', '');
            el.set('anchorlink', '');
            el.set('tag', '');
            el.set('telnumber', '');
            el.set('skype', '');
            el.set('sms', '');
            el.set('viber', '');
          },
          changeTag(el, value) {
            if (value === '{$email}') {
              el.addAttributes({ href: `mailto:${value}` });
            } else {
              el.addAttributes({ href: value });
            }
          },
          changeTitle(el, title) {
            el.addAttributes({ title });
          },
          changeEmail(el, email) {
            const emailMesages = buildQueryString({
              ...(el.get('messagesubject') && {
                subject: el.get('messagesubject'),
              }),
              ...(el.get('messagebody') && { body: el.get('messagebody') }),
            });

            el.addAttributes({ href: `mailto:${email}${emailMesages}` });
          },
          changeMessageSubject(el, subject) {
            const emailMesages = buildQueryString({
              subject,
              ...(el.get('messagebody') && { body: el.get('messagebody') }),
            });

            el.addAttributes({
              href: `mailto:${el.get('email')}${emailMesages}`,
            });
          },
          changeMessageBody(el, body) {
            const emailMesages = buildQueryString({
              ...(el.get('messagesubject') && {
                subject: el.get('messagesubject'),
              }),
              body,
            });

            el.addAttributes({
              href: `mailto:${el.get('email')}${emailMesages}`,
            });
          },
          changeAnchorLink(el, anchorlink) {
            el.addAttributes({ href: `#${anchorlink}` });
          },
          changeTelNumber(el, telNumber) {
            if (telNumber) {
              el.addAttributes({
                href: `tel:${telNumber}`,
              });
            }
          },
          changeSkype(el, skypeValue) {
            if (skypeValue) {
              el.addAttributes({
                href: `skype:${skypeValue}`,
              });
            }
          },
          changeSMS(el, skypeValue) {
            if (skypeValue) {
              el.addAttributes({
                href: `sms:${skypeValue}`,
              });
            }
          },
          changeViber(el, skypeValue) {
            if (skypeValue) {
              el.addAttributes({
                href: `viber:${skypeValue}`,
              });
            }
          },
          updateButtonIconEnable(model, buttonIconEnable) {
            if (!buttonIconEnable) {
              // reset all button icon settings
              model.addAttributes({
                'data-button-icon-align': 'left',
                'data-button-icon-size': '32',
                'data-button-icon-indent': '',
              });
              this.set('buttonIcon', '');

              // remove icon image
              const buttonIconImage = model.findType('image');

              if (buttonIconImage.length) {
                buttonIconImage[0].remove();
              }
            }
          },
          updateButtonIconAlign(model, buttonIconAlign) {
            const {
              'data-button-icon-size': buttonIconSize,
              'data-button-icon-indent': buttonIconIndent,
            } = model.get('attributes');

            const buttonIcon = model.get('buttonIcon') || '';
            const buttonIndentStyle =
              buttonIconAlign === 'right'
                ? `margin-left: ${buttonIconIndent}px`
                : `margin-right: ${buttonIconIndent}px`;
            const buttonIconImage = `
              <img
                data-gjs-editable="false"
                data-gjs-collection="false"
                data-gjs-droppable="false"
                data-gjs-draggable="false"
                data-gjs-badgable="false"
                data-gjs-copyable="false"
                data-gjs-removable="false"
                data-gjs-selectable="false"
                data-gjs-hoverable="false"
                data-gjs-resizable="false"
                src="${buttonIcon}"
                alt="icon"
                width="${buttonIconSize}"
                style="vertical-align: middle; ${buttonIndentStyle}"
            />
            `;

            if (buttonIconAlign === 'right' && buttonIcon) {
              model.components(`${model.getEl().innerText} ${buttonIconImage}`);
            }

            if (buttonIconAlign === 'left' && buttonIcon) {
              model.components(`${buttonIconImage} ${model.getEl().innerText}`);
            }
          },
          updateButtonIconSize(model, buttonIconSize) {
            const buttonIconImage = model.findType('image');

            if (buttonIconImage.length) {
              buttonIconImage[0].addAttributes({ width: buttonIconSize });
            }
          },
          updateButtonIconIndent(model, buttonIconSizeIndent) {
            const { 'data-button-icon-align': buttonIconAlign } =
              model.get('attributes');

            const buttonIconImage = model.findType('image');

            if (buttonIconImage.length) {
              buttonIconImage[0].setStyle({
                'vertical-align': 'middle',
                [`${
                  buttonIconAlign === 'right' ? 'margin-left' : 'margin-right'
                }`]: `${buttonIconSizeIndent}px`,
              });
            }
          },
        },
      });
    }
  });

  custom(editor, {});
};
