import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { buildAssetsUrl } from 'helpers';
import { useDebounce } from 'use-debounce';
import ConfirmModal from 'components/Modals/ConfirmModal';
import BaseModal from 'components/Modals/BaseModal';
import ImagesContent from 'components/Images/ImagesContent';
import ImageItem from 'components/Images/ImageItem';
import ReactTooltip from 'react-tooltip';

function TemplateImages({
  closeConfirmTemplateDeleteModal,
  data,
  editor,
  handleDeleteImage,
  id,
  isConfirmTemplateDeleteModalOpen,
  isTemplateImageDeleteLoading,
  loading,
  onSetImageUrl,
  openConfirmTemplateDeleteModal,
  selectedImageUrl,
}) {
  const intl = useIntl();

  const [savedNameForDelete, setSavedNameForDelete] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchValueDebounce] = useDebounce(searchValue, 600);

  const handleItemClick = (name) => {
    onSetImageUrl(buildAssetsUrl(id, name), name);
  };

  return (
    <div className="image-modal__content template-images">
      <div className="image-modal__images-wrapper">
        <div className="image-modal__search-box">
          <input
            className="image-modal__search-box__input"
            type="search"
            placeholder={intl.formatMessage({
              id: 'image-modal.search-box.template-input-placeholder',
            })}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <ImagesContent showBigSpinner={loading}>
          {data
            ?.filter((img) => img.savedName.includes(searchValueDebounce))
            ?.map(({ originalName, savedName }) => (
              <Fragment key={`template-image-${savedName}`}>
                <ImageItem
                  data-tip
                  data-for={`template-image-${savedName}`}
                  thumbUrl={buildAssetsUrl(id, savedName)}
                  description={originalName}
                  selected={selectedImageUrl.includes(savedName)}
                  onItemClick={() => handleItemClick(savedName)}
                  onDelete={() => {
                    setSavedNameForDelete(savedName);
                    openConfirmTemplateDeleteModal();
                  }}
                />
                <ReactTooltip
                  id={`template-image-${savedName}`}
                  type="dark"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  backgroundColor="#000000">
                  {originalName}
                </ReactTooltip>
              </Fragment>
            ))}
        </ImagesContent>
        <BaseModal
          isOpen={isConfirmTemplateDeleteModalOpen}
          headerTitle="image-modal.template-images.confirm-modal-header"
          maxWidth="600px"
          onClose={closeConfirmTemplateDeleteModal}>
          <ConfirmModal
            loading={isTemplateImageDeleteLoading}
            buttonsColor="secondary"
            onClose={closeConfirmTemplateDeleteModal}
            onConfirm={() => {
              handleDeleteImage(editor, savedNameForDelete);
              setSavedNameForDelete('');
            }}>
            <FormattedMessage id="image-modal.template-images.confirm-modal-header.confirm-modal-description" />
          </ConfirmModal>
        </BaseModal>
        {!loading && data?.length === 0 && (
          <div className="image-modal__empty">
            <FormattedMessage id="image-modal.template-images.empty" />
          </div>
        )}
      </div>
    </div>
  );
}

TemplateImages.propTypes = {
  closeConfirmTemplateDeleteModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  editor: PropTypes.object,
  handleDeleteImage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isConfirmTemplateDeleteModalOpen: PropTypes.bool.isRequired,
  isTemplateImageDeleteLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSetImageUrl: PropTypes.func.isRequired,
  openConfirmTemplateDeleteModal: PropTypes.func.isRequired,
  selectedImageUrl: PropTypes.string,
};

export default TemplateImages;
