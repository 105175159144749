import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { userEmailSelector } from 'store/selectors';
import { isEmail } from 'helpers';
import Button from '../../Button';
import './TestEmailModal.sass';
import TextFieldTags from '../../Forms/TextFieldTags';

const messages = defineMessages({
  cancelButton: {
    id: 'button.cancel',
    defaultMessage: 'Cancel',
  },
  sendButton: {
    id: 'button.send-test-email',
    defaultMessage: 'Send test email',
  },
  invalidEmailError: {
    id: 'error.invalid-email',
    defaultMessage: 'Please enter valid email',
  },
  emailLabel: {
    id: 'test-email-modal.email-input-label',
    defaultMessage: 'Test email recipients',
  },
  maximumEmailsMessage: {
    id: 'test-email-modal.maximum-emails-message',
    defaultMessage:
      'Enter email and press enter to add it. Maximum {number} emails',
  },
});

const maximumEmailsNumber = 3;

function TestEmailModal({ intl, loading, onSendTestEmails, onClose }) {
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(null);

  const userEmail = useSelector(userEmailSelector);

  const handleResetError = () => {
    setError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emails.length) {
      setError('Add at least 1 email');
      return;
    }

    handleResetError();
    onSendTestEmails(userEmail, emails);
  };

  const handleValidate = (value) => {
    if (!isEmail(value)) {
      return intl.formatMessage(messages.invalidEmailError);
    }
  };

  return (
    <form className="test-email-modal" onSubmit={handleSubmit}>
      <div className="test-email-modal__content">
        <TextFieldTags
          error={!!error}
          disabled={loading || emails.length >= maximumEmailsNumber}
          placeholder="email@example.com"
          label={intl.formatMessage(messages.emailLabel)}
          aria-label="email"
          name="email"
          helperText={
            error ||
            intl.formatMessage(messages.maximumEmailsMessage, {
              number: maximumEmailsNumber,
            })
          }
          value={emails}
          onChange={setEmails}
          onValidate={handleValidate}
          fullWidth
        />
      </div>
      <div className="test-email-modal__buttons">
        <Button
          type="button"
          className="test-email-modal__button"
          onClick={onClose}>
          {intl.formatMessage(messages.cancelButton)}
        </Button>
        <Button
          type="submit"
          className="test-email-modal__button"
          disabled={loading}
          loading={loading}
          onBlur={handleResetError}
          variant="contained">
          {intl.formatMessage(messages.sendButton)}
        </Button>
      </div>
    </form>
  );
}

TestEmailModal.propTypes = {
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSendTestEmails: PropTypes.func.isRequired,
};

export default injectIntl(TestEmailModal);
