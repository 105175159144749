import {
  cellStyle,
  iconPlaceholer,
  imagePlaceholer,
  imageTextPlaceholer,
  linkedImagePlaceholer,
  tableStyle,
} from 'appConstants';

export default function (editor, { category, baseUrl }, advanced) {
  const bm = editor.BlockManager;
  const newItemTD = (text) => `
    <td
      align="center"
      valign="top" width="25%"
      data-gjs-editable="false"
      data-gjs-collection="false"
      data-gjs-droppable="false"
      data-gjs-draggable="false"
      data-gjs-badgable="false"
      data-gjs-copyable="false"
      data-gjs-removable="false"
      data-gjs-selectable="false"
      data-gjs-hoverable="false"
      data-gjs-resizable="false">
        <a
          style="
            padding: 10px 5px;
            display: block;
            font-size: 20px;
            color: #000;
            text-decoration: none;
            font-family: Arial, Helvetica, sans-serif;
          "
          href="#"
          target="_blank"
          title="${text}">${text}
        </a>
    </td>
  `;
  const socialItem = (socialLink, socialAlt) => `
    <a
      href=""
      data-gjs-type="social-icon"
      data-type="social-icon"
      data-gjs-editable="false"
      style="display: inline-block; margin-right: 4px; margin-left: 4px;"
    >
      <img
        data-gjs-editable="false"
        data-gjs-collection="false"
        data-gjs-droppable="false"
        data-gjs-draggable="false"
        data-gjs-badgable="false"
        data-gjs-copyable="false"
        data-gjs-removable="false"
        data-gjs-selectable="false"
        data-gjs-hoverable="false"
        data-gjs-resizable="false"
        style="width: 32px; height: 32px;"
        width="32"
        height="32"
        src="${socialLink}"
        alt="${socialAlt}"
      />
    </a>
  `;

  bm.add('column1', {
    label: '1 Column',
    category,
    attributes: { class: 'bpreview-create-new-section' },
    content: `
    <table data-type="section" style="${tableStyle}">
      <tbody>
        <tr>
          <td style="${cellStyle}"></td>
        </tr>
      </tbody>
    </table>`,
  });

  bm.add('column2', {
    label: '2 Columns',
    category,
    attributes: { class: 'bpreview-divide-section' },
    content: `
    <table data-type="section" style="${tableStyle}">
      <tr>
        <td style="${cellStyle} width: 50%"></td>
        <td style="${cellStyle} width: 50%"></td>
      </tr>
    </table>`,
  });

  bm.add('column3', {
    label: '3 Columns',
    category,
    content: `
    <table data-type="section" style="${tableStyle}">
      <tr>
        <td style="${cellStyle} width: 33.3333%"></td>
        <td style="${cellStyle} width: 33.3333%"></td>
        <td style="${cellStyle} width: 33.3333%"></td>
      </tr>
    </table>`,
  });

  advanced === 'true' &&
    bm.add('image-block', {
      attributes: { class: 'bpreview-background-image' },
      label: 'Background Image',
      category,
      select: true,
      activate: true,
      content: {
        attributes: { 'data-type': 'image-block' },
        type: 'image-block',
        style: {
          'min-height': '200px',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-attachment': 'scroll',
          'background-size': 'cover',
          'background-image': `url('${baseUrl}/images/image-placeholder.png')`,
          '__background-type': 'image',
          __p: true,
        },
      },
    });

  bm.add('linked-image', {
    attributes: { class: 'bpreview-linked-image' },
    category,
    label: 'Linked image',
    activate: true,
    select: true,
    content: `<a style="display: block; overflow: hidden" data-type="linked-image">${linkedImagePlaceholer}</a>`,
  });

  bm.add('single-image', {
    attributes: { class: 'bpreview-single-image' },
    category,
    label: 'Image',
    activate: true,
    select: true,
    content: imagePlaceholer,
  });

  bm.add('image-text', {
    attributes: { class: 'bpreview-image-text' },
    category,
    label: 'Image + Text',
    activate: true,
    select: true,
    content: `
    <div data-type="image-text" class="image-text">
      <table data-gjs-selectable="false" data-gjs-hoverable="false" data-gjs-editable="false" data-gjs-droppable="img[data-gjs-type=image], p[data-gjs-type=text]" data-type="section" style="${tableStyle}">
        <tbody data-gjs-selectable="false" data-gjs-hoverable="false" data-gjs-editable="false" data-gjs-droppable="img[data-gjs-type=image], p[data-gjs-type=text]">
          <tr data-gjs-selectable="false" data-gjs-hoverable="false" data-gjs-editable="false" data-gjs-droppable="img[data-gjs-type=image], p[data-gjs-type=text]">
            <td data-gjs-selectable="false" data-gjs-hoverable="false" data-gjs-editable="false" data-gjs-droppable="img[data-gjs-type=image], p[data-gjs-type=text]" style="${cellStyle} width: 100%">
              ${imageTextPlaceholer}
              <p>
                This sentence has five words. Here are five more words. Five-word sentences are fine. But several together become monotonous.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  `,
  });

  bm.add('big-heading', {
    attributes: { class: 'bpreview-big-heading' },
    label: 'Heading',
    category,
    content: '<h1>Insert your heading here</h1>',
  });

  bm.add('text-section', {
    attributes: { class: 'bpreview-paragraph' },
    category,
    label: 'Text',
    content:
      '<p style="text-align: left;">This sentence has five words. Here are five more words. Five-word sentences are fine. But several together become monotonous.</p>',
  });

  bm.add('spacer', {
    label: 'Space',
    category,
    attributes: { class: 'bpreview-add-space' },
    content:
      '<div data-gjs-type="spacer" style="width: 100%; height: 50px"></div>',
  });

  bm.add('divider', {
    label: 'Divider',
    category,
    content:
      '<div data-gjs-type="divider" style="width: 100%; margin-top: 10px; margin-bottom: 10px; background-color: rgba(0, 0, 0, 0.1); height: 1px;"></div>',
    attributes: { class: 'bpreview-divider' },
  });

  bm.add('button', {
    label: 'Button',
    category,
    content:
      '<a data-gjs-type="button" data-type="button" data-button-icon-align="left" data-button-icon-size="32" data-button-icon-indent="" style="display: inline-block; font-size: 16px; padding: 20px 28px;background-color: rgb(217, 131, 166); color: rgb(255, 255, 255); text-align: center; border-radius: 5px; font-weight: 300; text-decoration: none;">Insert text here</a>',
    attributes: { type: 'button', class: 'bpreview-button' },
  });

  bm.add('icon', {
    label: 'Icon',
    category,
    attributes: { class: 'bpreview-icon' },
    activate: true,
    select: true,
    content: iconPlaceholer,
  });

  bm.add('link-block', {
    category,
    label: 'Link',
    attributes: { class: 'bpreview-link' },
    content:
      '<a href="" style="color: #d983a6; display: inline-block">This is a link</a>',
  });

  bm.add('video', {
    label: 'Video',
    category,
    attributes: { class: 'bpreview-video' },
    content: {
      type: 'embed-video',
    },
  });

  bm.add('menu', {
    label: 'Menu',
    category,
    attributes: { class: 'bpreview-menu' },
    content: `
    <table
      data-type="menu-wrapper"
      data-gjs-type="menu-wrapper"
      width="100%"
      border-collapse="collapse"
      border-spacing="0"
      style="width:100%"
    >
      <tbody
        data-gjs-editable="false"
        data-gjs-collection="false"
        data-gjs-droppable="false"
        data-gjs-draggable="false"
        data-gjs-badgable="false"
        data-gjs-copyable="false"
        data-gjs-removable="false"
        data-gjs-selectable="false"
        data-gjs-hoverable="false"
        data-gjs-resizable="false"
      >
        <tr
          data-gjs-editable="false"
          data-gjs-collection="false"
          data-gjs-droppable="false"
          data-gjs-draggable="false"
          data-gjs-badgable="false"
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-selectable="false"
          data-gjs-hoverable="false"
          data-gjs-resizable="false"
        >
          ${newItemTD('New Item 1')}
          ${newItemTD('New Item 2')}
          ${newItemTD('New Item 3')}
          ${newItemTD('New Item 4')}
        </tr>
      </tbody>
    </table>
    `,
  });

  bm.add('social-icon-wrapper', {
    label: 'Social (Beta)',
    category,
    attributes: { class: 'bpreview-social' },
    content: `
    <div
      data-gjs-editable="false"
      data-type="social-icon-wrapper"
      data-gjs-type="social-icon-wrapper"
      data-social-icon-size="32"
      data-social-icon-style="preview-32-circle-colored"
      style="text-align: center"
    >
      ${socialItem(
        'https://cdn11.waymore.io/dnd/images/social/social-icons/circle-colored/facebook-circle-colored.png',
        'facebook',
      )}
      ${socialItem(
        'https://cdn11.waymore.io/dnd/images/social/social-icons/circle-colored/instagram-circle-colored.png',
        'instagram',
      )}
      ${socialItem(
        'https://cdn11.waymore.io/dnd/images/social/social-icons/circle-colored/youtube-circle-colored.png',
        'youtube',
      )}
    </div>
    `,
  });

  bm.add('timer', {
    label: 'Timer',
    category,
    attributes: { class: 'bpreview-timer' },
    content: {
      type: 'timer',
    },
  });
}
