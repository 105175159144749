import cleaner from './Cleaner';
import customCode from './CustomCode';
import generalSettings from './GeneralSettings';
import image from './Image';
import importHtml from './Import';
import outsidePageWrapperWatcher from './OutsidePageWrapperWatcher';
import personalNotes from './PersonalNotes';
import exportModal from './Export';
import historyPanel from './VersionHistory';
import viewCode from './ViewCode';
import wayMore from './WayMore';
import zoom from './Zoom';

export default (editor, config = {}) => {
  cleaner(editor, config);
  customCode(editor, config);
  generalSettings(editor, config);
  image(editor, config);
  importHtml(editor, config);
  outsidePageWrapperWatcher(editor, config);
  personalNotes(editor, config);
  exportModal(editor, config);
  historyPanel(editor, config);
  viewCode(editor, config);
  wayMore(editor, config);
  zoom(editor, config);
};
