import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { showErrorMessage, templateSaveStatus } from 'helpers';
import Modal from 'react-modal';
import grapesjs from 'grapesjs';
import grapesjsStyleBgPlugin from 'grapesjs-style-bg';
import grapesjsTuiImageEditorPlugin from 'grapesjs-tui-image-editor';
import { useHistory, Prompt } from 'react-router-dom';
import { difference } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAdminSelector,
  getCustomBlocksGroupSelector,
  subscriptionPlanSelector,
} from 'store/selectors';
import {
  getCustomFields,
  deleteCategory,
  updateCustomBlocksGroup,
  createCustomBlocksGroup,
  getCustomBlocksGroup,
} from 'store/actions';
import 'grapesjs/dist/css/grapes.min.css';
import 'grapick/dist/grapick.min.css';
import {
  PREVIEW_SIZES,
  LOAD_STATUS,
  CUSTOM_MODULES_SELECTED_CATEGORIES,
} from 'appConstants';
import PersonalNotes from 'components/Modals/PersonalNotes';
import ExportModal from 'components/Modals/ExportModal';
import VersionHistory from 'components/Modals/VersionHistory';
import CleanModal from 'components/Modals/CleanModal';
import GeneralSettingsModal from 'components/Modals/GeneralSettingsModal';
import ImageModal from 'components/Modals/ImageModal';
import BaseModal, {
  contentStyle,
  overlayStyle,
} from 'components/Modals/BaseModal';
import WayMoreProductModal from 'components/Modals/WayMoreProductModal';
import TestEmailModal from 'components/Modals/TestEmailModal';
import BlockModal from 'components/Modals/BlockModal';
import CodeImportModal from 'components/Modals/CodeImportModal';
import ViewCodeModal from 'components/Modals/ViewCodeModal';
import CustomModulCategoryModal from 'components/Modals/CustomModulCategoryModal';
import CustomCodeModal from 'components/Modals/CustomCodeModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import {
  useBlocks,
  useToggleTypes,
  useModal,
  useQuery,
  useTemplateImages,
} from 'hooks';
import { useEditorContext } from '../EditorContext';
import EditorHeader from '../components/EditorHeader';
import PreviewHeader from '../components/PreviewHeader';
import EditorContent from '../components/EditorContent';
import blocks from './Blocks';
import modules from './Modules';
import wayMore from './WayMore';
import panels from './Panels';
import {
  commandsConfig,
  panelsConfig,
  styleManagerConfig,
  canvasCss,
  protectedCss,
  listeners,
  richTextEditorConfig,
  richTextEditor,
  addCustomFields,
} from './config';
import './Grapes.sass';

const extendBorder = (editor) => {
  editor.StyleManager.addBuiltIn('border', {
    toStyle(values, { property }) {
      const propWidth = property.getProperty('border-width');
      const width = `${propWidth.getValue()}${propWidth.getUnit()}`;
      const style = property.getProperty('border-style').getValue();
      const color = property.getProperty('border-color').getValue();
      return {
        border: `${width} ${style} ${color}`,
        'border-top': '',
        'border-right': '',
        'border-bottom': '',
        'border-left': '',
      };
    },
  });
};

const refreshCustomModulesList = async (
  editor,
  getBlocks,
  categoriesNames,
  addBlock,
  setBlocks,
  setCustomModulesGroupActions,
  deleteModuleCategoryConfirmModal,
  editModuleCategoryModal,
  setCustomModuleGroupsCount,
  advanced,
) => {
  const blocks = await getBlocks();

  const customBlocks = editor.BlockManager.getAll().filter((block) =>
    [
      ...categoriesNames.map((item) => item.name),
      customBlocksCategoryId,
    ].includes(block.get('categorySet')),
  );

  customBlocks.forEach((block) => {
    editor.BlockManager.remove(block.id);
  });

  const classifiedItemsCats = [];

  [
    ...CUSTOM_MODULES_SELECTED_CATEGORIES,
    ...difference(
      categoriesNames.map((item) => item.name.toLowerCase()),
      CUSTOM_MODULES_SELECTED_CATEGORIES,
    ),
  ].forEach((catItem) => {
    const filteredItems = blocks
      .slice()
      .filter(
        (filterItem) => catItem === filterItem.category?.name.toLowerCase(),
      )
      .sort((a, b) => {
        const hasNumA = /\d/.test(a.name);
        const hasNumB = /\d/.test(b.name);

        if (!hasNumA && !hasNumB) {
          return a.name.localeCompare(b.name);
        }

        if (!hasNumA) {
          return -1;
        }

        if (!hasNumB) {
          return 1;
        }

        const numA = parseInt(a.name.match(/\d+/)[0], 10);
        const numB = parseInt(b.name.match(/\d+/)[0], 10);

        return numA - numB;
      });

    if (filteredItems && filteredItems?.length > 0) {
      classifiedItemsCats.push(filteredItems);
    }
  });

  const sortedBlocks = [
    ...classifiedItemsCats,
    ...blocks
      .slice()
      .filter((item) => !item.category)
      .sort((a, b) => b.createdAt - a.createdAt),
  ].flat();

  sortedBlocks.forEach(addBlock);

  await setBlocks(editor);

  const categories = editor.BlockManager.getCategories();

  setCustomModulesGroupActions(
    categories,
    deleteModuleCategoryConfirmModal,
    editModuleCategoryModal,
  );

  editor.BlockManager.getCategories().forEach((categoryItem) => {
    categoryItem.set('open', false);

    if (['Basic'].includes(categoryItem.id)) {
      if (advanced !== 'true') {
        categoryItem.view.el.classList.add('simple-mode');
      }

      categoryItem.set('open', true);
    }

    if (categoryItem.id === 'My modules') {
      categoryItem.set('attributes', {
        class: 'gjs-block-category--custom',
      });
      categoryItem.set('open', true);
    }
  });

  const CBGroupsCount = new Set();
  sortedBlocks.forEach((item) => {
    if (item?.category?.name !== undefined) {
      CBGroupsCount.add(item.category.name);
    }
  });

  setCustomModuleGroupsCount(CBGroupsCount.size);
};

const customBlocksCategoryId = 'My modules';

const Grapes = ({ onLoad }) => {
  const history = useHistory();

  const isAdmin = useSelector(isAdminSelector);

  const { advanced = 'false', system } = useQuery();

  const categoriesNames = useSelector(getCustomBlocksGroupSelector);
  const subscriptionPlan = useSelector(subscriptionPlanSelector);

  const dispatch = useDispatch();

  const {
    template: value,
    generalSettings,
    haveChanges,
    show,
    isUpdateLoading,
    isStatusLoading,
    isConvertLoading,
    isSendEmailLoading,
    onChangeInfo,
    onChangeTemplate,
    onChangeStatus,
    onSaveTemplate,
    onConvertToSystem,
    onShow,
    onSendTestEmails,
    onSetGeneralSettings,
    onRestartEditorTour,
  } = useEditorContext();

  const editor = useRef(null);
  const muteChange = useRef(true);

  const {
    isBlocksLoading,
    getBlocks,
    saveBlock,
    deleteBlock,
    setBlocks,
    setCustomModulesGroupActions,
  } = useBlocks();

  const { handleUploadImage } = useTemplateImages(value.id);

  const {
    isOpen: isConfirmDeleteModalOpen,
    open: openConfirmDeleteModal,
    close: closeConfirmDeleteModal,
  } = useModal();

  const {
    isOpen: isConfirmDeleteModuleCatModalOpen,
    open: openConfirmDeleteModuleCatModal,
    close: closeConfirmDeleteModuleCatModal,
  } = useModal();

  const [isComponentSettingsCollapsed, setIsComponentSettingsCollapsed] =
    useState(false);
  const [searchBlocksTerm, setSearchBlocksTerm] = useState('');
  const [moduleStatus, setModuleStatus] = useState('create');
  const [isPreview, setIsPreview] = useState(false);
  const [previewSize, setPreviewSize] = useState(PREVIEW_SIZES[0]);
  const [moduleTempId, setModuleTempId] = useState(null);
  const [moduleGroupTempId, setModuleGroupTempId] = useState(null);
  const [moduleGroupTempCategory, setModuleGroupTempCategory] = useState({});
  const [moduleDeleteStatus, setModuleDeleteStatus] = useState(
    LOAD_STATUS.IDLE,
  );
  const [moduleGroupDeleteStatus, setModuleGroupDeleteStatus] = useState(
    LOAD_STATUS.IDLE,
  );
  const [moduleGroupEditStatus, setModuleGroupEditStatus] = useState(
    LOAD_STATUS.IDLE,
  );

  const [blocksTab, setBlocksTab] = useState('blocks');
  const [customModuleGroupsCount, setCustomModuleGroupsCount] = useState(0);

  useEffect(() => {
    const templateValue = value.template;

    if (value) {
      editor.current = grapesjs.init({
        container: '#canvas',
        colorPicker: {
          preferredFormat: 'hex',
          showInput: true,
          showInitial: true,
        },
        storageManager: { type: null },
        avoidInlineStyle: true,
        allowScripts: 1,
        protectedCss,
        showOffsets: true,
        canvasCss,
        panels: panelsConfig(advanced, system),
        styleManager: styleManagerConfig,
        commands: commandsConfig,
        richTextEditor: richTextEditorConfig,
        showDevices: false,
        height: 'auto', // Oddly defaults to 900px, and is set inline
        width: 'auto',
        blockManager: {
          appendTo: '.editor-content__blocks-list',
        },
        traitManager: {
          appendTo: '#component-settings',
        },
        plugins: [
          extendBorder,
          grapesjsStyleBgPlugin,
          grapesjsTuiImageEditorPlugin,
        ],
        pluginsOpts: {
          [grapesjsTuiImageEditorPlugin]: {
            onApply: async (imageEditor) => {
              const base64Image = imageEditor.toDataURL();
              const applyButton = document.querySelector(
                '.tui-image-editor__apply-btn',
              );
              applyButton.disabled = true;
              applyButton.innerHTML =
                '<span class="icon icon-spinner"></span> Loading';

              try {
                const rndNumber = new Date().valueOf();
                const base64Response = await fetch(base64Image);
                const blob = await base64Response.blob();

                const fileResult = new File(
                  [blob],
                  `edited_image_${rndNumber}.jpg`,
                  {
                    type: blob.type,
                    lastModified: new Date(),
                  },
                );

                await handleUploadImage([fileResult]);

                editor.current.runCommand('open-assets', { isImage: true });
              } catch (error) {
                showErrorMessage('Something went wrong. Try again!');
              } finally {
                editor.current.Modal.close();
                applyButton.disabled = false;
                applyButton.innerHTML = 'Apply';
              }
            },
            addToAssets: false,
            upload: false,
            width: '100%',
            config: {
              includeUI: {
                initMenu: 'filter',
              },
            },
          },
        },
      });
      // stop tracking changes to prevent undo of the initial setup
      editor.current.UndoManager.stop();

      blocks(
        editor.current,
        subscriptionPlan?.createCustomCode,
        subscriptionPlan?.editCustomCode,
        advanced,
        dispatch,
        value.id,
      );
      modules(editor.current);
      wayMore(editor.current, subscriptionPlan?.waymoreIntegration);
      panels(editor.current, advanced);
      listeners(editor.current, advanced);
      richTextEditor(editor.current);

      editor.current.setComponents(templateValue.components);
      editor.current.setStyle(templateValue.css);

      // resume tracking changes
      editor.current.UndoManager.start();

      editor.current.on('update', () => {
        if (!muteChange.current) {
          onChangeTemplate();
        }
        muteChange.current = false;
      });

      editor.current.getModel().set('onShow', onShow);
      editor.current
        .getModel()
        .set(
          'setIsComponentSettingsCollapsed',
          setIsComponentSettingsCollapsed,
        );
      editor.current
        .getModel()
        .set('onSetGeneralSettings', onSetGeneralSettings);
      editor.current.getModel().set('setSearchBlocksTerm', setSearchBlocksTerm);
      editor.current.getModel().set('setBlocks', setBlocks);
      editor.current.getModel().set('setBlocksTab', setBlocksTab);
      editor.current.getModel().set('customBlocksGroupList', categoriesNames);
      editor.current.getModel().set('setModuleStatus', setModuleStatus);
      editor.current
        .getModel()
        .set('setCustomModulesGroupActions', setCustomModulesGroupActions);
      editor.current
        .getModel()
        .set(
          'deleteModuleCategoryConfirmModal',
          deleteModuleCategoryConfirmModal,
        );
      editor.current
        .getModel()
        .set('editModuleCategoryModal', editModuleCategoryModal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChangeTemplate, history, value.templateId]);

  useEffect(() => {
    editor.current.on('load', onLoad);
  }, [onLoad]);

  useEffect(() => {
    templateSaveStatus(haveChanges);
  }, [haveChanges]);

  // callback function to add block to the editor
  // it's defined here because we need closure to onShow, deleteBlock functions
  // and also editor
  const addBlock = (block) => {
    const { id, name, description, html, css, previewUrl, updatedAt } = block;

    editor.current.BlockManager.add(id, {
      label: name,
      category: block.category ? block.category.name : customBlocksCategoryId,
      categorySet: block.category
        ? block.category.name
        : customBlocksCategoryId,
      attributes: { class: 'gjs-thumb-label gjs-thumb-label--custom' },
      content: `${html}<style>${css}</style>`,
      render: ({ el }) => {
        const buttonsWrapper = document.createElement('div');
        buttonsWrapper.className = 'gjs-thumb-label__buttons-wrapper';

        const editButton = document.createElement('button');
        editButton.title = 'Edit';
        editButton.className = `gjs-thumb-label__btn ${
          isAdmin && block?.category?.group === 'system'
            ? 'secondary'
            : 'default'
        }`;
        editButton.innerHTML = '<span class="icon icon-edit"></span>';
        editButton.addEventListener('click', function () {
          onShow(useToggleTypes.showBlockModal, block);
          setModuleStatus('Edit');
        });

        const deleteButton = document.createElement('button');
        deleteButton.title = 'Delete';
        deleteButton.className = `gjs-thumb-label__btn ${
          isAdmin && block?.category?.group === 'system'
            ? 'secondary'
            : 'default'
        }`;
        deleteButton.innerHTML = '<span class="icon icon-delete"></span>';
        deleteButton.addEventListener('click', async function () {
          openConfirmDeleteModal();
          setModuleTempId(id);
        });

        if (!isAdmin && !block.category) {
          buttonsWrapper.appendChild(editButton);
          buttonsWrapper.appendChild(deleteButton);
        } else if (isAdmin) {
          buttonsWrapper.appendChild(editButton);
          buttonsWrapper.appendChild(deleteButton);
        }

        el.innerHTML = `<img src="${previewUrl}?${updatedAt}" alt="${name}"/>`;
        el.appendChild(buttonsWrapper);

        // Hover over every custom module
        const customModulePreview = document.querySelector(
          '.custom-module-preview',
        );
        const customModulePreviewHover = () => {
          customModulePreview.querySelector('h1').innerText = name;
          customModulePreview.querySelector('p').innerText = description;
          customModulePreview
            .querySelector('img')
            .setAttribute('src', previewUrl);
          customModulePreview.querySelector('img').setAttribute('alt', name);
          customModulePreview.classList.add('custom-module-preview__show');
        };
        const customModulePreviewHoverOut = () => {
          customModulePreview.classList.remove('custom-module-preview__show');
          customModulePreview
            .querySelector('img')
            .setAttribute('src', '/images/image-loading.svg');
        };

        el.addEventListener('mouseenter', customModulePreviewHover);
        el.addEventListener('mouseleave', customModulePreviewHoverOut);
        el.addEventListener('drag', customModulePreviewHoverOut);
      },
    });
  };

  useEffect(() => {
    editor.current.on('load', async () => {
      refreshCustomModulesList(
        editor.current,
        getBlocks,
        categoriesNames,
        addBlock,
        setBlocks,
        setCustomModulesGroupActions,
        deleteModuleCategoryConfirmModal,
        editModuleCategoryModal,
        setCustomModuleGroupsCount,
        advanced,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteBlock, getBlocks, onShow]);

  useEffect(() => {
    editor.current.on('load', async () => {
      const { payload } = await dispatch(getCustomFields());

      addCustomFields(editor.current, payload);
    });
  }, [dispatch]);

  const handleClosePersonalNotes = () => {
    onShow(useToggleTypes.showPersonalNotesModal);
  };

  const handleCloseExportModal = () => {
    onShow(useToggleTypes.showExportModal);
  };

  const handleCloseVersionHistory = () => {
    onShow(useToggleTypes.showVersionHistory);
  };

  const handleCloseCustomModulCategoryModal = () => {
    onShow(useToggleTypes.showCustomModulCategoryModal);
  };

  const deleteModuleCategoryConfirmModal = (id) => {
    setModuleGroupTempId(id);
    openConfirmDeleteModuleCatModal();
  };

  const handleDeleteModuleCategory = async () => {
    try {
      setModuleGroupDeleteStatus(LOAD_STATUS.LOADING);
      const [{ id }] = categoriesNames.filter(
        (item) => item.name === moduleGroupTempId,
      );

      await dispatch(deleteCategory(id)).unwrap();
      setModuleGroupDeleteStatus(LOAD_STATUS.RESOLVED);
    } catch {
      setModuleGroupDeleteStatus(LOAD_STATUS.REJECTED);
    } finally {
      closeConfirmDeleteModuleCatModal();
      setModuleGroupTempId(null);
      refreshCustomModulesList(
        editor.current,
        getBlocks,
        categoriesNames,
        addBlock,
        setBlocks,
        setCustomModulesGroupActions,
        deleteModuleCategoryConfirmModal,
        editModuleCategoryModal,
        setCustomModuleGroupsCount,
        advanced,
      );
    }
  };

  const handleDeleteModule = async () => {
    try {
      setModuleDeleteStatus(LOAD_STATUS.LOADING);
      await deleteBlock(moduleTempId);

      editor.current.BlockManager.remove(moduleTempId);
      setModuleDeleteStatus(LOAD_STATUS.RESOLVED);
    } catch {
      setModuleDeleteStatus(LOAD_STATUS.REJECTED);
    } finally {
      setModuleTempId(null);
      closeConfirmDeleteModal();
      refreshCustomModulesList(
        editor.current,
        getBlocks,
        categoriesNames,
        addBlock,
        setBlocks,
        setCustomModulesGroupActions,
        deleteModuleCategoryConfirmModal,
        editModuleCategoryModal,
        setCustomModuleGroupsCount,
        advanced,
      );
    }
  };

  const editModuleCategoryModal = (groupName) => {
    const [{ id, name }] = categoriesNames.filter(
      (item) => item.name === groupName,
    );
    setModuleGroupTempCategory({
      id,
      name,
    });
    onShow(useToggleTypes.showCustomModulCategoryModal);
  };

  const handleSaveCustomModuleCategory = async (values) => {
    try {
      setModuleGroupEditStatus(LOAD_STATUS.LOADING);

      if (moduleGroupTempCategory) {
        await dispatch(
          updateCustomBlocksGroup({
            id: moduleGroupTempCategory.id,
            name: values.name.trim(),
          }),
        ).unwrap();
      } else {
        await dispatch(
          createCustomBlocksGroup({ name: values.name.trim() }),
        ).unwrap();
      }

      handleCloseCustomModulCategoryModal();
      setModuleGroupEditStatus(LOAD_STATUS.RESOLVED);
    } catch {
      setModuleGroupEditStatus(LOAD_STATUS.REJECTED);
    } finally {
      const customBlocksGroupList = await dispatch(
        getCustomBlocksGroup(),
      ).unwrap();

      setModuleGroupTempCategory(null);
      editor.current
        .getModel()
        .set('customBlocksGroupList', customBlocksGroupList);

      await refreshCustomModulesList(
        editor.current,
        getBlocks,
        categoriesNames,
        addBlock,
        setBlocks,
        setCustomModulesGroupActions,
        deleteModuleCategoryConfirmModal,
        editModuleCategoryModal,
        setCustomModuleGroupsCount,
        advanced,
      );
    }
  };

  const handlePreviewSizeChange = (size = 'pc') => {
    editor.current.Panels.getButton(
      'responsive-actions',
      `preview-${size === 'pc' ? 'desktop' : size}`,
    ).set('active', true);

    const canvasFrames = document.getElementsByClassName(
      'gjs-cv-canvas__frames',
    )[0];

    canvasFrames.classList.remove(...PREVIEW_SIZES);
    canvasFrames.classList.add(currentDevicePreview());

    setPreviewSize(size);
  };

  const handleSaveSettings = () => {
    editor.current.runCommand('set-general-settings', generalSettings);
    onShow(useToggleTypes.showGeneralSettings);
  };

  const handleSaveTemplate = () => {
    onSaveTemplate(editor.current);
  };

  const handleSetImageUrl = ({ isIcon, url, imageName }) => {
    if (url) {
      editor.current.runCommand('set-image-url', { url, imageName });
    }

    if (isIcon) {
      onShow(useToggleTypes.showIconModal);
      return;
    }

    onShow(useToggleTypes.showImageModal);
  };

  const handleChangeSearchBlocksTerm = async ({ target: { value } }) => {
    setSearchBlocksTerm(value);
    await setBlocks(editor.current, value);
  };

  const handleCloseWayMoreProductModal = () => {
    const selected = editor.current.getSelected();

    if (!selected.view.el.innerHTML) {
      selected.remove();
    }

    onShow(useToggleTypes.showWayMoreProductModal);
  };

  const handleSaveWayMoreProduct = (
    layout,
    showCategory,
    showDescription,
    product,
  ) => {
    const selected = editor.current.getSelected();

    editor.current.runCommand('set-waymore-product', {
      layout,
      showCategory,
      showDescription,
      product,
      oldProduct: selected,
    });

    selected.remove();

    onShow(useToggleTypes.showWayMoreProductModal);
  };

  const handleCloseBlockModal = () => {
    onShow(useToggleTypes.showBlockModal);
  };

  const handleCloseCodeImportModal = () => {
    onShow(useToggleTypes.showCodeImportModal);
  };

  const handleCloseViewCodeModal = () => {
    onShow(useToggleTypes.showViewCodeModal);
  };

  const handleCloseCustomCodeModal = () => {
    onShow(useToggleTypes.showCustomCodeModal);
  };

  const handleSaveBlock = async (payload) => {
    try {
      await saveBlock(payload, isAdmin);

      handleCloseBlockModal();
      // eslint-disable-next-line no-empty
    } catch {
    } finally {
      refreshCustomModulesList(
        editor.current,
        getBlocks,
        categoriesNames,
        addBlock,
        setBlocks,
        setCustomModulesGroupActions,
        deleteModuleCategoryConfirmModal,
        editModuleCategoryModal,
        setCustomModuleGroupsCount,
        advanced,
      );
    }
  };

  const togglePreview = () => {
    if (!isPreview) {
      // enable preview
      setIsPreview(true);

      // run original command
      editor.current.runCommand('preview');

      // add preview class for canvas
      const canvas = document.getElementsByClassName('gjs-cv-canvas')[0];
      canvas.classList.add('preview');

      // remove default close button
      const defaultCloseBtn =
        document.getElementsByClassName('fa-eye-slash')[0];
      if (defaultCloseBtn) {
        defaultCloseBtn.remove();
      }

      // hide iframe scrollbar
      const iframe = document.getElementsByClassName('gjs-frame')[0];
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      if (iframeDocument) {
        const css = `
          *::-webkit-scrollbar {
            width: 0px;
          }

          [data-gjs-type="column"]:empty:before {
            content: none;
          }
        `;
        const style = iframeDocument.createElement('style');
        style.type = 'text/css';
        style.id = 'preview-style';
        style.setAttribute('id', 'preview-style');
        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(iframeDocument.createTextNode(css));
        }
        iframeDocument.getElementsByTagName('body')[0].appendChild(style);
      }

      // add size preview class for frame container
      const canvasFrames = document.getElementsByClassName(
        'gjs-cv-canvas__frames',
      )[0];
      canvasFrames.classList.add(currentDevicePreview());
      return;
    }

    // disable preview
    setIsPreview(false);

    // restore iframe scrollbar
    const iframe = document.getElementsByClassName('gjs-frame')[0];
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    if (iframeDocument) {
      const previewStyle = iframeDocument.getElementById('preview-style');
      iframeDocument.getElementsByTagName('body')[0].removeChild(previewStyle);
    }

    // remove preview class from canvas
    const canvas = document.getElementsByClassName('gjs-cv-canvas')[0];
    canvas.classList.remove('preview');

    // remove size preview class from frame container
    const canvasFrames = document.getElementsByClassName(
      'gjs-cv-canvas__frames',
    )[0];
    canvasFrames.classList.remove(previewSize);

    // stop original command
    editor.current.stopCommand('preview');

    // refresh editor
    editor.current.refresh();
  };

  const currentDevicePreview = () => {
    const currectDevice = ['desktop', 'tablet', 'mobile']
      .filter((device) =>
        editor.current.Panels.getButton(
          'responsive-actions',
          `preview-${device}`,
        ).get('active'),
      )
      .join('');

    return currectDevice === 'desktop' ? 'pc' : currectDevice;
  };

  const handleClearCanvas = () => {
    editor.current.DomComponents.clear();
    editor.current.setStyle('[data-gjs-type=wrapper] { background: #fff }');
    editor.current.setComponents(`
      <table
        data-type="root"
        style="
          width: 100%;
          padding: 0 20px;
          max-width: 700px;
          height: 100%;
          margin: 0 auto;
          background-color: #ffffff;
        ">
        <tbody data-type="root-body">
          <tr data-type="root-row">
            <td data-type="root-cell" style="vertical-align: top; width: 100%; height: 100%;"></td>
          </tr>
        </tbody>
      </table>
  `);
  };

  let testEmailModal;

  if (show.testEmailModal) {
    // changes should be saved before user sends email to test
    // so first show confirm save modal if there is changes
    if (haveChanges) {
      testEmailModal = (
        <BaseModal
          isOpen={show.testEmailModal}
          headerTitle="test-email-modal.confirm-save-title"
          onClose={() => onShow(useToggleTypes.showTestEmailModal)}
          maxWidth="700px">
          <ConfirmModal
            onConfirm={() => handleSaveTemplate(editor.current)}
            loading={isUpdateLoading}
            onClose={() => onShow(useToggleTypes.showTestEmailModal)}>
            <FormattedMessage id="test-email-modal.confirm-save-text" />
          </ConfirmModal>
        </BaseModal>
      );
    } else {
      testEmailModal = (
        <BaseModal
          isOpen={show.testEmailModal}
          headerTitle="test-email-modal.header"
          onClose={() => onShow(useToggleTypes.showTestEmailModal)}
          maxWidth="700px">
          <TestEmailModal
            loading={isSendEmailLoading}
            onClose={() => onShow(useToggleTypes.showTestEmailModal)}
            onSendTestEmails={onSendTestEmails}
          />
        </BaseModal>
      );
    }
  }

  return (
    <div id="grapes" className="grapes">
      <EditorHeader
        template={value}
        isConvertLoading={isConvertLoading}
        isUpdateLoading={isUpdateLoading}
        isStatusLoading={isStatusLoading}
        haveChanges={haveChanges}
        show={show}
        onShow={onShow}
        onConvertToSystem={onConvertToSystem}
        onChangeStatus={() => onChangeStatus()}
        onSaveTemplate={handleSaveTemplate}
        onChangeInfo={onChangeInfo}
        onRestartEditorTour={onRestartEditorTour}
        onToggleTestEmail={() => onShow(useToggleTypes.showTestEmailModal)}
        onTogglePreview={togglePreview}
      />
      {isPreview && (
        <PreviewHeader
          onPreviewSizeChange={handlePreviewSizeChange}
          onTogglePreview={togglePreview}
          currentDevicePreview={currentDevicePreview()}
          templateStatus={value.status}
        />
      )}
      <EditorContent
        editor={editor.current}
        searchBlocksTerm={searchBlocksTerm}
        onChangeSearchBlocksTerm={handleChangeSearchBlocksTerm}
        onShow={editor?.current?.getModel().get('onShow')}
        blocksTab={blocksTab}
        customModuleGroupsCount={customModuleGroupsCount}
        setModuleGroupTempCategory={setModuleGroupTempCategory}
        setIsComponentSettingsCollapsed={setIsComponentSettingsCollapsed}
        isComponentSettingsCollapsed={isComponentSettingsCollapsed}
      />
      <BaseModal
        isOpen={show.clean}
        onClose={() => {
          onShow(useToggleTypes.showClean);
        }}
        style={{ width: '700px', maxWidth: '100%' }}
        headerTitle="clean-modal.title"
        iconTitleClass="icon icon-delete">
        <CleanModal
          onClose={() => {
            onShow(useToggleTypes.showClean);
          }}
          handleClean={handleClearCanvas}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.generalSettings}
        headerTitle="general-settings-modal.title"
        onClose={() => onShow(useToggleTypes.showGeneralSettings)}>
        <GeneralSettingsModal
          onSaveSettings={handleSaveSettings}
          onClose={() => onShow(useToggleTypes.showGeneralSettings)}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.showImageModal}
        onClose={() => onShow(useToggleTypes.showImageModal)}
        headerTitle="image-modal.title">
        <ImageModal
          id={value.id}
          editor={editor.current}
          onSetImageUrl={handleSetImageUrl}
        />
      </BaseModal>
      <Modal
        isOpen={show.wayMoreProductModal}
        onRequestClose={handleCloseWayMoreProductModal}
        style={{
          overlay: overlayStyle,
          content: {
            ...contentStyle,
            maxWidth: 700,
            overflow: 'visible',
          },
        }}>
        <WayMoreProductModal
          editor={editor?.current}
          onSaveWayMoreProduct={handleSaveWayMoreProduct}
          onClose={handleCloseWayMoreProductModal}
        />
      </Modal>
      {testEmailModal}
      <BaseModal
        isOpen={show.showIconModal}
        onClose={() => onShow(useToggleTypes.showIconModal)}
        headerTitle="icon-modal.title">
        <ImageModal
          withIconsTab
          id={value.id}
          editor={editor.current}
          onSetImageUrl={handleSetImageUrl}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.blockModal}
        onClose={handleCloseBlockModal}
        maxWidth="80%"
        headerTitle={
          moduleStatus === 'create'
            ? 'block-modal.title.create'
            : 'block-modal.title.edit'
        }
        contentClass="block-modal">
        <BlockModal
          block={show.blockModalPayload}
          isBlocksLoading={isBlocksLoading}
          onSaveBlock={handleSaveBlock}
          onClose={handleCloseBlockModal}
          templateId={value.id}
          editor={editor?.current}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.codeImportModal}
        onClose={handleCloseCodeImportModal}
        headerTitle="code-import-modal.modal-header"
        contentClass="code-import-content-modal"
        maxWidth="80%">
        <CodeImportModal
          editor={editor?.current}
          onClose={handleCloseCodeImportModal}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.viewCodeModal}
        onClose={handleCloseViewCodeModal}
        headerTitle="view-code-modal.modal-header"
        contentClass="view-code-content-modal"
        maxWidth="80%">
        <ViewCodeModal editor={editor?.current} />
      </BaseModal>
      <BaseModal
        isOpen={show.customCodeModal}
        onClose={handleCloseCustomCodeModal}
        headerTitle="custom-code-modal.modal-header"
        contentClass="custom-code-content-modal"
        maxWidth="80%">
        <CustomCodeModal
          editor={editor?.current}
          onClose={handleCloseCustomCodeModal}
        />
      </BaseModal>
      <Prompt
        when={haveChanges}
        message="You have unsaved work. If you continue it will be lost. Are you sure you want to leave?"
      />
      <BaseModal
        isOpen={isConfirmDeleteModalOpen}
        headerTitle="block-modal.confirm-modal-header"
        maxWidth="700px"
        onClose={closeConfirmDeleteModal}>
        <ConfirmModal
          loading={moduleDeleteStatus === LOAD_STATUS.LOADING}
          buttonsColor="secondary"
          onClose={closeConfirmDeleteModal}
          onConfirm={handleDeleteModule}>
          <FormattedMessage id="block-modal.confirm-modal-description" />
        </ConfirmModal>
      </BaseModal>
      <BaseModal
        iconTitleClass="icon icon-add-comment"
        isOpen={show.showPersonalNotesModal}
        onClose={handleClosePersonalNotes}
        maxWidth="700px"
        headerTitle="personal-notes.modal-title">
        <PersonalNotes onClose={handleClosePersonalNotes} />
      </BaseModal>
      <BaseModal
        iconTitleClass="icon icon-ios-share"
        isOpen={show.showExportModal}
        onClose={handleCloseExportModal}
        maxWidth="400px"
        headerTitle="export-modal.modal-title">
        <ExportModal onClose={handleCloseExportModal} />
      </BaseModal>
      <BaseModal
        contentClass="version-history-content"
        contentStyle={{
          padding: 0,
        }}
        headerStyle={{
          margin: 0,
          position: 'sticky',
          top: 0,
          background: '#fff',
        }}
        style={{
          maxWidth: '400px',
          borderRadius: 0,
          boxShadow: 'none',
          margin: 0,
          padding: '0 !important',
          border: 'none',
        }}
        iconTitleClass="icon icon-history"
        isOpen={show.showVersionHistory}
        onClose={handleCloseVersionHistory}
        headerTitle="version-history.modal-title">
        <VersionHistory
          editor={editor.current}
          onSaveTemplate={onSaveTemplate}
          onClose={handleCloseVersionHistory}
        />
      </BaseModal>
      <BaseModal
        isOpen={show.showCustomModulCategoryModal}
        onClose={handleCloseCustomModulCategoryModal}
        maxWidth="700px"
        headerTitle="custom-modules-category.modal-title">
        <CustomModulCategoryModal
          onClose={handleCloseCustomModulCategoryModal}
          categoriesNames={categoriesNames}
          category={moduleGroupTempCategory}
          onSaveCustomModuleCategory={handleSaveCustomModuleCategory}
          isLoading={moduleGroupEditStatus === LOAD_STATUS.LOADING}
        />
      </BaseModal>
      <BaseModal
        isOpen={isConfirmDeleteModuleCatModalOpen}
        headerTitle="custom-modules.confirm-modal-header"
        maxWidth="700px"
        onClose={closeConfirmDeleteModuleCatModal}>
        <ConfirmModal
          loading={moduleGroupDeleteStatus === LOAD_STATUS.LOADING}
          buttonsColor="secondary"
          onClose={closeConfirmDeleteModuleCatModal}
          onConfirm={handleDeleteModuleCategory}>
          <FormattedMessage id="custom-modules.confirm-modal-description" />
        </ConfirmModal>
      </BaseModal>
    </div>
  );
};
Grapes.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

export default Grapes;
