import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { isAdminSelector } from 'store/selectors';
import Button from '../../Button';
import './PublishedModal.sass';

function PublishedModal({ loading, onClose, onGoBack, onConvertToSystem }) {
  const isAdmin = useSelector(isAdminSelector);

  return (
    <div className="published-modal">
      <div className="published-modal__content">
        <FormattedMessage id="published-modal.message" />
      </div>
      <div className="published-modal__buttons">
        <Button
          type="button"
          className="published-modal__button"
          onClick={onClose}>
          <FormattedMessage id="published-modal.continue" />
        </Button>
        <Button
          type="button"
          className="published-modal__button"
          variant="contained"
          disabled={loading}
          onClick={onGoBack}>
          <FormattedMessage id="published-modal.go-back" />
        </Button>
        {isAdmin && (
          <Button
            type="button"
            className="published-modal__button"
            variant="contained"
            color="secondary"
            loading={loading}
            disabled={loading}
            onClick={onConvertToSystem}>
            <FormattedMessage id="published-modal.convert-to-system" />
          </Button>
        )}
      </div>
    </div>
  );
}

PublishedModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onConvertToSystem: PropTypes.func.isRequired,
};

export default PublishedModal;
