import {
  showErrorMessage,
  getErrorText,
  checkComponentType,
  buildAssetsUrl,
  setRuleStyle,
  buildCountdownImageUrl,
} from 'helpers';
import { TIME_ZONE } from 'appConstants';
import { httpGetVideoInfo, httpGetVideoMerge } from 'api/general.api';
import { linkTraits, iconTraits } from '../../Panels/custom-traits';

const addImageBlock = (editor, { imageType, imageBlockType }) => {
  const domc = editor.DomComponents;

  domc.addType(imageType, {
    view: {
      init() {
        this.listenTo(this.model, 'change:src', this.updateImage);
      },
      onActive() {
        if (this.model.get('editable')) {
          editor.runCommand('open-assets', { isImage: true });
        }
      },
      updateImage(model) {
        model.addStyle({
          width: '100%',
          height: 'auto',
        });
      },
    },
  });

  domc.addType(imageBlockType, {
    extend: 'default',
    model: {
      defaults() {
        return {
          name: 'Background image',
          type: imageBlockType,
          tagName: 'div',
          void: false,
          droppable: true,
        };
      },
    },
    isComponent(el) {
      if (checkComponentType(el, imageBlockType)) {
        return { type: imageBlockType };
      }
    },
  });
};

const addLinkedImage = (editor, { linkedImageType, linkedImageImageType }) => {
  const domc = editor.DomComponents;

  domc.addType(linkedImageType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Linked image',
        droppable: false,
        resizable: true,
        type: linkedImageType,
        traits: [
          ...linkTraits(),
          {
            type: 'text',
            name: 'alt',
            label: 'Alternate text',
            placeholder: '',
            changeProp: true,
          },
        ],
      },
      init() {
        this.on('change:alt', this.updateImageAlt);
      },
      updateImageAlt(cmp, value) {
        if (cmp.findType('linked-image-image')) {
          const imgCmp = cmp.findType('linked-image-image')[0];

          imgCmp.set('alt', value);
          imgCmp.setAttributes({
            ...imgCmp.getAttributes(),
            alt: value,
          });
        }
      },
    },
    view: {
      onActive() {
        editor.runCommand('open-assets', { isLinkedImage: true });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, linkedImageType)) {
        return { type: linkedImageType };
      }
    },
  });

  domc.addType(linkedImageImageType, {
    extend: 'image',
    model: {
      defaults: {
        editable: true,
        collection: false,
        droppable: false,
        draggable: false,
        badgable: false,
        copyable: false,
        removable: false,
        selectable: false,
        hoverable: false,
      },
    },
    view: {
      onActive() {
        editor.runCommand('open-assets', { isLinkedImage: true });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, linkedImageImageType)) {
        return { type: linkedImageImageType };
      }
    },
  });
};

const addImageText = (editor, { imageTextType }) => {
  const domc = editor.DomComponents;

  domc.addType(imageTextType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Image + Text',
        droppable: false,
        draggable: 'td[data-type="root-cell"]',
        type: imageTextType,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, imageTextType)) {
        return { type: imageTextType };
      }
    },
  });
};

const addButton = (editor, { buttonType }) => {
  const domc = editor.DomComponents;

  domc.addType(buttonType, {
    extend: 'link',
    model: {
      defaults: {
        droppable: false,
        type: buttonType,
        buttonIcon: '',
        tagName: 'a',
        traits: [...linkTraits(), ...iconTraits()],
      },
      init() {
        this.on(
          'change:attributes:data-button-icon-enable',
          this.updateButtonIconEnable,
        );
        this.on(
          'change:attributes:data-button-icon-align',
          this.updateButtonIconAlign,
        );
        this.on(
          'change:attributes:data-button-icon-size',
          this.updateButtonIconSize,
        );
        this.on(
          'change:attributes:data-button-icon-indent',
          this.updateButtonIconIndent,
        );
      },
      updateButtonIconEnable(model, buttonIconEnable) {
        if (!buttonIconEnable) {
          // reset all button icon settings
          model.addAttributes({
            'data-button-icon-align': 'left',
            'data-button-icon-size': '32',
            'data-button-icon-indent': '',
          });
          this.set('buttonIcon', '');

          // remove icon image
          const buttonIconImage = model.findType('image');

          if (buttonIconImage.length) {
            buttonIconImage[0].remove();
          }
        }
      },
      updateButtonIconAlign(model, buttonIconAlign) {
        const buttonIcon = model.findType('image');

        const {
          'data-button-icon-size': buttonIconSize,
          'data-button-icon-indent': buttonIconIndent,
        } = model.get('attributes');

        const buttonIndentStyle =
          buttonIconAlign === 'right'
            ? `margin-left: ${buttonIconIndent}px`
            : `margin-right: ${buttonIconIndent}px`;
        const buttonIconImage =
          buttonIcon?.length > 0
            ? `
          <img
            data-gjs-editable="false"
            data-gjs-collection="false"
            data-gjs-droppable="false"
            data-gjs-draggable="false"
            data-gjs-badgable="false"
            data-gjs-copyable="false"
            data-gjs-removable="false"
            data-gjs-selectable="false"
            data-gjs-hoverable="false"
            data-gjs-resizable="false"
            src="${buttonIcon[0].get('src')}"
            alt="icon"
            width="${buttonIconSize}"
            style="vertical-align: middle; ${buttonIndentStyle}"
        />
        `
            : '';

        if (buttonIconAlign === 'right' && buttonIcon?.length > 0) {
          model.components(`${model.getEl().innerText} ${buttonIconImage}`);
        }

        if (buttonIconAlign === 'left' && buttonIcon?.length > 0) {
          model.components(`${buttonIconImage} ${model.getEl().innerText}`);
        }
      },
      updateButtonIconSize(model, buttonIconSize) {
        const buttonIconImage = model.findType('image');

        if (buttonIconImage.length) {
          buttonIconImage[0].addAttributes({ width: buttonIconSize });
        }
      },
      updateButtonIconIndent(model, buttonIconSizeIndent) {
        const { 'data-button-icon-align': buttonIconAlign } =
          model.get('attributes');

        const buttonIconImage = model.findType('image');

        if (buttonIconImage.length) {
          buttonIconImage[0].setStyle({
            'vertical-align': 'middle',
            [`${
              buttonIconAlign === 'right' ? 'margin-left' : 'margin-right'
            }`]: `${buttonIconSizeIndent}px`,
          });
        }
      },
    },
    isComponent(el) {
      if (el.tagName === 'A' && checkComponentType(el, buttonType)) {
        return { type: buttonType };
      }
    },
  });
};

const addIconWrapper = (editor, { iconWrapperType }) => {
  const domc = editor.DomComponents;

  domc.addType(iconWrapperType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Icon',
        type: iconWrapperType,
        droppable: false,
        resizable: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:src', this.updateIcon);
      },
      events: {
        dblclick: 'onActive',
      },
      onActive() {
        editor.runCommand('open-assets', { isIcon: true });
      },
      updateIcon(model, src) {
        if (src) {
          editor.getSelected().addAttributes({ src });
          editor.getSelected().view.render();
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, iconWrapperType)) {
        return { type: iconWrapperType };
      }
    },
  });
};

const addIconLink = (editor, { iconLinkType }) => {
  const domc = editor.DomComponents;
  domc.addType(iconLinkType, {
    extend: 'link',
    model: {
      defaults: {
        droppable: false,
        draggable: false,
        removable: false,
        stylable: false,
        copyable: false,
        editable: false,
        selectable: false,
        layerable: false,
        hoverable: false,
        propagate: [
          'draggable',
          'removable',
          'stylable',
          'copyable',
          'editable',
          'selectable',
          'layerable',
          'hoverable',
        ],
        type: iconLinkType,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, iconLinkType)) {
        return { type: iconLinkType };
      }
    },
  });
};

const addIconImage = (editor, { iconImageType }) => {
  const domc = editor.DomComponents;

  domc.addType(iconImageType, {
    extend: 'image',
    model: {
      defaults: {
        type: iconImageType,
      },
    },
    view: {
      events: {
        dblclick: null,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, iconImageType)) {
        return { type: iconImageType };
      }
    },
  });
};

const addLink = (editor, { linkType }) => {
  const domc = editor.DomComponents;

  domc.addType(linkType, {
    model: {
      defaults: {
        openTraitsOnSelect: true,
      },
    },
  });
};

const addVideo = (editor, { videoType }) => {
  const domc = editor.DomComponents;

  domc.addType(videoType, {
    model: {
      defaults: {
        name: 'Video',
        droppable: false,
        openTraitsOnSelect: true,
        traits: [
          {
            type: 'url',
            name: 'videolink',
            label: 'Link to video',
            placeholder: 'Youtube or Vimeo',
            changeProp: 1,
          },
          {
            type: 'text',
            name: 'videoalt',
            label: 'Alternate text',
            changeProp: 1,
          },
          {
            type: 'video-play-buttons',
            name: 'playbutton',
            label: 'Play button',
            default: 'none',
          },
          {
            id: 'videoCustomThumb',
            type: 'button',
            text: 'Custom thumbnail',
            full: true,
            changeProp: true,
            command: (editor) => {
              const videoImage = editor?.getSelected().find('img');
              const { playbutton } = editor.getSelected().get('attributes');

              if (videoImage?.length) {
                editor.select(videoImage[0]);
                editor.runCommand('open-assets', { isImage: true, playbutton });
              }
            },
          },
        ],
        tagName: 'a',
        videolink: '',
        videoalt: '',
        attributes: {
          class: 'embed-video',
          'data-type': 'embed-video',
          playbutton: 'none',
        },
        components: `
          <img
            src="https://cdn11.waymore.io/dnd/images/video_images/default-img.png"
            alt="Default image"
            data-gjs-editable="false"
            data-gjs-collection="false"
            data-gjs-droppable="false"
            data-gjs-draggable="false"
            data-gjs-badgable="false"
            data-gjs-copyable="false"
            data-gjs-removable="false"
            data-gjs-selectable="false"
            data-gjs-hoverable="false"
            data-gjs-resizable="false"
          />
        `,
        styles: `
          .embed-video {
            width: 100%;
            display: inline-block;
          }
          .embed-video img:not(.play-button) {
            width: 100%;
            max-width: 100%;
            height: auto;
          }
          .embed-video img[src*="default-img"] {
            background: #f9f9f9 url('https://cdn11.waymore.io/dnd/images/video_images/default-video-back.png') no-repeat 50%;
            box-shadow: 0 0 0 1px #eee inset;
            height: 100px;
          }
        `,
      },
      init() {
        this.on('change:attributes:playbutton', this.updateVideoPlayButton);
        this.on('change:videoalt', this.updateVideoAlt);
        this.on('change:videolink', this.updateVideoLink);
      },
      getVideoProvider(videoLink) {
        let videoProvider = '';
        let videoId = '';
        const YOUTUBE_VID_REGEX =
          /(?:youtube(?:-nocookie)?\.com\/(?:[^\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const VIMEO_VID_REGEX =
          /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\\-]+)?/;

        // Youtube pattern
        if (videoLink.match(YOUTUBE_VID_REGEX)) {
          videoProvider = 'youtube';
          videoId = videoLink.match(YOUTUBE_VID_REGEX)[1];
        }

        // Vimeo pattern
        if (videoLink.match(VIMEO_VID_REGEX)) {
          videoProvider = 'vimeo';
          videoId = videoLink.match(VIMEO_VID_REGEX)[1];
        }

        return { videoProvider, videoId };
      },
      async generateMergedImage(videoPlayButton, videoProvider, thumbnails) {
        const originalURL = window.location.href;
        const match = originalURL.split('?')[0].match(/\/edit\/([^/]+)$/);
        const frontImageUrl = `https://cdn11.waymore.io/dnd/images/video_images/${videoPlayButton}.png`;
        let backImageUrl = '';

        if (videoProvider === 'youtube') {
          backImageUrl = thumbnails?.standard?.url;
        }

        if (videoProvider === 'vimeo') {
          backImageUrl = thumbnails?.sizes[3]?.link;
        }

        if (match) {
          const templateId = match[1];

          try {
            if (videoPlayButton !== 'none') {
              const {
                data: { savedImage },
              } = await httpGetVideoMerge({
                backImageUrl,
                frontImageUrl,
                templateId,
              });

              return buildAssetsUrl(templateId, savedImage);
            }

            return backImageUrl;
          } catch (error) {
            showErrorMessage(getErrorText());

            throw error;
          }
        } else {
          showErrorMessage(getErrorText());
        }

        return '';
      },
      async updateVideoPlayButton(model, videoPlayButton) {
        const videoLink = model.get('videolink');

        if (videoLink && videoPlayButton && videoPlayButton !== 'none') {
          const { thumbnails } = await this.getVideoDetails(videoLink);
          const { videoProvider } = this.getVideoProvider(videoLink);
          const videoImage = model.findType('image');
          const finalImageUrl = await this.generateMergedImage(
            videoPlayButton,
            videoProvider,
            thumbnails,
          );

          if (videoImage.length > 0 && finalImageUrl) {
            videoImage[0].set('src', finalImageUrl);
          }
        } else {
          showErrorMessage('Please enter a valid Youtube or Vimeo URL');
        }
      },
      updateVideoAlt(model, videoAltText) {
        if (model.findType('image').length > 0) {
          model.findType('image')[0].addAttributes({ alt: videoAltText });
        }
      },
      async getVideoDetails(videoLink) {
        const { videoProvider, videoId } = this.getVideoProvider(videoLink);

        if (videoProvider && videoId) {
          try {
            const {
              data: { title, thumbnails },
            } = await httpGetVideoInfo({
              provider: videoProvider,
              videoId,
            });

            return { title, thumbnails, videoProvider };
          } catch (error) {
            showErrorMessage(getErrorText());

            throw error;
          }
        }

        return {};
      },
      async updateVideoLink(model, videoLink) {
        try {
          let savedImageUrl = '';
          const { title, thumbnails, videoProvider } =
            await this.getVideoDetails(videoLink);
          const selected = editor.getSelected();
          const traits = selected.get('traits');
          const videoAltTrait = traits.where({ name: 'videoalt' })[0];
          const videoPlayButtonTrait = traits.where({
            name: 'playbutton',
          })[0];
          const videoPlayButtonTraitValue = videoPlayButtonTrait.get('value');

          if (title) {
            // Updating Alternate text input value with default video title
            videoAltTrait.set('value', title);
            videoAltTrait.view.elInput.value = title;
          }

          // Merge image when play button is available
          if (videoPlayButtonTraitValue !== 'none') {
            savedImageUrl = await this.generateMergedImage(
              videoPlayButtonTraitValue,
              videoProvider,
              thumbnails,
            );
          }

          // Youtube
          if (videoProvider === 'youtube') {
            const videoImagelink = savedImageUrl || thumbnails?.standard?.url;

            model.addAttributes({ href: videoLink });
            model.components(
              `<img
                      src="${videoImagelink}"
                      alt="${title}"
                      data-gjs-editable="false"
                      data-gjs-collection="false"
                      data-gjs-droppable="false"
                      data-gjs-draggable="false"
                      data-gjs-badgable="false"
                      data-gjs-copyable="false"
                      data-gjs-removable="false"
                      data-gjs-selectable="false"
                      data-gjs-hoverable="false"
                      data-gjs-resizable="false"
                  />
                  `,
            );
          }

          // Vimeo
          if (videoProvider === 'vimeo') {
            const videoImagelink = savedImageUrl || thumbnails?.sizes[3]?.link;

            model.addAttributes({ href: videoLink });
            model.components(
              `<img
                      src="${videoImagelink}"
                      alt="${title}"
                      data-gjs-editable="false"
                      data-gjs-collection="false"
                      data-gjs-droppable="false"
                      data-gjs-draggable="false"
                      data-gjs-badgable="false"
                      data-gjs-copyable="false"
                      data-gjs-removable="false"
                      data-gjs-selectable="false"
                      data-gjs-hoverable="false"
                      data-gjs-resizable="false"
                    >
                  `,
            );
          }
        } catch (error) {
          showErrorMessage(getErrorText());

          throw error;
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, videoType)) {
        return { type: videoType };
      }
    },
  });
};

const addMenuWrapper = (editor, { menuWrapperType }) => {
  const domc = editor.DomComponents;

  domc.addType(menuWrapperType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Menu',
        type: menuWrapperType,
        traits: [
          {
            id: 'add-menu-item-btn',
            type: 'button',
            text: 'Add item',
            full: true,
            command: (editor) => {
              const menuItemLinks = editor?.getSelected().findType('link');
              const menuItemLinksLen = menuItemLinks?.length || 0;
              const menuRow = editor?.getSelected().findType('row');
              const menuCells = editor?.getSelected().findType('cell');

              if (menuRow) {
                const newWidthSize = 100 / (menuItemLinksLen + 1);

                if (menuCells) {
                  menuCells.forEach((cell) =>
                    cell.addAttributes({ width: `${Math.abs(newWidthSize)}%` }),
                  );
                }

                const newAddedItem = menuRow[0].components().add(
                  {
                    type: 'cell',
                    attributes: {
                      align: 'center',
                      valign: 'top',
                      width: `${newWidthSize}%`,
                    },
                    components: {
                      type: 'link',
                      attributes: {
                        href: '#',
                        target: '_blank',
                        title: 'Item 1',
                      },
                      content: 'New item',
                    },
                  },
                  { at: menuItemLinksLen },
                );

                newAddedItem.findType('link')[0].setStyle({
                  display: 'block',
                  padding: '10px 5px',
                  'font-size': '20px',
                  color: '#000000',
                  'text-decoration': 'none',
                  'font-family': 'Arial, Helvetica, sans-serif',
                });
                newAddedItem.set({
                  editable: false,
                  collection: false,
                  droppable: false,
                  draggable: false,
                  badgable: false,
                  copyable: false,
                  removable: false,
                  selectable: false,
                  hoverable: false,
                  resizable: false,
                });
              }
            },
          },
          {
            name: 'menuItemsColor',
            label: 'Color',
            type: 'color',
            default: '#000000',
            changeProp: true,
          },
          {
            name: 'menuItemsFontSize',
            label: 'Font size',
            type: 'number',
            default: 20,
            changeProp: true,
          },
          {
            name: 'menuItemsPaddingTopBottom',
            label: 'Padding (Top | Bottom)',
            type: 'number',
            default: 10,
            changeProp: true,
          },
          {
            name: 'menuItemsPaddingRightLeft',
            label: 'Padding (Right | Left)',
            type: 'number',
            default: 5,
            changeProp: true,
          },
          {
            type: 'select',
            label: 'Separator',
            name: 'menuItemsSeparator',
            default: 'none',
            changeProp: true,
            options: [
              { value: 'none', name: 'None' },
              { value: 'solid', name: 'Solid' },
              { value: 'dotted', name: 'Dotted' },
              { value: 'dashed', name: 'Dashed' },
            ],
          },
          {
            name: 'menuItemsSeparatorColor',
            label: 'Separator color',
            type: 'color',
            default: '#000000',
            changeProp: true,
          },
          {
            name: 'menuItemsSeparatorWidth',
            label: 'Separator width',
            type: 'number',
            default: 1,
            changeProp: true,
          },
        ],
      },
      init() {
        this.on('change:menuItemsColor', this.updateMenuItemsColor);
        this.on('change:menuItemsFontSize', this.updateMenuItemsFontSize);
        this.on(
          'change:menuItemsPaddingTopBottom',
          this.updateMenuItemsPaddingTopBottom,
        );
        this.on(
          'change:menuItemsPaddingRightLeft',
          this.updateMenuItemsPaddingRightLeft,
        );
        this.on('change:menuItemsSeparator', this.updateMenuItemsSeparator);
        this.on(
          'change:menuItemsSeparatorColor',
          this.updateMenuItemsSeparatorColor,
        );
        this.on(
          'change:menuItemsSeparatorWidth',
          this.updateMenuItemsSeparatorWidth,
        );
      },
      updateMenuItemsColor(cmp, value) {
        const menuLinks = cmp.findType('link');
        const style = {
          color: value,
        };

        if (menuLinks) {
          menuLinks.forEach((link) =>
            setRuleStyle(editor, `#${link.getId()}`, style),
          );
        }
      },
      updateMenuItemsFontSize(cmp, value) {
        const menuLinks = cmp.findType('link');
        const style = {
          'font-size': `${value}px`,
        };

        if (menuLinks) {
          menuLinks.forEach((link) =>
            setRuleStyle(editor, `#${link.getId()}`, style),
          );
        }
      },
      updateMenuItemsPaddingTopBottom(cmp, value) {
        const menuLinks = cmp.findType('link');
        const style = {
          'padding-top': `${value}px`,
          'padding-bottom': `${value}px`,
        };

        if (menuLinks) {
          menuLinks.forEach((link) =>
            setRuleStyle(editor, `#${link.getId()}`, style),
          );
        }
      },
      updateMenuItemsPaddingRightLeft(cmp, value) {
        const menuLinks = cmp.findType('link');
        const style = {
          'padding-right': `${value}px`,
          'padding-left': `${value}px`,
        };

        if (menuLinks) {
          menuLinks.forEach((link) =>
            setRuleStyle(editor, `#${link.getId()}`, style),
          );
        }
      },
      updateMenuItemsSeparator(cmp, value) {
        const menuCells = cmp.findType('cell');

        if (menuCells) {
          menuCells.forEach((cell, index) => {
            if (value === 'none') {
              const colorTrait = cmp.getTrait('menuItemsSeparatorColor');
              const widthTrait = cmp.getTrait('menuItemsSeparatorWidth');

              colorTrait.set('value', '');
              widthTrait.set('value', '');

              cell.removeStyle('border-left');
            } else {
              if (index !== 0) {
                const borderStyles = cell
                  ?.getStyle()
                  ['border-left']?.split(' ');

                if (borderStyles) {
                  const style = {
                    'border-left': `${borderStyles[0]} ${value} ${borderStyles[2]}`,
                  };

                  setRuleStyle(editor, `#${cell.getId()}`, style);
                } else {
                  const style = {
                    'border-left': `1px ${value} #000`,
                  };

                  setRuleStyle(editor, `#${cell.getId()}`, style);
                }

                editor.CssComposer.setRule(
                  `#${cell.getId()}`,
                  { 'border-left': 'none' },
                  {
                    atRuleType: 'media',
                    atRuleParams: '(max-width: 992px)',
                  },
                );
              }
            }
          });
        }
      },
      updateMenuItemsSeparatorColor(cmp, value) {
        const menuCells = cmp.findType('cell');

        if (menuCells) {
          menuCells.forEach((cell, index) => {
            if (index !== 0) {
              const borderStyles = cell?.getStyle()['border-left']?.split(' ');

              if (borderStyles) {
                const style = {
                  'border-left': `${borderStyles[0]} ${borderStyles[1]} ${value}`,
                };

                setRuleStyle(editor, `#${cell.getId()}`, style);
              } else {
                const style = {
                  'border-left': `1px solid ${value}`,
                };

                setRuleStyle(editor, `#${cell.getId()}`, style);
              }

              editor.CssComposer.setRule(
                `#${cell.getId()}`,
                { 'border-left': 'none' },
                {
                  atRuleType: 'media',
                  atRuleParams: '(max-width: 992px)',
                },
              );
            }
          });
        }
      },
      updateMenuItemsSeparatorWidth(cmp, value) {
        const menuCells = cmp.findType('cell');

        if (menuCells) {
          menuCells.forEach((cell, index) => {
            if (index !== 0) {
              const borderStyles = cell?.getStyle()['border-left']?.split(' ');

              if (borderStyles) {
                const style = {
                  'border-left': `${value}px ${borderStyles[1]} ${borderStyles[2]} `,
                };

                setRuleStyle(editor, `#${cell.getId()}`, style);
              } else {
                const style = {
                  'border-left': `${value}px solid #000`,
                };

                setRuleStyle(editor, `#${cell.getId()}`, style);
              }

              editor.CssComposer.setRule(
                `#${cell.getId()}`,
                { 'border-left': 'none' },
                {
                  atRuleType: 'media',
                  atRuleParams: '(max-width: 992px)',
                },
              );
            }
          });
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, menuWrapperType)) {
        return { type: menuWrapperType };
      }
    },
  });
};

const addSocial = (editor, { socialIconWrapperType, socialIconType }) => {
  const domc = editor.DomComponents;

  domc.addType(socialIconWrapperType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Social',
        type: socialIconWrapperType,
        openTraitsOnSelect: true,
        traits: [
          {
            type: 'add-social-icons',
            name: 'socialIcon',
            label: 'Add icon',
            default: 'none',
            changeProp: true,
          },
          {
            type: 'number',
            label: 'Size (px)',
            name: 'data-social-icon-size',
            min: 12,
            max: 64,
            default: 32,
          },
          {
            type: 'style-social-icons',
            name: 'data-social-icon-style',
            label: 'Icons style',
            default: 'preview-32-circle-colored',
          },
        ],
      },
      init() {
        this.on(
          'change:attributes:data-social-icon-size',
          this.updateSocialSize,
        );
        this.on(
          'change:attributes:data-social-icon-style',
          this.updateSocialStyle,
        );
      },
      updateSocialSize(cmp, value) {
        const socialIcons = cmp.findType('social-icon');

        if (socialIcons) {
          socialIcons.forEach((icon) => {
            const iconImage = icon.findType('image')[0];

            icon
              .findType('image')[0]
              .addAttributes({ width: value, height: value });

            setRuleStyle(editor, `#${iconImage.getId()}`, {
              width: `${value}px`,
              height: `${value}px`,
            });
          });
        }
      },
      updateSocialStyle(cmp, value) {
        const socialIcons = cmp.findType('social-icon');
        const iconStyle = value.replace('preview-32-', '');
        const oldIconStyle = cmp['_previousAttributes'].attributes[
          'data-social-icon-style'
        ].replace('preview-32-', '');

        if (socialIcons) {
          socialIcons.forEach((icon) => {
            const iconImage = icon.findType('image')[0];
            const iconImageSrc = iconImage.get('src');
            const { width, height } = iconImage.get('attributes');

            iconImage.set(
              'src',
              iconImageSrc.replaceAll(oldIconStyle, iconStyle),
            );
            setRuleStyle(editor, `#${iconImage.getId()}`, {
              width: `${width}px`,
              height: `${height}px`,
            });
          });
        }
      },
    },

    isComponent(el) {
      if (checkComponentType(el, socialIconWrapperType)) {
        return { type: socialIconWrapperType };
      }
    },
  });

  domc.addType(socialIconType, {
    extend: 'link',
    model: {
      defaults: {
        name: 'Social icon',
        type: socialIconType,
        tagName: 'a',
        traits: [
          {
            type: 'url',
            name: 'href',
            label: 'Link',
          },
        ],
        draggable: '[data-gjs-type="social-icon-wrapper"]',
        openTraitsOnSelect: true,
      },
    },
    isComponent(el) {
      const elementType = el.getAttribute && el.getAttribute('data-type');

      if (el.tagName === 'A' && elementType === socialIconType) {
        return { type: socialIconType };
      }
    },
  });
};

const addTimer = (editor, { timerType }) => {
  const domc = editor.DomComponents;

  domc.addType(timerType, {
    extend: 'default',
    model: {
      defaults: {
        openTraitsOnSelect: true,
        droppable: false,
        attributes: {
          class: 'timer',
          'data-type': 'timer',
        },
        traits: [
          {
            label: 'End date and time',
            name: 'enddatetime',
            type: 'custom-datetime',
            changeProp: true,
          },
          {
            label: 'Time zone',
            name: 'timezone',
            type: 'select',
            changeProp: true,
            options: TIME_ZONE,
          },
          {
            id: 'generate-timer-btn',
            type: 'button',
            text: 'Generate timer',
            full: true,
            command: async (editor) => {
              const endDateTimeTrait = editor
                .getSelected()
                .getTrait('enddatetime');
              const timeZoneTrait = editor.getSelected().getTrait('timezone');
              const endDateTimeValue = endDateTimeTrait.get('value');
              const timeZoneValue = timeZoneTrait.get('value');
              const timerImage = editor.getSelected().findType('image');

              // Validation
              if (!endDateTimeValue) {
                showErrorMessage('Please select "End data and time"');

                return false;
              }
              if (!timeZoneValue) {
                showErrorMessage('Please select "Time zone"');

                return false;
              }

              if (timerImage) {
                const finalTimerTime = `${endDateTimeValue}${timeZoneValue}`;
                const imageUrl = buildCountdownImageUrl(finalTimerTime);
                const img = new Image();

                img.src = imageUrl;
                img.onload = function () {
                  timerImage[0].set('src', imageUrl);
                  timerImage[0].setStyle({
                    width: 'auto',
                    height: 'auto',
                  });
                };

                if (!img.complete) {
                  timerImage[0].setStyle({
                    'background-image':
                      "url('https://cdn11.waymore.io/dnd/images/timer_images/timer_loader.gif')",
                  });
                }
              }
            },
          },
        ],
        components: `
        <img
          src="https://cdn11.waymore.io/dnd/images/video_images/default-img.png"
          alt="Default image"
          data-gjs-editable="false"
          data-gjs-collection="false"
          data-gjs-droppable="false"
          data-gjs-draggable="false"
          data-gjs-badgable="false"
          data-gjs-copyable="false"
          data-gjs-removable="false"
          data-gjs-selectable="false"
          data-gjs-hoverable="false"
          data-gjs-resizable="false"
        />
      `,
        styles: `
        .timer {
          width: 100%;
          display: inline-block;
        }
        .timer img {
          width: 100%;
          max-width: 100%;
          height: auto;
        }
        .timer img[src*="default-img"] {
          background: #f9f9f9 url('https://cdn11.waymore.io/dnd/images/timer_images/default-timer-back.png') no-repeat 50%;
          box-shadow: 0 0 0 1px #eee inset;
          height: 100px;
        }
      `,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, timerType)) {
        return { type: timerType };
      }
    },
  });
};

export default (editor, config) => {
  addImageBlock(editor, config);
  addLinkedImage(editor, config);
  addButton(editor, config);
  addIconWrapper(editor, config);
  addIconLink(editor, config);
  addIconImage(editor, config);
  addLink(editor, config);
  addImageText(editor, config);
  addVideo(editor, config);
  addMenuWrapper(editor, config);
  addSocial(editor, config);
  addTimer(editor, config);
};
