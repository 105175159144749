import { combineReducers, createStore, applyMiddleware } from 'redux';
import reduxThunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { iconsReducer } from './reducers/icons';
import { userReducer } from './reducers/user';
import { customBlocksReducer } from './reducers/blocks';
import { assetsReducer } from './reducers/assets';
import { waymoreReducer } from './reducers/waymore';
import { templateReducer } from './reducers/template';
import { categoriesReducer } from './reducers/categories';

const rootReducer = combineReducers({
  icons: iconsReducer,
  blocks: customBlocksReducer,
  user: userReducer,
  categories: categoriesReducer,
  assets: assetsReducer,
  waymore: waymoreReducer,
  template: templateReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(reduxThunkMiddleware)),
);

export { rootReducer };
export default store;
