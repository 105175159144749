import { useToggleTypes } from 'hooks';
import { showErrorMessage, getErrorText, buildAssetsUrl } from 'helpers';
import { httpGetVideoMerge } from 'api/general.api';

export default (editor) => {
  const cmd = editor.Commands;

  // replace built-in command to open custom image modal
  cmd.add('open-assets', {
    run(
      editor,
      sender,
      {
        isIcon,
        isImage,
        isLinkedImage,
        isLink,
        isButtonIcon,
        playbutton,
        select,
      },
    ) {
      const model = editor.getModel();

      model.set('playbutton', playbutton);
      model.set('assetTarget', isIcon || isImage ? 'image' : 'background');
      model.set('linkAssetTarget', isLink);
      model.set('buttonIconAssetTarget', isButtonIcon);
      model.set('linkedImageAssetTarget', isLinkedImage);

      if (select) {
        model.set('onSetBackgroundImage', select);
      }

      if (isIcon) {
        model.get('onShow')(useToggleTypes.showIconModal);
        return;
      }

      model.get('onShow')(useToggleTypes.showImageModal);
    },
  });

  // command to set image which is running by custom image modal
  cmd.add('set-image-url', {
    async run(editor, sender, { url, imageName }) {
      const target = editor.getSelected();
      const editorModel = editor.getModel();
      const assetTarget = editorModel.get('assetTarget');
      const playbutton = editorModel.get('playbutton');
      const linkAssetTarget = editorModel.get('linkAssetTarget');
      const buttonIconAssetTarget = editorModel.get('buttonIconAssetTarget');
      const onSetBackgroundImage = editorModel.get('onSetBackgroundImage');
      const linkedImageAssetTarget = editorModel.get('linkedImageAssetTarget');

      if (buttonIconAssetTarget) {
        const {
          'data-button-icon-align': buttonIconAlign,
          'data-button-icon-size': buttonIconSize,
          'data-button-icon-indent': buttonIconIndent,
        } = target.get('attributes');
        const buttonIconEnableTrait = target.getTrait(
          'data-button-icon-enable',
        );
        const buttonIndentStyle =
          buttonIconAlign === 'right'
            ? `margin-left: ${buttonIconIndent || 0}px`
            : `margin-right: ${buttonIconIndent || 0}px`;
        const buttonIconImage = `
          <img
              data-gjs-editable="false"
              data-gjs-collection="false"
              data-gjs-droppable="false"
              data-gjs-draggable="false"
              data-gjs-badgable="false"
              data-gjs-copyable="false"
              data-gjs-removable="false"
              data-gjs-selectable="false"
              data-gjs-hoverable="false"
              data-gjs-resizable="false"
              src="${url}"
              alt="icon"
              width="${buttonIconSize || 32}"
              style="vertical-align: middle; ${buttonIndentStyle}"
            />
          `;
        const buttonIconImageFinal =
          buttonIconAlign === 'left'
            ? `${buttonIconImage} ${target.getEl().innerText}`
            : `${target.getEl().innerText} ${buttonIconImage}`;

        buttonIconEnableTrait.setValue(true);
        target.set('buttonIcon', url);
        target.components(buttonIconImageFinal);
      }

      if (linkAssetTarget) {
        target.addAttributes({ href: url, download: `${imageName || ''}` });
      }

      if (
        onSetBackgroundImage instanceof Function &&
        assetTarget === 'background'
      ) {
        const bgImgStyle = target.getStyle('background-image');

        if (bgImgStyle === 'none') {
          target.addStyle({ 'background-image': `url("${url}")` });
        } else {
          target.addStyle({
            'background-image': `${bgImgStyle.replace(
              'none,',
              '',
            )}, url("${url}")`,
          });
        }
      }

      if (assetTarget === 'image') {
        if (target.get('type') === 'embed-video') {
          const videoImage = target.find('img');

          if (!playbutton || playbutton === 'none') {
            if (videoImage?.length) {
              videoImage[0].set('src', url);
            }
          } else {
            const match = window.location.href.match(/\/edit\/([^/]+)$/);

            if (match) {
              const templateId = match[1];
              const frontImageUrl = `https://cdn11.waymore.io/dnd/images/video_images/${playbutton}.png`;
              let backImageUrl = url;

              try {
                const {
                  data: { savedImage },
                } = await httpGetVideoMerge({
                  backImageUrl,
                  frontImageUrl,
                  templateId,
                });

                videoImage[0].set(
                  'src',
                  buildAssetsUrl(templateId, savedImage),
                );
              } catch (error) {
                showErrorMessage(getErrorText());

                throw error;
              }
            }
          }
        } else {
          target.set('src', url);
        }
      }

      if (linkedImageAssetTarget) {
        if (target.findType('linked-image-image')) {
          const cmpStyle = target.getStyle();
          const imgCmp = target.findType('linked-image-image')[0];

          imgCmp.set('src', url);
          imgCmp.setStyle({
            width: `${cmpStyle?.width || '100%'}`,
            height: `${cmpStyle?.height || 'auto'}`,
          });
        }
      }
    },
  });
};
