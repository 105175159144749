import { createSlice } from '@reduxjs/toolkit';
import { getCredentials, getPrincipal } from 'store/actions';

const initialState = {
  theme: 'light',
  token: null,
  principalId: null,
  firstName: null,
  lastName: null,
  email: null,
  role: null,
  principalList: [],
};

const { reducer: userReducer } = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCredentials.fulfilled, (state, action) => {
        const {
          token,
          principalId,
          firstName,
          lastName,
          email,
          role,
          subscriptionPlan,
        } = action.payload;

        state.token = token;
        state.principalId = principalId;
        state.firstName = firstName;
        state.lastName = lastName;
        state.email = email;
        state.role = role;
        state.subscriptionPlan = subscriptionPlan;
      })
      .addCase(getPrincipal.fulfilled, (state, action) => {
        const { principals } = action.payload.data;

        state.principalList = principals;
      });
  },
});

export { userReducer };
