import { getSocialImageUrl } from './utils';

export default (
  editor,
  { baseUrl, shape, color, size, socialFollowBlockType, socialFollowType },
  advanced,
) => {
  const bm = editor.BlockManager;

  bm.add(socialFollowBlockType, {
    attributes: { class: 'bpreview-social-follow-block' },
    label: 'Social Follow',
    category: advanced === 'true' ? 'Social' : 'Basic',
    content: `
    <div
      style="text-align: center;"
      data-type=${socialFollowBlockType}
      size="${size}"
      shape="${shape}"
      color="${color}"
    >
      <a
        href=""
        data-type="${socialFollowType}-facebook"
        network="facebook"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          network="facebook"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'facebook',
          })}"
          alt="facebook"
        />
      </a>
      <a
        href=""
        data-type="${socialFollowType}-instagram"
        network="instagram"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'instagram',
          })}"
          alt="instagram"
        />
      </a>
      <a
        href=""
        data-type="${socialFollowType}-linkedin"
        network="linkedin"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          network="linkedin"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'linkedin',
          })}"
          alt="linkedin"
        />
      </a>
      <a
        href=""
        data-type="${socialFollowType}-x"
        network="x"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'x',
          })}"
          alt="x"
        />
      </a>
      <a
        href=""
        data-type="${socialFollowType}-vk"
        network="vk"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'vk',
          })}"
          alt="vk"
        />
      </a>
      <a
        href=""
        data-type="${socialFollowType}-youtube"
        network="youtube"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'youtube',
          })}"
          alt="youtube"
        />
      </a>
      <a
        href=""
        data-type="${socialFollowType}-pin"
        network="pin"
        style="
          display: inline-block;
          margin: 0 4px;
        "
      >
        <img
          data-gjs-editable="false"
          data-gjs-selectable="false"
          data-gjs-removable="false"
          width="32"
          height="32"
          src="${getSocialImageUrl({
            baseUrl,
            shape,
            color,
            network: 'pin',
          })}"
          alt="pin"
        />
      </a>
    </div>
    `,
  });
};
