import { createSlice } from '@reduxjs/toolkit';

import {
  createCategory,
  updateCategory,
  deleteCategory,
  getCategories,
} from 'store/actions';

/* example of categories state:
  {
    51asd235a: { 
      id: "51asd235a",
      name: "CAT",
      group: "system",
      type: 'email',
      hidden: true,
    },
    51asd235b: { 
      id: "51asd235b",
      name: "CAT2",
      group: "system",
      type: 'email',
      hidden: false,
    },
  }
*/
const initialState = {};

const { reducer: categoriesReducer } = createSlice({
  name: 'categories',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.fulfilled, (state, action) => {
        return action.payload.reduce((acc, category) => {
          acc[category.id] = category;

          return acc;
        }, state);
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state[action.payload.id] = action.payload;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state[action.payload.id] = {
          ...state[action.payload.id],
          ...action.payload,
        };
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        delete state[action.payload.id];
      });
  },
});

export { categoriesReducer };
