import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'hooks';
import { Link } from 'react-router-dom';
import { PREVIEW_SIZES } from 'appConstants';
import Logo from 'components/Logo';
import Breadcrumb from 'components/Breadcrumb';
import Button from 'components/Button';
import './PreviewHeader.sass';

function PreviewHeader({
  onPreviewSizeChange,
  onTogglePreview,
  currentDevicePreview,
  templateStatus,
}) {
  const [active, setActive] = useState(currentDevicePreview);
  const { system } = useQuery();

  return (
    <div className="preview-header">
      <Link to={{ pathname: '/' }}>
        <Logo />
      </Link>
      <Breadcrumb
        status={templateStatus}
        system={Boolean(system)}
        isPreview
        onTogglePreview={onTogglePreview}
      />
      <div className="preview-header__devices">
        {PREVIEW_SIZES.map((size) => (
          <Button
            className={cx(
              'preview-header__size-button',
              active === size ? 'active' : '',
            )}
            data-testid={size}
            key={size}
            onClick={() => {
              setActive(size);
              onPreviewSizeChange(size);
            }}>
            <span
              className={cx(
                'icon',
                `icon-${
                  size === 'pc'
                    ? 'desktop-windows'
                    : size === 'tablet'
                    ? 'tablet-mac'
                    : 'phone-iphone'
                }`,
              )}
            />
          </Button>
        ))}
      </div>
      <div className="preview-header__right-col">
        <Button
          className="preview-header__exit-preview-btn"
          variant="contained"
          color="default"
          onClick={onTogglePreview}>
          <FormattedMessage id="button.exit-preview" />
          <span className="icon icon-close" />
        </Button>
      </div>
    </div>
  );
}

PreviewHeader.propTypes = {
  currentDevicePreview: PropTypes.oneOf(PREVIEW_SIZES).isRequired,
  onPreviewSizeChange: PropTypes.func.isRequired,
  onTogglePreview: PropTypes.func.isRequired,
  templateStatus: PropTypes.string.isRequired,
};

export default PreviewHeader;
