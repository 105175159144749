import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { REDIRECT_URL } from 'appConstants';
import BaseModal from 'components/Modals/BaseModal';
import ConfirmModal from 'components/Modals/ConfirmModal';
import { useModal, useQuery } from 'hooks';
import { navigateToWayMoreUrl } from 'helpers';
import cx from 'classnames';
import './EditorMode.sass';

function EditorMode({ version, type }) {
  const {
    isOpen: isConfirmModeModalOpen,
    open: openConfirmModeModal,
    close: closeConfirmModeModal,
  } = useModal();

  const handleMode = () =>
    type === 'header'
      ? navigateToWayMoreUrl(`${REDIRECT_URL}/settings/editor`)
      : openConfirmModeModal();

  const { advanced } = useQuery();
  const currentMode = advanced && advanced === 'true' ? 'advanced' : 'simple';

  return (
    <div className="editor-mode">
      <div className="editor-mode__mode">
        <button
          disabled={currentMode === 'simple'}
          onClick={handleMode}
          className={cx('simple-mode', {
            current: currentMode === 'simple',
          })}>
          <FormattedMessage id="editor-mode.simple" />
        </button>
        <button
          disabled={currentMode === 'advanced'}
          onClick={handleMode}
          className={cx('advanced-mode', {
            current: currentMode === 'advanced',
          })}>
          <FormattedMessage id="editor-mode.advanced" />
        </button>
      </div>
      <div className="editor-mode__ver">v{version}</div>
      <BaseModal
        isOpen={isConfirmModeModalOpen}
        headerTitle="editor-mode.modal-title"
        maxWidth="600px"
        onClose={closeConfirmModeModal}>
        <ConfirmModal
          loading={false}
          buttonsColor="secondary"
          onClose={closeConfirmModeModal}
          onConfirm={() =>
            navigateToWayMoreUrl(`${REDIRECT_URL}/settings/editor`)
          }>
          <FormattedMessage id="editor-mode.confirm-description" />
        </ConfirmModal>
      </BaseModal>
    </div>
  );
}

EditorMode.propTypes = {
  version: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['header', 'editor-header']),
};

export default EditorMode;
