import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  httpCreateCategory,
  httpDeleteCategory,
  httpGetCategories,
  httpUpdateCategory,
} from 'api';
import { CATEGORY_GROUPS } from 'appConstants';
import { getErrorText, showErrorMessage } from 'helpers';

const getCategories = createAsyncThunk(
  'categories/getCategories',
  async ({ type = 'email', group, hidden } = {}) => {
    try {
      let params = { type, hidden };

      if (group) params = { ...params, group };

      const { data } = await httpGetCategories(params);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const createCategory = createAsyncThunk(
  'categories/createCategory',
  async (payload) => {
    try {
      const { data } = await httpCreateCategory({
        ...payload,
        hidden: false,
        type: 'email',
        group: CATEGORY_GROUPS.SYSTEM, // TODO: remove this when category type will be added to CategoryModal
      });

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (payload) => {
    try {
      const { data } = await httpUpdateCategory(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (id) => {
    try {
      await httpDeleteCategory(id);

      return { id };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

export { getCategories, createCategory, updateCategory, deleteCategory };
