import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { API_HOST } from 'appConstants';
import { useIntl, FormattedMessage } from 'react-intl';
import { showSuccessMessage, showErrorMessage, getErrorText } from 'helpers';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useEditorContext } from 'routes/Editor/EditorContext';
import cx from 'classnames';
import './ExportModal.sass';

export const ExportModal = ({ onClose }) => {
  const intl = useIntl();

  const FILE_TYPES = ['pdf', 'html', 'image'];

  const [isLoading, setIsLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [imageExtraOptions, setImageExtraOptions] = useState({
    desktopWidth: 600,
    mobileWidth: 320,
    format: 'png',
  });

  const {
    template: { name: templateName, id: templateId },
  } = useEditorContext();

  const handleDownload = async (url, filename) => {
    try {
      setIsLoading(true);

      await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, filename);

          showSuccessMessage(
            intl.formatMessage({ id: 'export-modal.success-message' }),
          );

          setIsLoading(false);

          onClose();
        });
    } catch {
      showErrorMessage(getErrorText());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setIsLoading(false);
      setSelectedItem('pdf');
    };
  }, [onClose]);

  return (
    <div className="export-modal">
      <div className="export-modal__file">
        {FILE_TYPES.map((type) => (
          <button
            key={`${type}-file`}
            data-testid={`export-${type}-item`}
            disabled={isLoading}
            onClick={() => setSelectedItem(type)}
            className={cx('export-modal__item', {
              loading: isLoading && selectedItem === type,
              active: selectedItem === type,
            })}>
            <img
              src={`https://cdn11.waymore.io/dnd/images/export_icons/${type}.svg`}
              alt={type.toLocaleUpperCase()}
            />
            <span className="export-modal__item__title">
              {type.toLocaleUpperCase()}
            </span>
          </button>
        ))}
      </div>
      <div
        className={cx('export-modal__details', {
          active: selectedItem,
        })}>
        <h2>
          {intl.formatMessage({ id: 'export-modal.image-options.title' })}
          {selectedItem?.toLocaleUpperCase()}
        </h2>
        {selectedItem === 'pdf' && (
          <div className="export-modal__details__actions">
            <button
              data-testid="export-pdf"
              disabled={isLoading}
              onClick={() =>
                handleDownload(
                  `${API_HOST}/api/v2/usertemplate/export/${templateId}/pdf`,
                  `${templateName}.pdf`,
                )
              }>
              <span className="icon icon-cloud-download" />
              <FormattedMessage id="export-modal.image-options.button-actions.pdf-button" />
            </button>
          </div>
        )}
        {selectedItem === 'html' && (
          <div className="export-modal__details__actions">
            <button
              data-testid="export-html"
              disabled={isLoading}
              onClick={() =>
                handleDownload(
                  `${API_HOST}/api/v2/usertemplate/export/${templateId}/html`,
                  `${templateName}.html`,
                )
              }>
              <span className="icon icon-cloud-download" />
              <FormattedMessage id="export-modal.image-options.button-actions.html-button" />
            </button>
          </div>
        )}
        {selectedItem === 'image' && (
          <>
            <div className="export-modal__details__input-range">
              <label>
                <FormattedMessage id="export-modal.image-options.desktop-range-title" />
                ({imageExtraOptions.desktopWidth})
              </label>
              <input
                type="range"
                onChange={(e) =>
                  setImageExtraOptions({
                    ...imageExtraOptions,
                    desktopWidth: Number(e.target.value),
                  })
                }
                id="desktop-width"
                name="desktop_width"
                min="600"
                max="1920"
                value={imageExtraOptions.desktopWidth || '600'}
              />
            </div>
            <div className="export-modal__details__input-range">
              <label>
                <FormattedMessage id="export-modal.image-options.mobile-range-title" />
                ({imageExtraOptions.mobileWidth})
              </label>
              <input
                type="range"
                onChange={(e) =>
                  setImageExtraOptions({
                    ...imageExtraOptions,
                    mobileWidth: Number(e.target.value),
                  })
                }
                id="desktop-width"
                name="desktop_width"
                min="320"
                max="599"
                value={imageExtraOptions.mobileWidth || '320'}
              />
            </div>
            <div className="export-modal__details__format-selection">
              <button
                disabled={isLoading}
                onClick={() =>
                  setImageExtraOptions({
                    ...imageExtraOptions,
                    format: 'png',
                  })
                }
                className={cx({ active: imageExtraOptions.format === 'png' })}>
                PNG
              </button>
              <button
                onClick={() =>
                  setImageExtraOptions({
                    ...imageExtraOptions,
                    format: 'jpeg',
                  })
                }
                className={cx({ active: imageExtraOptions.format === 'jpeg' })}>
                JPG
              </button>
            </div>
            <div className="export-modal__details__actions">
              <p>
                <FormattedMessage id="export-modal.image-options.button-actions.title" />
              </p>
              <button
                data-testid="export-desktop-image"
                disabled={isLoading}
                onClick={() =>
                  handleDownload(
                    `${API_HOST}/api/v2/usertemplate/export/${templateId}/image?width=${imageExtraOptions.desktopWidth}&format=${imageExtraOptions.format}`,
                    `${templateName}.${imageExtraOptions.format}`,
                  )
                }>
                <span className="icon icon-cloud-download" />
                <FormattedMessage id="export-modal.image-options.button-actions.desktop-button" />
              </button>
              <button
                data-testid="export-mobile-image"
                disabled={isLoading}
                onClick={() =>
                  handleDownload(
                    `${API_HOST}/api/v2/usertemplate/export/${templateId}/image?width=${imageExtraOptions.mobileWidth}&format=${imageExtraOptions.format}`,
                    `${templateName}.${imageExtraOptions.format}`,
                  )
                }>
                <span className="icon icon-cloud-download" />
                <FormattedMessage id="export-modal.image-options.button-actions.mobile-button" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ExportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ExportModal;
