import React, { useRef } from 'react';
import { FormattedMessage, FormattedDateParts, useIntl } from 'react-intl';
import { TEMPLATE_STATUS } from 'appConstants';
import {
  buildPreviewUrl,
  getListUsersFullName,
  showErrorMessage,
  showSuccessMessage,
} from 'helpers';
import PropTypes from 'prop-types';
import find from 'lodash.find';
import { useSelector } from 'react-redux';
import { useQuery, useModal } from 'hooks';
import {
  isAdminSelector,
  principalIdSelector,
  principalListSelector,
} from 'store/selectors';
import ConfirmModal from 'components/Modals/ConfirmModal';
import YesNoConfirmModal from 'components/Modals/YesNoConfirmModal';
import BaseModal from 'components/Modals/BaseModal';
import ReactTooltip from 'react-tooltip';
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button';
import cx from 'classnames';
import './TemplateItem.sass';

function TemplateItem({
  templateId,
  tags,
  templateSize,
  isShared,
  isLocked,
  userId,
  collaboratorId,
  collaboratorsList,
  isSystem,
  status,
  templateImage,
  lastModified,
  categories,
  name,
  onItemClick,
  onEditClick,
  onUnpublishClick,
  onSettingsClick,
  onPreviewClick,
  onDeleteClick,
  onRemoveClick,
  onShareClick,
  onSystemRemoveForeverClick,
  onDuplicateClick,
  onSystemEditClick,
  onSystemSelectClick,
  onSystemSettingsClick,
  onSystemRemoveClick,
  onRestoreClick,
  bulkActionTemplatesList,
  setBulkActionTemplatesList,
  bulkAction,
  onUpdateSharedTemplateLockStatus,
  onUpdateFavoriteStatus,
  onSendUnlockRequestSharedTemplateClick,
}) {
  const { system_templates, status: queryStatus } = useQuery();

  const ref = useRef(null);

  const intl = useIntl();

  const isAdmin = useSelector(isAdminSelector);
  const currentUser = useSelector(principalIdSelector);
  const principalList = useSelector(principalListSelector);

  const sharedTemplateOwner = getListUsersFullName(principalList, [userId]);
  const collaboratorNameIsEditing = getListUsersFullName(principalList, [
    collaboratorId,
  ]);
  const collaboratorsListNames = getListUsersFullName(
    principalList,
    collaboratorsList,
  );
  const isFeatured = find(categories, { name: 'Featured Layouts' });
  const tagsList = tags
    ? tags
        .toLocaleLowerCase()
        .split(',')
        .map((item) => item.trim())
    : [];
  const isFavorited = tagsList.includes('favorite') || false;

  const {
    isOpen: isDuplicateSharedTemplateOpen,
    open: openDuplicateSharedTemplate,
    close: closeDuplicateSharedTemplate,
  } = useModal();
  const {
    isOpen: isConfirmUnlockModalOpen,
    open: openConfirmUnlockModal,
    close: closeConfirmUnlockModal,
  } = useModal();
  const {
    isOpen: isUnpublishTemplateModalOpen,
    open: openUnpublishTemplateModal,
    close: closeUnpublishTemplateModal,
  } = useModal();

  const handlePreviewUrlBtnClick = async () => {
    const previewUrl = buildPreviewUrl(templateId);

    try {
      await navigator.clipboard.writeText(previewUrl);
      showSuccessMessage(
        intl.formatMessage({ id: 'toast.copy.success-message' }),
      );
    } catch (error) {
      // in case browser doesn't support clipboard api just open url
      // in new tab
      window.open(previewUrl, '_blank');
    }
  };

  const handleConfirmDuplicateSharedTemplate = () => {
    onDuplicateClick(templateId, false);
  };

  const handleDenyDuplicateSharedTemplate = () => {
    onDuplicateClick(templateId, true);
  };

  const handleUpdateFavoriteStatus = () => {
    const newTagsList = isFavorited
      ? tagsList.filter((item) => item !== 'favorite')
      : [...tagsList, 'favorite'];

    onUpdateFavoriteStatus({
      id: templateId,
      payload: { tags: newTagsList.join(',') },
    });
  };

  const handleUnpublishTemplate = () => {
    onUnpublishClick(templateId);
  };

  const handleUnlockTemplate = () => {
    onUpdateSharedTemplateLockStatus({
      id: templateId,
      lockStatus: false,
    });
  };

  const lockIconComponent = () => {
    if (isShared) {
      if (isLocked) {
        if (userId === currentUser) {
          return (
            <button
              data-tip
              data-for={`template-lock-status-${templateId}`}
              className="icon template-item__header__lock-status"
              onClick={() => openConfirmUnlockModal()}>
              <span className="icon icon-lock" />
            </button>
          );
        } else {
          return (
            <span
              data-tip
              data-for={`template-lock-status-${templateId}`}
              className="icon template-item__header__lock-status icon-lock"
            />
          );
        }
      } else {
        return (
          <span
            data-tip
            data-for={`template-lock-status-${templateId}`}
            className="icon template-item__header__lock-status icon-lock-open"
          />
        );
      }
    }

    return false;
  };

  return (
    <div
      className={cx('template-item', {
        'featured-template': isFeatured,
        'system-template': isSystem || system_templates === 'true',
      })}>
      {isFeatured && (
        <div className="ribbon">
          <div className="ribbon__glow">&nbsp;</div>
          <div className="ribbon__front">
            <FormattedMessage id="featured-template.title" />
          </div>
          <div className="ribbon__edge" />
        </div>
      )}
      <div className="template-item__wrapper">
        <div className="template-item__header">
          <input
            className={cx('template-item__header__checkbox', {
              visible: bulkAction,
            })}
            onChange={(e) => {
              if (!bulkActionTemplatesList.includes(e.target.value)) {
                setBulkActionTemplatesList([
                  ...bulkActionTemplatesList,
                  e.target.value,
                ]);
              } else {
                const newList = bulkActionTemplatesList.filter(
                  (item) => item !== e.target.value,
                );

                setBulkActionTemplatesList(newList);
              }
            }}
            type="checkbox"
            name={templateId}
            value={templateId}
            checked={bulkActionTemplatesList.includes(templateId)}
          />
          <div
            data-tip
            data-for={`template-${templateId}`}
            role="button"
            tabIndex={0}
            className="template-item__header__title"
            onClick={isSystem && isAdmin ? onSystemEditClick : onItemClick}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                isSystem && isAdmin ? onSystemEditClick() : onItemClick();
              }
            }}>
            {name}
          </div>
          <div className="template-item__header__actions">
            {isFavorited && queryStatus === 'favorited' && (
              <span
                className={`template-item__header__title__status ${status}`}>
                {status}
              </span>
            )}
            {(isFavorited ||
              status === TEMPLATE_STATUS.PUBLISHED ||
              status === TEMPLATE_STATUS.DRAFT) &&
              !isSystem && (
                <button
                  className="template-item__header__favorite"
                  onClick={handleUpdateFavoriteStatus}>
                  <span
                    className={`icon icon-${
                      isFavorited ? 'star-fill' : 'star'
                    }`}
                  />
                </button>
              )}
            {lockIconComponent()}
            {isShared && (
              <span
                data-tip
                data-for={`template-share-status-${templateId}`}
                className="icon icon-share template-item__header__share-status"
              />
            )}
            {queryStatus === 'shared' &&
              isShared &&
              isLocked &&
              collaboratorId !== currentUser && (
                <Menu>
                  <MenuButton aria-label="open template menu" ref={ref}>
                    <span className="icon icon-more-vert" />
                  </MenuButton>
                  <MenuList className="template-item__header__menu">
                    <MenuItem
                      onSelect={() =>
                        onSendUnlockRequestSharedTemplateClick(
                          collaboratorId,
                          templateId,
                        )
                      }>
                      <span className="icon icon-lock-open-right" />
                      <FormattedMessage id="template-menu.unlock-request" />
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            {queryStatus !== 'shared' && (
              <Menu>
                <MenuButton aria-label="open template menu" ref={ref}>
                  <span className="icon icon-more-vert" />
                </MenuButton>
                <MenuList className="template-item__header__menu">
                  {isSystem ? (
                    isAdmin ? (
                      <>
                        <MenuItem onSelect={onSystemEditClick}>
                          <span className="icon icon-edit" />
                          <FormattedMessage id="template-menu.edit" />
                        </MenuItem>
                        <MenuItem onSelect={onSystemSettingsClick}>
                          <span className="icon icon-settings" />
                          <FormattedMessage id="template-menu.settings" />
                        </MenuItem>
                        <MenuItem
                          className="template-item__header__menu--delete"
                          onSelect={onSystemRemoveClick}>
                          <span className="icon icon-delete" />
                          <FormattedMessage id="template-menu.delete" />
                        </MenuItem>
                      </>
                    ) : (
                      <MenuItem onSelect={onSystemSelectClick}>
                        <span className="icon icon-check-circle" />
                        <FormattedMessage id="template-menu.select" />
                      </MenuItem>
                    )
                  ) : (
                    <>
                      <MenuItem onSelect={onEditClick}>
                        <span className="icon icon-edit" />
                        <FormattedMessage id="template-menu.edit" />
                      </MenuItem>
                      {status === TEMPLATE_STATUS.DRAFT && (
                        <>
                          <MenuItem onSelect={onShareClick}>
                            <span className="icon icon-share" />
                            <FormattedMessage id="template-menu.share" />
                          </MenuItem>
                          {isShared && isLocked && userId === currentUser && (
                            <MenuItem
                              onSelect={() => {
                                onUpdateSharedTemplateLockStatus({
                                  id: templateId,
                                  lockStatus: false,
                                });
                              }}>
                              <span className="icon icon-lock-open" />
                              <FormattedMessage id="template-menu.unlock" />
                            </MenuItem>
                          )}
                        </>
                      )}
                      {!isShared && (
                        <>
                          <MenuItem onSelect={onSettingsClick}>
                            <span className="icon icon-settings" />
                            <FormattedMessage id="template-menu.settings" />
                          </MenuItem>
                          {status === TEMPLATE_STATUS.PUBLISHED && (
                            <>
                              <MenuItem onSelect={handlePreviewUrlBtnClick}>
                                <span className="icon icon-link" />
                                <FormattedMessage id="template-menu.copy-link" />
                              </MenuItem>
                              <MenuItem onSelect={openUnpublishTemplateModal}>
                                <span className="icon icon-draft" />
                                <FormattedMessage id="template-menu.recover" />
                              </MenuItem>
                            </>
                          )}
                        </>
                      )}
                      {status !== TEMPLATE_STATUS.DELETED && (
                        <MenuItem
                          onSelect={() => {
                            if (isShared) {
                              openDuplicateSharedTemplate();
                            } else {
                              onDuplicateClick(templateId);
                            }
                          }}>
                          <span className="icon icon-content-copy" />
                          <FormattedMessage id="template-menu.duplicate" />
                        </MenuItem>
                      )}
                      {status === TEMPLATE_STATUS.DELETED ? (
                        <MenuItem
                          className="template-item__header__menu--delete"
                          onSelect={
                            system_templates === 'true'
                              ? onSystemRemoveForeverClick
                              : onRemoveClick
                          }>
                          <span className="icon icon-delete" />
                          <FormattedMessage id="template.button-delete-forever" />
                        </MenuItem>
                      ) : (
                        <MenuItem
                          className="template-item__header__menu--delete"
                          onSelect={() => {
                            if (isShared && isLocked) {
                              showErrorMessage(
                                'This template is locked and cannot be deleted.',
                              );
                            } else {
                              onDeleteClick();
                            }
                          }}>
                          <span className="icon icon-delete" />
                          <FormattedMessage id="template-menu.delete" />
                        </MenuItem>
                      )}
                    </>
                  )}
                  {status === TEMPLATE_STATUS.DELETED &&
                    system_templates !== 'true' && (
                      <MenuItem onSelect={onRestoreClick}>
                        <span className="icon icon-content-copy" />
                        <FormattedMessage id="template-menu.recover" />
                      </MenuItem>
                    )}
                  <MenuItem onSelect={onPreviewClick}>
                    <span className="icon icon-preview" />
                    <FormattedMessage id="template-menu.preview" />
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </div>
        </div>
        {system_templates === 'true' ? (
          <div style={{ cursor: 'auto' }} className="template-item__content">
            <img src={templateImage} alt={name} />
          </div>
        ) : (
          <div
            role="button"
            tabIndex="0"
            className="template-item__content"
            onClick={onItemClick}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onItemClick();
              }
            }}>
            <img src={templateImage} alt={name} />
          </div>
        )}
        <div className="template-item__info">
          {lastModified && (
            <div className="template-item__edited">
              <FormattedMessage id="last-update" />
              :&nbsp;
              <FormattedDateParts
                value={lastModified}
                day="2-digit"
                month="short"
                year="numeric">
                {(parts) =>
                  `${parts[2].value} ${parts[0].value} ${parts[4].value}`
                }
              </FormattedDateParts>
            </div>
          )}
          {templateSize && (
            <div className="template-item__size">
              {templateSize > 1024
                ? `${Number.parseFloat(
                    (+templateSize / Math.pow(1024, 1)).toFixed(1),
                  )} MB`
                : `${templateSize} KB`}
            </div>
          )}
        </div>
      </div>
      <ReactTooltip
        id={`template-${templateId}`}
        class="title-tooltip"
        type="dark"
        aria-haspopup="true"
        place="top"
        clickable={false}
        delayShow={500}
        border={false}
        effect="solid">
        <span>{name}</span>
      </ReactTooltip>
      <ReactTooltip
        id={`template-share-status-${templateId}`}
        class="title-tooltip"
        type="dark"
        aria-haspopup="true"
        place="top"
        clickable={false}
        delayShow={500}
        border={false}
        effect="solid"
        getContent={() => {
          return (
            <span>
              <div>
                <strong>
                  <FormattedMessage id="template-title-header.owner-tooltip" />
                </strong>
                {sharedTemplateOwner}
              </div>
              <div>
                <strong>
                  <FormattedMessage id="template-title-header.share-tooltip" />
                </strong>
                {collaboratorsListNames}
              </div>
            </span>
          );
        }}
      />
      <ReactTooltip
        id={`template-lock-status-${templateId}`}
        class="title-tooltip"
        type="dark"
        aria-haspopup="true"
        place="top"
        clickable={false}
        delayShow={500}
        border={false}
        effect="solid">
        <span>
          {isLocked ? (
            `${intl.formatMessage({
              id: 'template-title-header.lock-tooltip',
            })} ${collaboratorNameIsEditing}`
          ) : (
            <FormattedMessage id="template-title-header.unlock-tooltip" />
          )}
        </span>
      </ReactTooltip>
      <BaseModal
        isOpen={isConfirmUnlockModalOpen}
        headerTitle="template-title-header.confirm-unlock-modal-header"
        maxWidth="600px"
        onClose={closeConfirmUnlockModal}>
        <ConfirmModal
          onClose={closeConfirmUnlockModal}
          onConfirm={handleUnlockTemplate}>
          <FormattedMessage id="template-title-header.confirm-unlock-modal-description" />
        </ConfirmModal>
      </BaseModal>
      <BaseModal
        isOpen={isUnpublishTemplateModalOpen}
        headerTitle="template-title-header.confirm-unpublish-modal-header"
        maxWidth="600px"
        onClose={closeUnpublishTemplateModal}>
        <ConfirmModal
          onClose={closeUnpublishTemplateModal}
          onConfirm={handleUnpublishTemplate}>
          <FormattedMessage id="template-title-header.confirm-unpublish-modal-description" />
        </ConfirmModal>
      </BaseModal>
      <BaseModal
        isOpen={isDuplicateSharedTemplateOpen}
        headerTitle="template-title-header.confirm-duplicate-shared-template-header"
        maxWidth="600px"
        onClose={closeDuplicateSharedTemplate}>
        <YesNoConfirmModal
          onClose={closeDuplicateSharedTemplate}
          onConfirm={handleConfirmDuplicateSharedTemplate}
          onDeny={handleDenyDuplicateSharedTemplate}>
          <FormattedMessage id="template-title-header.confirm-duplicate-shared-template-description" />
        </YesNoConfirmModal>
      </BaseModal>
    </div>
  );
}

TemplateItem.propTypes = {
  templateId: PropTypes.string.isRequired,
  templateSize: PropTypes.string,
  tags: PropTypes.string,
  isShared: PropTypes.bool,
  isLocked: PropTypes.bool,
  userId: PropTypes.string,
  collaboratorId: PropTypes.string,
  collaboratorsList: PropTypes.array,
  status: PropTypes.string.isRequired,
  isSystem: PropTypes.bool,
  templateImage: PropTypes.string,
  lastModified: PropTypes.number,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onUnpublishClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onPreviewClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
  onSystemRemoveForeverClick: PropTypes.func.isRequired,
  onDuplicateClick: PropTypes.func.isRequired,
  onSystemEditClick: PropTypes.func.isRequired,
  onSystemSelectClick: PropTypes.func.isRequired,
  onSystemSettingsClick: PropTypes.func.isRequired,
  onSystemRemoveClick: PropTypes.func.isRequired,
  onRestoreClick: PropTypes.func.isRequired,
  bulkActionTemplatesList: PropTypes.array.isRequired,
  setBulkActionTemplatesList: PropTypes.func.isRequired,
  bulkAction: PropTypes.bool.isRequired,
  onUpdateSharedTemplateLockStatus: PropTypes.func.isRequired,
  onUpdateFavoriteStatus: PropTypes.func.isRequired,
  onSendUnlockRequestSharedTemplateClick: PropTypes.func.isRequired,
};

export default TemplateItem;
