import {
  CURSOR_OPTIONS,
  FLOAT_OPTIONS,
  FONTS,
  TEXT_ALIGN_OPTIONS,
  VISIBILITY_OPTIONS,
} from 'appConstants';

export const styleManagerConfig = {
  sectors: [
    {
      name: 'General',
      open: false,
      buildProps: [
        'display',
        'position',
        'top',
        'right',
        'left',
        'bottom',
        'visibility',
      ],
      properties: [
        {
          name: 'Display',
          property: 'display',
          type: 'select',
          defaults: 'block',
          options: [
            { value: 'block' },
            { value: 'inline' },
            { value: 'inline-block' },
            { value: 'table' },
            { value: 'none' },
          ],
        },
        {
          name: 'Visibility',
          property: 'visibility',
          type: 'select',
          defaults: 'visible',
          options: VISIBILITY_OPTIONS,
        },
        {
          name: 'Cursor',
          property: 'cursor',
          type: 'select',
          defaults: 'auto',
          options: CURSOR_OPTIONS,
        },
        {
          name: 'Float',
          property: 'float',
          type: 'radio',
          defaults: 'none',
          options: FLOAT_OPTIONS,
        },
        { property: 'position', type: 'select' },
      ],
    },
    {
      name: 'Dimension',
      open: false,
      buildProps: [
        'width',
        'height',
        'max-width',
        'min-height',
        'margin',
        'padding',
      ],
      properties: [
        {
          property: 'margin',
          properties: [
            { name: 'Top', property: 'margin-top' },
            { name: 'Right', property: 'margin-right' },
            { name: 'Bottom', property: 'margin-bottom' },
            { name: 'Left', property: 'margin-left' },
          ],
        },
        {
          property: 'padding',
          properties: [
            { name: 'Top', property: 'padding-top' },
            { name: 'Right', property: 'padding-right' },
            { name: 'Bottom', property: 'padding-bottom' },
            { name: 'Left', property: 'padding-left' },
          ],
        },
      ],
    },
    {
      name: 'Typography',
      open: false,
      buildProps: [
        'font-family',
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'line-height',
        'text-shadow',
      ],
      properties: [
        {
          name: 'Line height',
          property: 'line-height',
          full: true,
        },
        {
          name: 'Font',
          property: 'font-family',
          defaults: FONTS[0].value,
          options: FONTS,
        },
        { name: 'Weight', property: 'font-weight' },
        { name: 'Font color', property: 'color' },
        {
          name: 'Vertical align',
          property: 'vertical-align',
          type: 'radio',
          defaults: 'baseline',
          list: [
            {
              value: 'baseline',
              title: 'Baseline',
              className: 'icon icon-close',
            },
            {
              value: 'top',
              title: 'Top',
              className: 'icon icon-align-items-flex-start',
            },
            {
              value: 'middle',
              title: 'Middle',
              className: 'icon icon-align-items-center',
            },
            {
              value: 'bottom',
              title: 'Bottom',
              className: 'icon icon-align-items-flex-end',
            },
          ],
        },
        {
          property: 'text-align',
          type: 'radio',
          defaults: 'left',
          options: TEXT_ALIGN_OPTIONS,
        },
        {
          property: 'text-decoration',
          type: 'radio',
          defaults: 'none',
          options: [
            { value: 'none', name: 'None', className: 'icon icon-close' },
            {
              value: 'underline',
              name: 'underline',
              className: 'icon icon-format-underlined',
            },
            {
              value: 'line-through',
              name: 'Line-through',
              className: 'icon icon-strikethrough',
            },
          ],
        },
        {
          property: 'text-shadow',
          properties: [
            { name: 'X position', property: 'text-shadow-h' },
            { name: 'Y position', property: 'text-shadow-v' },
            { name: 'Blur', property: 'text-shadow-blur' },
            { name: 'Color', property: 'text-shadow-color' },
          ],
        },
      ],
    },
    {
      name: 'Decorations',
      open: false,
      buildProps: [
        'opacity',
        'border-radius-c',
        'background-color',
        'border-radius',
        'border',
        'box-shadow',
        'background',
      ],
      properties: [
        {
          type: 'slider',
          property: 'opacity',
          defaults: 1,
          step: 0.01,
          max: 1,
          min: 0,
        },
        {
          property: 'border-radius',
          properties: [
            { name: 'Top Left', property: 'border-top-left-radius' },
            { name: 'Top Right', property: 'border-top-right-radius' },
            { name: 'Bottom Right', property: 'border-bottom-right-radius' },
            { name: 'Bottom Left', property: 'border-bottom-left-radius' },
          ],
        },
        {
          property: 'box-shadow',
          properties: [
            { name: 'X position', property: 'box-shadow-h' },
            { name: 'Y position', property: 'box-shadow-v' },
            { name: 'Blur', property: 'box-shadow-blur' },
            { name: 'Spread', property: 'box-shadow-spread' },
            { name: 'Color', property: 'box-shadow-color' },
            { name: 'Shadow type', property: 'box-shadow-type' },
          ],
        },
      ],
    },
    {
      name: 'Extra',
      open: false,
      buildProps: ['transition', 'perspective', 'transform'],
      properties: [
        { name: 'Perspective', property: 'perspective', full: true },
      ],
    },
  ],
};

export const canvasCss = `
  table[data-type="root"] {
    min-height: 100vh;
  }
  table[data-type="root"] tr {
    height: 100%;
  }
  html, body, [data-gjs-type=wrapper] {
    font-family: Arial, Helvetica, sans-serif;
  }
  a {
    text-decoration: none;
  }
  h1 {
    font-size: 40px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 18px;
  }
  h2 {
    font-size: 32px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3 {
    font-size: 28px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 12px;
  }
  h4 {
    font-size: 24px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 10px;
  }
  h5 {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 8px;
  }
  h6 {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p,
  body:not(font)[data-gjs-type=text],
  [data-gjs-type=wrapper] a[data-gjs-type=link] {
    font-family: Arial, Helvetica, sans-serif;
  }
  [data-gjs-type=wrapper] {
    padding-bottom: 10px;
  }
  body [data-gjs-type=text] > :not(font)[data-gjs-type=text] {
    font-family: inherit;
  }
  [data-gjs-type="cell"]:not([isspacer=true]):not(.divider):empty:before,
  [data-gjs-type="root-cell"]:empty:before {
    background-color: #ddd;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    padding: 0 10px;
    opacity: 0.3;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    content: "Drop something here";
  }
  [data-gjs-type="root-cell"]:empty:before {
    content: "Drag a Block or Module and drop it here to start building your new email template";
    white-space: pre-line;
    text-align: center;
    padding: 10px 3rem;
  }
  @media (max-width: 768px) {
    table tr,
    table tr th,
    table tr td {
      width: 100% !important;
      display: block;
    }
  }
`;

export const protectedCss = '';
