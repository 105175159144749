import { REDIRECT_URL } from 'appConstants';

export default function (
  editor,
  { category, createCustomCode, customCodeType, discountCodeType },
  advanced,
) {
  const bm = editor.BlockManager;

  bm.add(customCodeType, {
    label: 'Code',
    category: advanced === 'true' ? category : 'Basic',
    activate: true,
    select: true,
    content: { type: customCodeType },
    attributes: {
      class: `bpreview-custom-code ${
        !createCustomCode ? 'gjs-block--lock' : ''
      }`,
    },
    disable: !createCustomCode,
    media: !createCustomCode
      ? `<a target="_blank" title="Not enabled in your plan" href="${REDIRECT_URL}/settings/subscriptions" draggable="false"><span class="icon icon-lock"></span></a>`
      : '',
  });

  advanced === 'true' &&
    bm.add('quote', {
      category,
      label: 'Quote',
      attributes: { class: 'bpreview-quote' },
      content: `<blockquote class="quote">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
        incididunt ut labore ipsum dolor sit
      </blockquote>`,
    });

  const gridItem = `<table class="grid-item-card">
    <tr>
      <td class="grid-item-card-cell">
        <img class="grid-item-image" src="" alt="Image"/>
        <table class="grid-item-card-body">
          <tr>
            <td class="grid-item-card-content">
              <h1 class="card-title">Title here</h1>
              <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                sed do eiusmod tempor incididunt</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>`;

  advanced === 'true' &&
    bm.add('grid-items', {
      label: 'Grid Items',
      category,
      content: `<table class="grid-item-row">
      <tr>
        <td class="grid-item-cell2-l">${gridItem}</td>
        <td class="grid-item-cell2-r">${gridItem}</td>
      </tr>
    </table>`,
      attributes: { class: 'bpreview-grid-items' },
    });

  bm.add('form-data-table', {
    label: 'Table',
    category: advanced === 'true' ? category : 'Basic',
    attributes: { class: 'bpreview-form-data-table' },
    content: `
      <table
        leftcolumntext="Question"
        rightcolumntext="Answer"
        data-type="form-data-table"
        bordercolor="#ccc"
        borderwidth="1"
        rowspadding="10"
        fontsize="14"
        textalign="center"
        textcolor="#000"
        margintop="16"
        marginbottom="16"
      >
        <tbody>
          <tr>
            <th>Question</th>
            <th>Answer</th>
          </tr>
          <tr>
            <td>First Name</td>
            <td>John</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>Smith</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>John@site.com</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>9999-777-8888</td>
          </tr>
          <tr>
            <td>Comment</td>
            <td>This is a sample comment.</td>
          </tr>
        </tbody>
      </table>
      <style>
        .form-data-table {
          width: 100%;
          border-collapse: collapse;
          border: none;
          text-align: center;
          color: #000;
          margin-top: 16px;
          margin-bottom: 16px;
        }
        .form-data-table th,
        .form-data-table td {
          padding: 10px 10px 10px 10px;
          border: 1px solid #ccc;
          font-size: 14px;
          text-align: center;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: normal;
          letter-spacing: 0;
          line-height: normal;
          text-decoration: none;
          text-shadow: none;
        }
        .form-data-table th {
          font-weight: bold;
        }
        div[data-gjs-type="wrapper"] .form-data-table {
          background: repeating-linear-gradient( -45deg, #3637491a, #ffffff00 2px, #e5e5f71a 2px, #e5e5f700 10px );
        }
        div[data-gjs-type="wrapper"] .form-data-table * {
          pointer-events: none;
        }
        @media (max-width: 768px) {
          .form-data-table th,
          .form-data-table tr:not(:last-child) td,
          .form-data-table tr:last-child td:first-child {
            border-bottom: none;
          }
        }
      </style>
      `,
    media: '<span>Form Data</span>',
  });

  bm.add('qr-code', {
    label: 'QR Code',
    category: advanced === 'true' ? category : 'Basic',
    attributes: { class: 'bpreview-qr-code' },
    content: `
      <img
        data-type="qr-code"
        qr-code-url="{$QRCODE}"
        waymore-use="true"
        class="qr-code"
        src="https://cdn11.waymore.io/dnd/images/qr-code.png"
        alt="qr"
      />
    `,
  });

  bm.add(discountCodeType, {
    label: 'Discount Code',
    category: advanced === 'true' ? category : 'Basic',
    content: { type: discountCodeType },
    attributes: {
      class: 'bpreview-discount-code',
    },
  });
}
