import axios from 'axios';

const httpGetVideoInfo = (params) => {
  return axios.get('/api/v2/video/', { params });
};

const httpGetVideoMerge = (params) => {
  return axios.get('/api/v2/video/merge', { params });
};

export { httpGetVideoInfo, httpGetVideoMerge };
