import { checkComponentType } from 'helpers';
import { useToggleTypes } from 'hooks';

const addWayMoreProduct = (editor, { wayMoreProductType }) => {
  editor.DomComponents.addType(wayMoreProductType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Waymore product',
      },
    },
    view: {
      events: {
        dblclick: 'onActive',
      },
      onActive(e) {
        const onShow = editor.getModel().get('onShow');

        if (
          !e ||
          checkComponentType(e.target, wayMoreProductType) ||
          checkComponentType(e.target, 'table') ||
          checkComponentType(e.target, 'cell')
        ) {
          onShow(useToggleTypes.showWayMoreProductModal);

          editor.select(this.model.closest('.product-section'));
        }
      },
    },
    isComponent(el) {
      if (checkComponentType(el, wayMoreProductType)) {
        return { type: wayMoreProductType };
      }
    },
  });
};

export default (editor, config) => {
  addWayMoreProduct(editor, config);
};
