import axios from 'axios';

const httpGetWayMoreStores = () => {
  return axios.get('/api/v2/stores');
};

const httpGetWayMoreCategories = (storeId) => {
  return axios.get(`/api/v2/stores/${storeId}/categories`);
};

const httpGetWayMoreProducts = (storeId, params) => {
  return axios.get(`/api/v2/products/store/${storeId}`, { params });
};

export {
  httpGetWayMoreStores,
  httpGetWayMoreCategories,
  httpGetWayMoreProducts,
};
