import { checkComponentType } from '../../../../../helpers/common';

const addPageWrapper = (editor, { pageWrapperType }) => {
  const domc = editor.DomComponents;

  domc.addType(pageWrapperType, {
    extends: 'table',
    model: {
      defaults: {
        name: 'Page wrapper',
        type: pageWrapperType,
        removable: false,
        draggable: '[data-gjs-type="wrapper"]',
        droppable: false,
        copyable: false,
        tagName: 'table',
      },
    },
    isComponent(el) {
      if (checkComponentType(el, pageWrapperType)) {
        return { type: pageWrapperType };
      }
    },
  });
};

const addPageWrapperBody = (editor, { pageWrapperBodyType }) => {
  const domc = editor.DomComponents;

  domc.addType(pageWrapperBodyType, {
    extends: 'row',
    model: {
      defaults: {
        draggable: false,
        droppable: false,
        removable: false,
        stylable: false,
        copyable: false,
        editable: false,
        selectable: false,
        hoverable: false,
        layerable: false,
        type: pageWrapperBodyType,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, pageWrapperBodyType)) {
        return { type: pageWrapperBodyType };
      }
    },
  });
};

const addPageWrapperRow = (editor, { pageWrapperRowType }) => {
  const domc = editor.DomComponents;

  domc.addType(pageWrapperRowType, {
    extends: 'row',
    model: {
      defaults: {
        draggable: false,
        droppable: false,
        removable: false,
        stylable: false,
        copyable: false,
        editable: false,
        selectable: false,
        hoverable: false,
        layerable: false,
        type: pageWrapperRowType,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, pageWrapperRowType)) {
        return { type: pageWrapperRowType };
      }
    },
  });
};

const addPageWrapperCell = (editor, { pageWrapperCellType }) => {
  const domc = editor.DomComponents;

  domc.addType(pageWrapperCellType, {
    extends: 'cell',
    model: {
      defaults: {
        draggable: false,
        removable: false,
        stylable: false,
        copyable: false,
        editable: false,
        selectable: false,
        hoverable: false,
        layerable: false,
        type: pageWrapperCellType,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, pageWrapperCellType)) {
        return { type: pageWrapperCellType };
      }
    },
  });
};

const addSection = (editor, { sectionType }) => {
  const domc = editor.DomComponents;

  domc.addType(sectionType, {
    extends: 'table',
    model: {
      defaults: {
        name: 'Section',
        type: sectionType,
        tagName: 'table',
      },
    },
    isComponent(el) {
      if (checkComponentType(el, sectionType)) {
        return { type: sectionType };
      }
    },
  });
};

const addSpacer = (editor, { spacerType }) => {
  const domc = editor.DomComponents;

  domc.addType(spacerType, {
    model: {
      defaults: {
        name: 'Spacer',
        droppable: false,
        resizable: false,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, spacerType)) {
        return { type: spacerType };
      }
    },
  });
};

const addDivider = (editor, { dividerType }) => {
  const domc = editor.DomComponents;

  domc.addType(dividerType, {
    model: {
      defaults: {
        name: 'Divider',
        droppable: false,
        resizable: false,
      },
    },
    isComponent(el) {
      if (checkComponentType(el, dividerType)) {
        return { type: dividerType };
      }
    },
  });
};

export default (editor, config) => {
  addPageWrapper(editor, config);
  addPageWrapperBody(editor, config);
  addPageWrapperRow(editor, config);
  addPageWrapperCell(editor, config);
  addSection(editor, config);
  addSpacer(editor, config);
  addDivider(editor, config);
};
