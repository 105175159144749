import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  httpCopyFromGlobalToTemplateAssets,
  httpGetGlobalAssets,
  httpDeleteAsset,
  httpDeleteGlobalAsset,
  httpGetAssets,
  httpSearchIcons,
  httpUnsplash,
  httpUploadAssets,
} from 'api';
import { buildAssetsUrl, getErrorText, showErrorMessage } from 'helpers';

const copyFromGlobalToTemplateAssets = createAsyncThunk(
  'assets/copyFromGlobalToTemplateAssets',
  async ({ id, imageName }) => {
    try {
      const { data } = await httpCopyFromGlobalToTemplateAssets(id, imageName);

      return data;
    } catch (error) {
      if (error?.response?.status !== 409) {
        showErrorMessage(getErrorText());
        throw error;
      }
    }
  },
);

const getGlobalAssets = createAsyncThunk('assets/getGlobalAssets', async () => {
  try {
    const { data } = await httpGetGlobalAssets();

    return data;
  } catch (error) {
    showErrorMessage(getErrorText());
    throw error;
  }
});

const getAssets = createAsyncThunk('assets/getAssets', async (id) => {
  try {
    const { data } = await httpGetAssets(id);

    return data;
  } catch (error) {
    showErrorMessage(getErrorText());
    throw error;
  }
});

const uploadAssets = createAsyncThunk(
  'assets/uploadAssets',
  async ({ payload }) => {
    try {
      const { data } = await httpUploadAssets(payload);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteGlobalAsset = createAsyncThunk(
  'assets/deleteGlobalAsset',
  async ({ assetName } = {}) => {
    try {
      await httpDeleteGlobalAsset(assetName);

      return { assetName };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const deleteAsset = createAsyncThunk(
  'assets/deleteAsset',
  async ({ id, name } = {}) => {
    try {
      const payload = { url: buildAssetsUrl(id, name) };

      await httpDeleteAsset(id, payload);

      return { name };
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const clearGlobalImages = createAction('clearGlobalImages');

const clearTemplateImages = createAction('clearTemplateImages');

const getUnsplash = createAsyncThunk(
  'assets/unsplash',
  async ({ url: urlString, params } = {}) => {
    try {
      const url = new URL(urlString);

      url.search = new URLSearchParams(params);

      const { data } = await httpUnsplash(url);

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      throw error;
    }
  },
);

const setUnsplashSearchTerm = createAction('setUnsplashSearchTerm');

const setUnsplashNextPage = createAction('setUnsplashNextPage');

const clearUnsplash = createAction('clearUnsplash');

const searchIcons = createAsyncThunk('assets/searchIcons', async (params) => {
  try {
    const url = new URL('https://api.iconfinder.com/v4/icons/search');

    url.search = new URLSearchParams(params);

    const { data } = await httpSearchIcons(url);

    return data;
  } catch (error) {
    showErrorMessage(getErrorText());
    throw error;
  }
});

const setIconFinderSearchTerm = createAction('setIconFinderSearchTerm');

const setIconFinderNextPage = createAction('setIconFinderNextPage');

const clearIconFinder = createAction('clearIconFinder');

export {
  copyFromGlobalToTemplateAssets,
  getGlobalAssets,
  getAssets,
  uploadAssets,
  deleteGlobalAsset,
  deleteAsset,
  clearGlobalImages,
  clearTemplateImages,
  getUnsplash,
  setUnsplashSearchTerm,
  setUnsplashNextPage,
  clearUnsplash,
  searchIcons,
  setIconFinderSearchTerm,
  setIconFinderNextPage,
  clearIconFinder,
};
