import { createAsyncThunk } from '@reduxjs/toolkit';

import { httpGetCustomFields } from 'api';
import { getErrorText, showErrorMessage } from 'helpers';

const getCustomFields = createAsyncThunk(
  'contacts/getCustomFields',
  async () => {
    try {
      const { data } = await httpGetCustomFields();

      return data;
    } catch (error) {
      showErrorMessage(getErrorText());
      return [];
    }
  },
);

export { getCustomFields };
