export const EMAIL_REGEX =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const BASE64_REGEX = /(data:image\/[^;]+;base64)/g;

export const START_END_WITH_SPACE = /(^\s+)|(\s+$)/i;

export const SPECIAL_SIGNS = /[!@#$%^&*,.?":{}|<>]/i;

export const URL_REGEX =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
