import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useEditorContext } from 'routes/Editor/EditorContext';
import HeadingsItem from './HeadingsItem';

function Headings() {
  const {
    generalSettings: {
      h1HeadingFontSize,
      h1HeadingFontColor,
      h1HeadingBold,
      h1HeadingItalic,
      h1HeadingBottomSpace,
      h2HeadingFontSize,
      h2HeadingFontColor,
      h2HeadingBold,
      h2HeadingItalic,
      h2HeadingBottomSpace,
      h3HeadingFontSize,
      h3HeadingFontColor,
      h3HeadingBold,
      h3HeadingItalic,
      h3HeadingBottomSpace,
      h4HeadingFontSize,
      h4HeadingFontColor,
      h4HeadingBold,
      h4HeadingItalic,
      h4HeadingBottomSpace,
      h5HeadingFontSize,
      h5HeadingFontColor,
      h5HeadingBold,
      h5HeadingItalic,
      h5HeadingBottomSpace,
      h6HeadingFontSize,
      h6HeadingFontColor,
      h6HeadingBold,
      h6HeadingItalic,
      h6HeadingBottomSpace,
    },
    onChangeGeneralSettings,
  } = useEditorContext();

  return (
    <div className="general-settings-modal__content headings-setting">
      <p className="general-settings-modal__content__headings-info">
        <FormattedMessage id="general-settings-modal.headings-info" />
      </p>
      <HeadingsItem
        headingTitle="general-settings-modal.headings-tab.h1.title"
        headingFontSize={h1HeadingFontSize}
        headingFontColor={h1HeadingFontColor}
        headingBold={h1HeadingBold}
        headingItalic={h1HeadingItalic}
        headingBottomSpace={h1HeadingBottomSpace}
        headingFontSizeSettingName="h1HeadingFontSize"
        headingFontColorSettingName="h1HeadingFontColor"
        headingBoldSettingName="h1HeadingBold"
        headingItalicSettingName="h1HeadingItalic"
        headingBottomSpaceSettingName="h1HeadingBottomSpace"
        onChangeGeneralSettings={onChangeGeneralSettings}
      />
      <HeadingsItem
        headingTitle="general-settings-modal.headings-tab.h2.title"
        headingFontSize={h2HeadingFontSize}
        headingFontColor={h2HeadingFontColor}
        headingBold={h2HeadingBold}
        headingItalic={h2HeadingItalic}
        headingBottomSpace={h2HeadingBottomSpace}
        headingFontSizeSettingName="h2HeadingFontSize"
        headingFontColorSettingName="h2HeadingFontColor"
        headingBoldSettingName="h2HeadingBold"
        headingItalicSettingName="h2HeadingItalic"
        headingBottomSpaceSettingName="h2HeadingBottomSpace"
        onChangeGeneralSettings={onChangeGeneralSettings}
      />
      <HeadingsItem
        headingTitle="general-settings-modal.headings-tab.h3.title"
        headingFontSize={h3HeadingFontSize}
        headingFontColor={h3HeadingFontColor}
        headingBold={h3HeadingBold}
        headingItalic={h3HeadingItalic}
        headingBottomSpace={h3HeadingBottomSpace}
        headingFontSizeSettingName="h3HeadingFontSize"
        headingFontColorSettingName="h3HeadingFontColor"
        headingBoldSettingName="h3HeadingBold"
        headingItalicSettingName="h3HeadingItalic"
        headingBottomSpaceSettingName="h3HeadingBottomSpace"
        onChangeGeneralSettings={onChangeGeneralSettings}
      />
      <HeadingsItem
        headingTitle="general-settings-modal.headings-tab.h4.title"
        headingFontSize={h4HeadingFontSize}
        headingFontColor={h4HeadingFontColor}
        headingBold={h4HeadingBold}
        headingItalic={h4HeadingItalic}
        headingBottomSpace={h4HeadingBottomSpace}
        headingFontSizeSettingName="h4HeadingFontSize"
        headingFontColorSettingName="h4HeadingFontColor"
        headingBoldSettingName="h4HeadingBold"
        headingItalicSettingName="h4HeadingItalic"
        headingBottomSpaceSettingName="h4HeadingBottomSpace"
        onChangeGeneralSettings={onChangeGeneralSettings}
      />
      <HeadingsItem
        headingTitle="general-settings-modal.headings-tab.h5.title"
        headingFontSize={h5HeadingFontSize}
        headingFontColor={h5HeadingFontColor}
        headingBold={h5HeadingBold}
        headingItalic={h5HeadingItalic}
        headingBottomSpace={h5HeadingBottomSpace}
        headingFontSizeSettingName="h5HeadingFontSize"
        headingFontColorSettingName="h5HeadingFontColor"
        headingBoldSettingName="h5HeadingBold"
        headingItalicSettingName="h5HeadingItalic"
        headingBottomSpaceSettingName="h5HeadingBottomSpace"
        onChangeGeneralSettings={onChangeGeneralSettings}
      />
      <HeadingsItem
        headingTitle="general-settings-modal.headings-tab.h6.title"
        headingFontSize={h6HeadingFontSize}
        headingFontColor={h6HeadingFontColor}
        headingBold={h6HeadingBold}
        headingItalic={h6HeadingItalic}
        headingBottomSpace={h6HeadingBottomSpace}
        headingFontSizeSettingName="h6HeadingFontSize"
        headingFontColorSettingName="h6HeadingFontColor"
        headingBoldSettingName="h6HeadingBold"
        headingItalicSettingName="h6HeadingItalic"
        headingBottomSpaceSettingName="h6HeadingBottomSpace"
        onChangeGeneralSettings={onChangeGeneralSettings}
      />
    </div>
  );
}

export default Headings;
