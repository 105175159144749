import React, { useCallback, useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { FormattedMessage } from 'react-intl';
import { USER_GUIDE_KEYS } from 'appConstants';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage/ErrorMessage';
import PublishedModal from 'components/Modals/PublishedModal';
import BaseModal from 'components/Modals/BaseModal';
import { useTemplate, useToggleTypes } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';
import { principalIdSelector } from 'store/selectors';
import { getCustomBlocksGroup } from 'store/actions';
import cx from 'classnames';
import Grapes from './Grapes';
import './Editor.sass';
import { EditorContext } from './EditorContext';

const editorTourSteps = [
  {
    placement: 'center',
    target: 'body',
    title: <FormattedMessage id="editor-tour.start-step.title" />,
    content: <FormattedMessage id="editor-tour.start-step.message" />,
    locale: {
      skip: <FormattedMessage id="editor-tour.button.skip" />,
      next: <FormattedMessage id="editor-tour.button.start-tour" />,
    },
  },
  {
    placement: 'left-start',
    target: '.editor-content__canvas',
    styles: { options: { width: 270 } },
    title: <FormattedMessage id="editor-tour.canvas-step.title" />,
    content: (
      <div style={{ textAlign: 'justify' }}>
        <FormattedMessage id="editor-tour.canvas-step.message" />
      </div>
    ),
  },
  {
    placement: 'bottom',
    target: '[data-user-guide-step="settings"]',
    title: <FormattedMessage id="editor-tour.settings-step.title" />,
    content: (
      <div style={{ textAlign: 'justify' }}>
        <FormattedMessage id="editor-tour.settings-step.message" />
      </div>
    ),
  },
  {
    placement: 'right-start',
    target: '.editor-content__blocks',
    title: <FormattedMessage id="editor-tour.components-step.title" />,
    content: (
      <div style={{ textAlign: 'justify' }}>
        <FormattedMessage id="editor-tour.components-step.message" />
      </div>
    ),
  },
  {
    placement: 'left-start',
    target: '.editor-content__traits',
    title: <FormattedMessage id="editor-tour.traits-step.title" />,
    content: (
      <div style={{ textAlign: 'justify' }}>
        <FormattedMessage id="editor-tour.traits-step.message" />
      </div>
    ),
  },
  {
    placement: 'bottom',
    target: '.editor-content__panel-canvas-history',
    title: <FormattedMessage id="editor-tour.history-step.title" />,
    content: <FormattedMessage id="editor-tour.history-step.message" />,
  },
  {
    placement: 'bottom',
    target: '.editor-content__panel-responsive-actions',
    title: <FormattedMessage id="editor-tour.responsive-actions-step.title" />,
    content: (
      <FormattedMessage id="editor-tour.responsive-actions-step.message" />
    ),
  },
  {
    placement: 'bottom',
    target: '.editor-content__panel-canvas-controls',
    title: <FormattedMessage id="editor-tour.canvas-controls-step.title" />,
    content: <FormattedMessage id="editor-tour.canvas-controls-step.message" />,
  },
  {
    placement: 'center',
    target: 'body',
    title: <FormattedMessage id="editor-tour.final-step.title" />,
    content: (
      <FormattedMessage
        id="editor-tour.final-step.message"
        values={{
          link: (
            <a
              href="https://emaileditor.guides.waymore.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#629AC7' }}>
              <FormattedMessage id="editor-tour.final-step.guide" />
            </a>
          ),
        }}
      />
    ),
    locale: {
      skip: <FormattedMessage id="editor-tour.button.skip" />,
      next: <FormattedMessage id="editor-tour.button.start-tour" />,
    },
  },
];

const Editor = () => {
  const {
    template,
    generalSettings,
    haveChanges,
    show,
    isError,
    isLoading,
    isUpdateLoading,
    isStatusLoading,
    isConvertLoading,
    isSendEmailLoading,
    isChangeInfoLoading,
    handleChangeTemplate,
    handleChangeInfo,
    handleChangeStatus,
    handleSaveTemplate,
    handleConvertToSystem,
    handleSendTestEmails,
    handleGoBack,
    handleShow,
    handleChangeGeneralSettings,
    setGeneralSettings,
  } = useTemplate();

  const dispatch = useDispatch();

  const currentUser = useSelector(principalIdSelector);

  const [grapesLoaded, setGrapesLoaded] = useState(false);
  const [cbLoaded, setcbLoaded] = useState(false);
  const [isEditorTourFinished, setIsEditorTourFinished] = useState(() => {
    const initialIsEditorTourFinished = localStorage.getItem(
      USER_GUIDE_KEYS.EDITOR_TOUR,
    );

    // local storage stores only strings, so we should convert it by hand
    return initialIsEditorTourFinished === 'true';
  });

  const templateIsSharedWithCurrentUser =
    template?.isShared &&
    template?.userId !== currentUser &&
    template?.userId !== template?.collaboratorId &&
    !template?.collaboratorsList.includes(template?.userId);

  useEffect(() => {
    const requestCustomBlocksGroup = async () => {
      await dispatch(getCustomBlocksGroup());
      setcbLoaded(true);
    };

    requestCustomBlocksGroup();
  }, [dispatch]);

  const handleFinishEditorTour = ({ status }) => {
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setIsEditorTourFinished(true);
      localStorage.setItem(USER_GUIDE_KEYS.EDITOR_TOUR, true);
    }
  };

  const handleRestartEditorTour = useCallback(() => {
    setIsEditorTourFinished(false);
    localStorage.removeItem(USER_GUIDE_KEYS.EDITOR_TOUR);
  }, []);

  const onLoad = useCallback(() => {
    setGrapesLoaded(true);
  }, []);

  if (isError) {
    return <ErrorMessage />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!template) {
    return null;
  }

  return (
    <EditorContext.Provider
      value={{
        template,
        generalSettings,
        haveChanges,
        show,
        isUpdateLoading,
        isStatusLoading,
        isConvertLoading,
        isSendEmailLoading,
        isChangeInfoLoading,
        onChangeTemplate: handleChangeTemplate,
        onChangeInfo: handleChangeInfo,
        onChangeStatus: handleChangeStatus,
        onSaveTemplate: handleSaveTemplate,
        onConvertToSystem: handleConvertToSystem,
        onShow: handleShow,
        onSendTestEmails: handleSendTestEmails,
        onChangeGeneralSettings: handleChangeGeneralSettings,
        onSetGeneralSettings: setGeneralSettings,
        onRestartEditorTour: handleRestartEditorTour,
      }}>
      <div
        id="editor"
        className={cx({
          'shared-temp-current-user': templateIsSharedWithCurrentUser,
        })}>
        {cbLoaded && <Grapes onLoad={onLoad} />}
        {!grapesLoaded && <Loading />}
        <BaseModal
          isOpen={show.publishedModal}
          maxWidth="700px"
          headerTitle="published-modal.header-title"
          onClose={() => handleShow(useToggleTypes.showPublishedModal)}>
          <PublishedModal
            isOpen={show.publishedModal}
            loading={isConvertLoading}
            onClose={() => handleShow(useToggleTypes.showPublishedModal)}
            onGoBack={handleGoBack}
            onConvertToSystem={handleConvertToSystem}
          />
        </BaseModal>
      </div>
      {grapesLoaded && (
        <Joyride
          run={!isEditorTourFinished}
          steps={editorTourSteps}
          showSkipButton
          continuous
          callback={handleFinishEditorTour}
          styles={{
            options: {
              primaryColor: '#629AC7',
              textColor: '#110349',
              zIndex: 99,
            },
          }}
          locale={{
            skip: <FormattedMessage id="button.cancel" />,
            next: <FormattedMessage id="button.continue" />,
            back: <FormattedMessage id="button.back" />,
            last: <FormattedMessage id="button.finish" />,
          }}
        />
      )}
    </EditorContext.Provider>
  );
};

export default Editor;
