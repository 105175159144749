import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useCombobox } from 'downshift';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { useVirtual } from 'react-virtual';

import FormControl from '../Forms/FormControl';
import Label from '../Forms/Label';
import Input from '../Forms/Input';
import Button from '../Button';
import { List, ListItem } from '../List';
import './Combobox.sass';

function Combobox({
  items,
  itemToString,
  normalizeItem,
  disabled,
  fullWidth,
  label,
  placeholder,
  renderOption,
  loading,
  itemHeight = 22,
  ...rest
}) {
  const intl = useIntl();

  const listRef = useRef();
  const inputRef = useRef();

  const { virtualItems, totalSize, scrollToIndex } = useVirtual({
    size: items.length,
    parentRef: listRef,
    estimateSize: React.useCallback(() => itemHeight, [itemHeight]),
    overscan: 2,
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
    openMenu,
    reset,
  } = useCombobox({
    items,
    itemToString,
    stateReducer: (state, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: itemToString(selectedItem),
          };
        default:
          return changes;
      }
    },
    onHighlightedIndexChange: ({ highlightedIndex }) => {
      if (highlightedIndex >= 0) {
        scrollToIndex(highlightedIndex);
      }
    },
    ...rest,
  });

  const handleInputFocus = () => {
    if (!isOpen) {
      openMenu();
    }
  };

  let menuContent = (
    <div className="dnd-combobox__empty-list">
      {intl.formatMessage({ id: 'combobox.empty-list' })}
    </div>
  );

  if (virtualItems.length) {
    menuContent = (
      <div
        key="total-size"
        style={{ height: totalSize, width: '100%', position: 'relative' }}>
        {virtualItems.map(({ index, size, start }) => {
          const item = items[index];
          const { key, label } = normalizeItem(item);

          return (
            <ListItem
              className={cx('dnd-combobox__list-item', {
                selected: selectedItem === items[index],
                highlighted: highlightedIndex === index,
              })}
              key={key}
              {...getItemProps({
                item,
                index,
                style: {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: size,
                  transform: `translateY(${start}px)`,
                },
              })}>
              {renderOption ? renderOption(item) : label}
            </ListItem>
          );
        })}
      </div>
    );
  }

  if (loading) {
    menuContent = (
      <div className="dnd-combobox__empty-list">
        {intl.formatMessage({ id: 'combobox.loading' })}
      </div>
    );
  }

  return (
    <FormControl fullWidth={fullWidth} {...getComboboxProps()}>
      {label && <Label {...getLabelProps()}>{label}</Label>}
      <Input
        fullWidth={fullWidth}
        placeholder={placeholder}
        after={
          <>
            {loading && (
              <div className="dnd-combobox__loading">
                <span className="icon icon-spinner" aria-label="loading" />
              </div>
            )}
            {!loading && selectedItem && (
              <Button
                type="button"
                className="dnd-combobox__button"
                aria-label="clear"
                onClick={() => {
                  inputRef.current.focus();
                  reset();
                }}>
                <span className="icon icon-close" />
              </Button>
            )}
            <Button
              type="button"
              className="dnd-combobox__button"
              {...getToggleButtonProps({ disabled })}
              aria-label="toggle menu">
              <span className="icon icon-expand-more" />
            </Button>
          </>
        }
        {...getInputProps({
          ref: inputRef,
          disabled,
          onFocus: handleInputFocus,
        })}
      />
      <List
        className={cx('dnd-combobox__list', { isOpen })}
        {...getMenuProps({ ref: listRef })}>
        {isOpen && menuContent}
      </List>
    </FormControl>
  );
}

Combobox.propTypes = {
  items: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  itemToString: PropTypes.func.isRequired,
  normalizeItem: PropTypes.func.isRequired, // (item: any) => { key: string; label: string }
  renderOption: PropTypes.func,
  itemHeight: PropTypes.number,
};

export default Combobox;
