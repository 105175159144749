import {
  FLOAT_OPTIONS,
  FONTS,
  TEXT_ALIGN_OPTIONS,
  TEXT_DECORATION_OPTIONS,
  VERTICAL_ALIGN_OPTIONS,
  VISIBILITY_OPTIONS,
} from 'appConstants';

const colorProperty = {
  property: 'color',
  default: '',
};

const verticalAlignProperty = {
  name: 'Vertical align',
  property: 'vertical-align',
  type: 'radio',
  defaults: 'baseline',
  list: VERTICAL_ALIGN_OPTIONS,
};

const visibilityProperty = {
  name: 'Visibility',
  property: 'visibility',
  type: 'select',
  defaults: 'visible',
  options: VISIBILITY_OPTIONS,
};

const customLineHeightProperty = {
  name: 'Line height',
  property: 'line-height',
  type: 'radio',
  default: 'inherit',
  options: [
    {
      value: 'inherit',
      name: 'Inherit',
      className: 'icon icon-close',
    },
    {
      value: '100%',
      name: '100',
      className: 'icon icon-line-spacing-1x',
    },
    {
      value: '120%',
      name: '120',
      className: 'icon icon-line-spacing-2x',
    },
    {
      value: '150%',
      name: '150',
      className: 'icon icon-line-spacing-3x',
    },
    {
      value: '200%',
      name: '200',
      className: 'icon icon-line-spacing-4x',
    },
  ],
};

const textAlignProperty = {
  property: 'text-align',
  type: 'radio',
  defaults: 'justify',
  name: 'Text alignment',
  options: TEXT_ALIGN_OPTIONS,
};

const fontFamilyProperty = {
  name: 'Font',
  property: 'font-family',
  defaults: FONTS[0].value,
  options: FONTS,
};

const widthFullProperty = {
  property: 'width',
  full: true,
};

const opacityProperty = {
  name: 'Opacity',
  type: 'slider',
  property: 'opacity',
  defaults: 1,
  step: 0.01,
  max: 1,
  min: 0,
};

const floatProperty = {
  name: 'Float',
  property: 'float',
  type: 'radio',
  defaults: 'none',
  options: FLOAT_OPTIONS,
};

const textDecorationProperty = {
  property: 'text-decoration',
  type: 'radio',
  defaults: 'none',
  options: TEXT_DECORATION_OPTIONS,
};

const textShadowProperty = {
  property: 'text-shadow',
  properties: [
    { name: 'X position', property: 'text-shadow-h' },
    { name: 'Y position', property: 'text-shadow-v' },
    { name: 'Blur', property: 'text-shadow-blur' },
    { name: 'Color', property: 'text-shadow-color' },
  ],
};

export const setPageWrapperSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: [
        'background-color',
        'font-family',
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'line-height',
        'text-align',
        'padding',
        'border',
      ],
      properties: [textAlignProperty, fontFamilyProperty],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setWrapperSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background-color'],
    },
  ]);
};

export const setVideoSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['width', 'padding', 'margin'],
      properties: [widthFullProperty],
    },
  ]);
};

export const setImageTextSector = (editor) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['font-family', 'font-size', 'color', 'text-align'],
      properties: [textAlignProperty],
    },
  ]);
};

export const setColumnSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background-color'],
      properties: [verticalAlignProperty],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setImageBlockSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background', 'opacity'],
      properties: [opacityProperty],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setImageSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['float', 'opacity'],
      properties: [opacityProperty, floatProperty],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setSectionSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: ['background-color'],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setIconSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      properties: [floatProperty],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setTextSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: [
        'font-family',
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'visibility',
        'text-align',
        'text-decoration',
        'text-shadow',
      ],
      properties: [
        colorProperty,
        visibilityProperty,
        customLineHeightProperty,
        textAlignProperty,
        fontFamilyProperty,
        textDecorationProperty,
        textShadowProperty,
      ],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};

export const setButtonSector = (editor, advanced) => {
  editor.StyleManager.getSectors().reset([
    {
      name: 'Quick Styles & Settings',
      id: 'quickSector',
      open: true,
      buildProps: [
        'background-color',
        'float',
        'font-family',
        'font-size',
        'font-weight',
        'letter-spacing',
        'color',
        'visibility',
        'text-align',
        'text-decoration',
        'text-shadow',
      ],
      properties: [
        customLineHeightProperty,
        visibilityProperty,
        floatProperty,
        fontFamilyProperty,
        {
          ...textAlignProperty,
          defaults: 'left',
        },
        textDecorationProperty,
        textShadowProperty,
      ],
    },
    ...(advanced === 'true' ? editor.StyleManager.getConfig().sectors : []),
  ]);
};
