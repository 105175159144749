import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useController, useForm } from 'react-hook-form';
import {
  buildQueryString,
  isStringWithSpecialSigns,
  showErrorMessage,
} from 'helpers';
import { isAdminSelector } from 'store/selectors';
import { useQuery } from 'hooks';
import {
  createTemplate,
  createSystemTemplate,
  getSystemTemplates,
  changeStatusTemplate,
} from 'store/actions';
import { CATEGORY_GROUPS, TEMPLATE_STATUS } from 'appConstants';
import Button from '../../Button';
import TextField from '../../Forms/TextField';
import CheckBox from '../../Forms/CheckBox';
import LoadingSpinner from '../../LoadingSpinner';
import TemplateLayout from './TemplateLayout/TemplateLayout';
import './NewTemplateModal.sass';

const messages = defineMessages({
  nameValidation: { id: 'general-error.name-validation' },
  descriptionValidation: { id: 'general-error.description-validation' },
  maxLenExceededValidation: {
    id: 'general-error.max-len-exceeded-validation',
  },
  nameEmptyError: { id: 'general-error.name-empty' },
  namePlaceholder: { id: 'new-template-modal.title-placeholder' },
  nameLabel: { id: 'new-template-modal.draft-title-label' },
  tagsPlaceholder: { id: 'new-template-modal.tags-placeholder' },
  tagsLabel: { id: 'new-template-modal.draft-tags-label' },
  systemLabel: { id: 'new-template-modal.system-label' },
  descriptionLabel: { id: 'new-template-modal.draft-desc-label' },
  errorMessage: {
    id: 'toast.error-message',
  },
});

const NewTemplateModal = ({ onClose }) => {
  const intl = useIntl();

  const { embed, advanced } = useQuery();

  const dispatch = useDispatch();

  const isAdmin = useSelector(isAdminSelector);

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    field: { value: id, onChange: onIdChange },
  } = useController({
    name: 'id',
    defaultValue: '',
    control,
  });
  const {
    field: { value: isSystemValue, onChange: onIsSystemChange },
  } = useController({
    name: 'isSystem',
    defaultValue: false,
    control,
  });

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [layoutTemplates, setLayoutTemplates] = useState([]);
  const [isLayoutTemplatesLoading, setIsLayoutTemplatesLoading] =
    useState(false);
  const [activeTab, setActiveTab] = useState('basic');

  const history = useHistory();

  useEffect(() => {
    setIsLayoutTemplatesLoading(true);
    dispatch(getSystemTemplates({ group: CATEGORY_GROUPS.LAYOUTS }))
      .unwrap()
      .then(({ data: { template } }) => {
        const blankTemplate = template.find(
          (item) => item.name.toLowerCase() === 'blank',
        );

        // checking whether api have blank layout template or not.
        // In case it doesn't exit adding hardcoded one
        if (!blankTemplate) {
          template = [
            ...template,
            {
              id: 'blank',
              name: 'Blank',
              description: '',
              lastModified: 0,
              categories: [
                {
                  name: 'Layouts',
                  group: 'layouts',
                },
              ],
            },
          ];
        }

        template.sort(function (a, b) {
          if (a.name === b.name) return 0;
          if (a.name === 'Blank') return -1;
          if (b.name === 'Blank') return 1;

          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });

        setLayoutTemplates(template);
      })
      .catch(() => {})
      .finally(() => {
        setIsLayoutTemplatesLoading(false);
      });

    return () => {
      setLayoutTemplates(null);
    };
  }, [dispatch, intl]);

  const handleCreate = async ({ isSystem, ...values }) => {
    const createRequest = isSystem ? createSystemTemplate : createTemplate;

    setIsSubmitLoading(true);

    try {
      const { templateId: id } = await dispatch(
        createRequest({ payload: values }),
      ).unwrap();

      if (isSystem && id) {
        try {
          await dispatch(
            changeStatusTemplate({
              id,
              status: TEMPLATE_STATUS.PUBLISHED,
            }),
          ).unwrap();
        } catch {
          showErrorMessage(intl.formatMessage(messages.errorMessage));
        }
      }

      setIsSubmitLoading(false);
      onClose();

      history.push({
        pathname: `/edit/${id}`,
        search: buildQueryString({
          embed,
          advanced,
          system: isSystem ? true : null,
        }),
      });
    } catch {
      setIsSubmitLoading(false);
    }
  };

  const featuredTemplates = layoutTemplates
    .filter((item) =>
      item.categories.some((item) => item.name === 'Featured Layouts'),
    )
    .map((item) => (
      <TemplateLayout
        classes={cx('new-template featured-template', {
          active: item.id === id,
        })}
        key={item.id}
        clickHandler={() => onIdChange(item.id)}
        name={item.name}
        templateId={item.id}
        lastModified={item.lastModified}
        description={item.description}
      />
    ));

  const simpleTemplates = layoutTemplates
    .filter((item) => item.categories.some((item) => item.name === 'Layouts'))
    .map((item, index, array) => {
      if (!id && array.length > 0 && index === 1) {
        onIdChange(item.id);
      }

      if (!id && array.length === 1 && index === 0) {
        onIdChange(item.id);
      }

      return (
        <TemplateLayout
          classes={cx('new-template basic-template', {
            active:
              (!id && array.length > 0 && index === 1) ||
              (!id && array.length === 1 && index === 0) ||
              item.id === id,
          })}
          key={item.id}
          clickHandler={() => onIdChange(item.id)}
          name={item.name}
          templateId={item.id}
          lastModified={item.lastModified}
          description={item.description}
        />
      );
    });

  const handleActiveTab = (e, tab) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  return (
    <div id="new-template" className="new-template-modal">
      <form onSubmit={handleSubmit(handleCreate)}>
        <div className="template-modal">
          <div className="template-modal__title">
            {simpleTemplates.length > 0 && (
              <button
                className={cx({ 'active-tab': activeTab === 'basic' })}
                onClick={(e) => handleActiveTab(e, 'basic')}>
                <FormattedMessage id="new-template-modal.basic-layout-choose" />
              </button>
            )}
            {featuredTemplates.length > 0 && (
              <button
                className={cx({ 'active-tab': activeTab === 'featured' })}
                onClick={(e) => handleActiveTab(e, 'featured')}>
                <FormattedMessage id="new-template-modal.featured-layout-choose" />
              </button>
            )}
          </div>
          <div className="template-modal__container-scroll">
            <div className="template-modal__container">
              {isLayoutTemplatesLoading ? (
                <LoadingSpinner className="template-modal__loading-spinner" />
              ) : (
                <>
                  {activeTab === 'basic' && simpleTemplates.length > 0 && (
                    <div className="new-template-modal__templates new-template-modal__basic">
                      {simpleTemplates}
                    </div>
                  )}
                  {activeTab === 'featured' && featuredTemplates.length > 0 && (
                    <div className="new-template-modal__templates new-template-modal__featured">
                      {featuredTemplates}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="template-modal__info">
          <div className="template-modal__info__options">
            <div className="template-modal__info__options__main">
              <TextField
                {...register('name', {
                  required: intl.formatMessage(messages.nameEmptyError),
                  maxLength: {
                    value: 255,
                    message: intl.formatMessage(
                      messages.maxLenExceededValidation,
                    ),
                  },
                  validate: (value) => {
                    if (isStringWithSpecialSigns(value)) {
                      return intl.formatMessage(messages.nameValidation);
                    }
                  },
                })}
                maxLength={255}
                fullWidth
                error={Boolean(errors.name)}
                placeholder={intl.formatMessage(messages.namePlaceholder)}
                label={intl.formatMessage(messages.nameLabel)}
                helperText={errors.name?.message}
              />
              <TextField
                {...register('tags')}
                fullWidth
                placeholder={intl.formatMessage(messages.tagsPlaceholder)}
                label={intl.formatMessage(messages.tagsLabel)}
              />
              <TextField
                {...register('description', {
                  maxLength: {
                    value: 70,
                    message: intl.formatMessage(
                      messages.maxLenExceededValidation,
                    ),
                  },
                  validate: (value) => {
                    if (value.length > 0 && isStringWithSpecialSigns(value)) {
                      return intl.formatMessage(messages.descriptionValidation);
                    }
                  },
                })}
                maxLength={70}
                fullWidth
                label={intl.formatMessage(messages.descriptionLabel)}
                error={Boolean(errors.description)}
                helperText={errors.description?.message}
              />
            </div>
            {isAdmin && (
              <div className="new-template-modal__create-system-template">
                <CheckBox
                  data-testid="create-system-template"
                  label={intl.formatMessage(messages.systemLabel)}
                  fullWidth
                  checked={isSystemValue}
                  onChange={(e) => onIsSystemChange(e.target.checked)}
                />
              </div>
            )}
          </div>
          <div className="template-modal__info-btns">
            <Button type="button" onClick={onClose}>
              <FormattedMessage id="new-template-modal.cancel" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitLoading}
              loading={isSubmitLoading}>
              <FormattedMessage id="new-template-modal.create" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

NewTemplateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default NewTemplateModal;
