import { showErrorMessage, showWarningMessage } from 'helpers';

export default (editor) => {
  editor.on('load', () => {
    const wrapper = editor.getWrapper();

    wrapper.set('attributes', { id: 'grapes-iframe-body' });
  });

  editor.on('change:changesCount', () => {
    const wrapper = editor.getWrapper();

    if (wrapper.findType('root').length) {
      wrapper.set({ droppable: false });
      return;
    }

    wrapper.set({ droppable: true });
  });

  editor.on('sorter:drag:end', (targetCollection) => {
    const wrapper = editor.getWrapper();
    const targetType = targetCollection.validResult.srcModel.get('type');

    let formDatatableCounter = 0;

    // Stop dragging more than one Form Data Table block
    for (const model of wrapper.findType('form-data-table')) {
      formDatatableCounter++;

      if (formDatatableCounter > 1) {
        showWarningMessage(
          'Each template can only contain one "Form Data Table" block',
        );
        model.remove();

        break;
      }
    }

    if (
      targetType === 'root' &&
      wrapper.findType('root').length === 1 &&
      targetCollection.warns.length > 1
    ) {
      showWarningMessage(
        'You cannot drop more than one page wrapper on canvas',
      );

      return;
    }

    if (wrapper.findType('root').length && targetCollection.warns.length > 1) {
      showWarningMessage('This block can only be dropped in the page wrapper');
    }

    return;
  });

  editor.on('canvas:drop', (_dataTransfer, model) => {
    const wrapper = editor.getWrapper();

    const isModelPageWrapper = model.get('type') === 'root';
    let pageWrapperLength = wrapper.findType('root').length;

    // updating pageWrapperLength because when user drop another one
    // it increases length although it should be removed
    if (isModelPageWrapper) {
      pageWrapperLength -= 1;
    }

    // if user drops page wrapper on canvas where there is already page wrapper
    // show error
    if (isModelPageWrapper && pageWrapperLength) {
      showErrorMessage('You cannot drop more than one page wrapper on canvas');

      model.remove();

      return;
    }
  });
};
