export const DEFAULT_USERS = [
  {
    name: 'Product Owner Test Account',
    email: 'potests@amdtelecom.net',
    role: 'admin',
    image: 'https://cdn11.waymore.io/dnd/images/img_avatar_1.png',
    id: 6997,
    sessionId: 'session_id',
  },
  {
    name: 'Andreas Sidiropoulos',
    email: 'asidiropoulos@waymore.io',
    role: 'admin',
    image: 'https://cdn11.waymore.io/dnd/images/img_avatar_2.png',
    id: 16090,
    sessionId: 'session_id2',
  },
  {
    email: 'gkrousiarlis@waymore.io',
    id: '26728',
    image: 'https://cdn11.waymore.io/dnd/images/img_avatar_3.png',
    name: 'Grigorios Krousiarlis',
    role: 'user',
    sessionId: 'session_id3',
  },
];

export const CUSTOM_MODULES_SELECTED_CATEGORIES = [
  'headers',
  'preheaders',
  'banners',
  'content',
  'set of blog posts',
  'blog post',
  'products',
  'footers',
];

export const LOAD_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

export const USER_GUIDE_KEYS = {
  DASHBOARD_TOUR: 'isDashboardTourFinished',
  EDITOR_TOUR: 'isEditorTourFinished',
};

export const USER_ROLES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const CATEGORY_GROUPS = {
  SYSTEM: 'system',
  LAYOUTS: 'layouts',
};

export const SOCIAL_STYLES = [
  'circle-black-bordered',
  'circle-black',
  'circle-colored',
  'circle-gray-bordered',
  'circle-gray',
  'circle-white-bordered',
  'circle-white',
  'logo-black',
  'logo-colored-bordered',
  'logo-colored',
  'logo-gray',
  'logo-white',
  'rounded-black-bordered',
  'rounded-black',
  'rounded-colored-bordered',
  'rounded-colored',
  'rounded-gray-bordered',
  'rounded-gray',
  'rounded-white-bordered',
  'rounded-white',
  'square-black-bordered',
  'square-black',
  'square-colored-bordered',
  'square-colored',
  'square-gray-bordered',
  'square-gray',
  'square-white-bordered',
  'square-white',
];

export const SOCIAL_PREVIEW_ICONS = [
  { iconName: 'preview-32-logo-colored', iconTitle: 'Colored logos' },
  { iconName: 'preview-32-logo-black', iconTitle: 'Black logos' },
  { iconName: 'preview-32-logo-gray', iconTitle: 'Gray logos' },
  { iconName: 'preview-32-logo-white', iconTitle: 'White logos' },
  { iconName: 'preview-32-circle-colored', iconTitle: 'Circle color logos' },
  {
    iconName: 'preview-32-circle-colored-bordered',
    iconTitle: 'Circle color with border',
  },
  { iconName: 'preview-32-rounded-colored', iconTitle: 'Rounded color logos' },
  {
    iconName: 'preview-32-rounded-colored-bordered',
    iconTitle: 'Rounded color with border',
  },
  { iconName: 'preview-32-square-colored', iconTitle: 'Square color logos' },
  {
    iconName: 'preview-32-square-colored-bordered',
    iconTitle: 'Square color with border',
  },
  { iconName: 'preview-32-circle-black', iconTitle: 'Circle black logos' },
  {
    iconName: 'preview-32-circle-black-bordered',
    iconTitle: 'Circle black with border',
  },
  { iconName: 'preview-32-rounded-black', iconTitle: 'Rounded black logos' },
  {
    iconName: 'preview-32-rounded-black-bordered',
    iconTitle: 'Rounded black with border',
  },
  { iconName: 'preview-32-square-black', iconTitle: 'Square black logos' },
  {
    iconName: 'preview-32-square-black-bordered',
    iconTitle: 'Square black with border',
  },
  { iconName: 'preview-32-circle-gray', iconTitle: 'Circle gray logos' },
  {
    iconName: 'preview-32-circle-gray-bordered',
    iconTitle: 'Circle gray with border',
  },
  { iconName: 'preview-32-rounded-gray', iconTitle: 'Rounded gray logos' },
  {
    iconName: 'preview-32-rounded-gray-bordered',
    iconTitle: 'Rounded gray with border',
  },
  { iconName: 'preview-32-square-gray', iconTitle: 'Square gray logos' },
  {
    iconName: 'preview-32-square-gray-bordered',
    iconTitle: 'Square gray with border',
  },
  { iconName: 'preview-32-circle-white', iconTitle: 'Circle white logos' },
  {
    iconName: 'preview-32-circle-white-bordered',
    iconTitle: 'Circle white with border',
  },
  { iconName: 'preview-32-rounded-white', iconTitle: 'Rounded white logos' },
  {
    iconName: 'preview-32-rounded-white-bordered',
    iconTitle: 'Rounded white with border',
  },
  { iconName: 'preview-32-square-white', iconTitle: 'Square white logos' },
  {
    iconName: 'preview-32-square-white-bordered',
    iconTitle: 'Square white with border',
  },
];

export const SOCIAL_SOCIAL_ICONS = [
  { iconName: 'askfm', iconTitle: '' },
  { iconName: 'behance', iconTitle: '' },
  { iconName: 'dribbble', iconTitle: '' },
  { iconName: 'facebook', iconTitle: '' },
  { iconName: 'flickr', iconTitle: '' },
  { iconName: 'foursquare', iconTitle: '' },
  { iconName: 'fleeped', iconTitle: '' },
  { iconName: 'glassdoor', iconTitle: '' },
  { iconName: 'instagram', iconTitle: '' },
  { iconName: 'itunespodcasts', iconTitle: '' },
  { iconName: 'lastfm', iconTitle: '' },
  { iconName: 'linkedin', iconTitle: '' },
  { iconName: 'myspace', iconTitle: '' },
  { iconName: 'pinterest', iconTitle: '' },
  { iconName: 'soundcloud', iconTitle: '' },
  { iconName: 'spotify', iconTitle: '' },
  { iconName: 'tiktok', iconTitle: '' },
  { iconName: 'tumblr', iconTitle: '' },
  { iconName: 'x', iconTitle: '' },
  { iconName: 'vimeo', iconTitle: '' },
  { iconName: 'weibo', iconTitle: '' },
  { iconName: 'youtube', iconTitle: '' },
  { iconName: 'xing', iconTitle: '' },
  { iconName: 'meetup', iconTitle: '' },
  { iconName: 'threads', iconTitle: '' },
  { iconName: 'twitch', iconTitle: '' },
  { iconName: 'yammer', iconTitle: '' },
  { iconName: 'reddit', iconTitle: '' },
  { iconName: 'strava', iconTitle: '' },
  { iconName: 'goodreads', iconTitle: '' },
  { iconName: 'yelp', iconTitle: '' },
  { iconName: 'mastodon', iconTitle: '' },
];

export const SOCIAL_MESSENGER_ICONS = [
  { iconName: 'fbwokplace', iconTitle: '' },
  { iconName: 'hangouts', iconTitle: '' },
  { iconName: 'messenger', iconTitle: '' },
  { iconName: 'skype', iconTitle: '' },
  { iconName: 'snapchat', iconTitle: '' },
  { iconName: 'telegram', iconTitle: '' },
  { iconName: 'viber', iconTitle: '' },
  { iconName: 'wechat', iconTitle: '' },
  { iconName: 'whatsapp', iconTitle: '' },
  { iconName: 'slack', iconTitle: '' },
  { iconName: 'zoom', iconTitle: '' },
  { iconName: 'teams', iconTitle: '' },
  { iconName: 'discort', iconTitle: '' },
  { iconName: 'line', iconTitle: '' },
];

export const SOCIAL_OTHER_ICONS = [
  { iconName: 'blogger', iconTitle: '' },
  { iconName: 'mail', iconTitle: '' },
  { iconName: 'link', iconTitle: '' },
  { iconName: 'location', iconTitle: '' },
  { iconName: 'globe', iconTitle: '' },
  { iconName: 'gmail', iconTitle: '' },
  { iconName: 'address', iconTitle: '' },
  { iconName: 'phone', iconTitle: '' },
  { iconName: 'share', iconTitle: '' },
  { iconName: 'tripadvisor', iconTitle: '' },
  { iconName: 'rss', iconTitle: '' },
  { iconName: 'apple-store', iconTitle: '' },
  { iconName: 'playmarket', iconTitle: '' },
  { iconName: 'windows-store', iconTitle: '' },
  { iconName: 'medium', iconTitle: '' },
  { iconName: 'dropbox', iconTitle: '' },
  { iconName: 'googledrive', iconTitle: '' },
  { iconName: 'github', iconTitle: '' },
  { iconName: 'pdf', iconTitle: '' },
  { iconName: 'doc', iconTitle: '' },
  { iconName: 'xls', iconTitle: '' },
  { iconName: 'ppt', iconTitle: '' },
  { iconName: 'onedrive', iconTitle: '' },
  { iconName: 'patreon', iconTitle: '' },
  { iconName: 'ko-fi', iconTitle: '' },
  { iconName: 'buymeacoffee', iconTitle: '' },
  { iconName: 'appgallery', iconTitle: '' },
  { iconName: 'googlebusiness', iconTitle: '' },
  { iconName: 'google', iconTitle: '' },
];

export const TIME_ZONE = [
  {
    name: '(UTC-11:00) Pacific/Pago_Pago',
    value: '-11:00',
  },
  {
    name: '(UTC-11:00) Pacific/Niue',
    value: '-11:00',
  },
  {
    name: '(UTC-11:00) Pacific/Midway',
    value: '-11:00',
  },
  {
    name: '(UTC-10:00) Pacific/Honolulu',
    value: '-10:00',
  },
  {
    name: '(UTC-10:00) Pacific/Tahiti',
    value: '-10:00',
  },
  {
    name: '(UTC-10:00) Pacific/Rarotonga',
    value: '-10:00',
  },
  {
    name: '(UTC-10:00) US/Hawaii',
    value: '-10:00',
  },
  {
    name: '(UTC-09:30) Pacific/Marquesas',
    value: '-09:30',
  },
  {
    name: '(UTC-09:00) America/Adak',
    value: '-09:00',
  },
  {
    name: '(UTC-09:00) Pacific/Gambier',
    value: '-09:00',
  },
  {
    name: '(UTC-08:00) America/Yakutat',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Anchorage',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Sitka',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) US/Alaska',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Nome',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Metlakatla',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) America/Juneau',
    value: '-08:00',
  },
  {
    name: '(UTC-08:00) Pacific/Pitcairn',
    value: '-08:00',
  },
  {
    name: '(UTC-07:00) America/Whitehorse',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) Canada/Pacific',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Dawson',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Tijuana',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) US/Pacific',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Los_Angeles',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Vancouver',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Creston',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Hermosillo',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Dawson_Creek',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Fort_Nelson',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) US/Arizona',
    value: '-07:00',
  },
  {
    name: '(UTC-07:00) America/Phoenix',
    value: '-07:00',
  },
  {
    name: '(UTC-06:00) America/Cambridge_Bay',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Ojinaga',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Chihuahua',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Denver',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) Canada/Mountain',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Edmonton',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Mazatlan',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Yellowknife',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Inuvik',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Boise',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) US/Mountain',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Tegucigalpa',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Costa_Rica',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Regina',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Belize',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/El_Salvador',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Swift_Current',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Guatemala',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) America/Managua',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) Pacific/Galapagos',
    value: '-06:00',
  },
  {
    name: '(UTC-06:00) Pacific/Easter',
    value: '-06:00',
  },
  {
    name: '(UTC-05:00) Canada/Central',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/North_Dakota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Resolute',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Bahia_Banderas',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Monterrey',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Rankin_Inlet',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Mexico_City',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Rainy_River',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Merida',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Menominee',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Winnipeg',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Matamoros',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) US/Central',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/North_Dakota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/North_Dakota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Indiana',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Indiana',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Chicago',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Cayman',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Jamaica',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Lima',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Guayaquil',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Rio_Branco',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Atikokan',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Bogota',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Panama',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Cancun',
    value: '-05:00',
  },
  {
    name: '(UTC-05:00) America/Eirunepe',
    value: '-05:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Havana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Iqaluit',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) US/Eastern',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Kentucky',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Kentucky',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Toronto',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Thunder_Bay',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) Canada/Eastern',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Port',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Pangnirtung',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Detroit',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Nipigon',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/New_York',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Nassau',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Indiana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Montserrat',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Curacao',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Caracas',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Grand_Turk',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Grenada',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Guadeloupe',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Martinique',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Port_of_Spain',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Porto_Velho',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Puerto_Rico',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Boa_Vista',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Blanc',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Barbados',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Marigot',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Manaus',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Dominica',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Aruba',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Antigua',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Anguilla',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Barthelemy',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Kitts',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Lucia',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Thomas',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/St_Vincent',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Lower_Princes',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Guyana',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Kralendijk',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/La_Paz',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Tortola',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Santo_Domingo',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Cuiaba',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Asuncion',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Santiago',
    value: '-04:00',
  },
  {
    name: '(UTC-04:00) America/Campo_Grande',
    value: '-04:00',
  },
  {
    name: '(UTC-03:00) America/Moncton',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Atlantic/Bermuda',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Glace_Bay',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Goose_Bay',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Halifax',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Canada/Atlantic',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Thule',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Punta_Arenas',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Atlantic/Stanley',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Recife',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Antarctica/Palmer',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Bahia',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Santarem',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) Antarctica/Rothera',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Araguaina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Belem',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Montevideo',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Fortaleza',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Cayenne',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Paramaribo',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Maceio',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Argentina',
    value: '-03:00',
  },
  {
    name: '(UTC-03:00) America/Sao_Paulo',
    value: '-03:00',
  },
  {
    name: '(UTC-02:30) Canada/Newfoundland',
    value: '-02:30',
  },
  {
    name: '(UTC-02:30) America/St_Johns',
    value: '-02:30',
  },
  {
    name: '(UTC-02:00) America/Miquelon',
    value: '-02:00',
  },
  {
    name: '(UTC-02:00) America/Godthab',
    value: '-02:00',
  },
  {
    name: '(UTC-02:00) America/Noronha',
    value: '-02:00',
  },
  {
    name: '(UTC-02:00) Atlantic/South_Georgia',
    value: '-02:00',
  },
  {
    name: '(UTC-01:00) Atlantic/Cape_Verde',
    value: '-01:00',
  },
  {
    name: '(UTC+00:00) America/Scoresbysund',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Atlantic/Azores',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Abidjan',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Atlantic/Reykjavik',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Banjul',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) GMT</',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Lome',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Bissau',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Freetown',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Bamako',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Monrovia',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Conakry',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) America/Danmarkshavn',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Dakar',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Atlantic/St_Helena',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Nouakchott',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Ouagadougou',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Accra',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) UTC</',
    value: '%2B00:00',
  },
  {
    name: '(UTC+00:00) Africa/Sao_Tome',
    value: '%2B00:00',
  },
  {
    name: '(UTC+01:00) Atlantic/Madeira',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Atlantic/Faroe',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Europe/London',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Europe/Lisbon',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Europe/Jersey',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Europe/Isle_of_Man',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Europe/Guernsey',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Europe/Dublin',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/El_Aaiun',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Casablanca',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Atlantic/Canary',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Bangui',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Brazzaville',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Douala',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Algiers',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Kinshasa',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Lagos',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Libreville',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Luanda',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Tunis',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Porto',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Niamey',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Ndjamena',
    value: '%2B01:00',
  },
  {
    name: '(UTC+01:00) Africa/Malabo',
    value: '%2B01:00',
  },
  {
    name: '(UTC+02:00) Antarctica/Troll',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Malta',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Prague',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Ceuta',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Zurich',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Zagreb',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Warsaw',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Vienna',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Vatican',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Vaduz',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Tirane',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Stockholm',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Skopje',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Sarajevo',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/San_Marino',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Rome',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Arctic/Longyearbyen',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Podgorica',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Paris',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Oslo',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Monaco',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Madrid',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Luxembourg',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Ljubljana',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Gibraltar',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Copenhagen',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Busingen',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Budapest',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Brussels',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Bratislava',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Berlin',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Belgrade',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Andorra',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Amsterdam',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Bujumbura',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Cairo',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Mbabane',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Europe/Kaliningrad',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Blantyre',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Lubumbashi',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Gaborone',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Lusaka',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Harare',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Johannesburg',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Khartoum',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Windhoek',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Tripoli',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Kigali',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Maputo',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Maseru',
    value: '%2B02:00',
  },
  {
    name: '(UTC+02:00) Africa/Juba',
    value: '%2B02:00',
  },
  {
    name: '(UTC+03:00) Europe/Mariehamn',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Jerusalem',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Vilnius',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Beirut',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Kyiv',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Uzhgorod',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Helsinki',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Tallinn',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Chisinau',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Sofia',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Bucharest',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Damascus',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Nicosia',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Famagusta',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Riga',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Gaza',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Hebron',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Athens',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Amman',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Zaporizhia',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Antarctica/Syowa',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Bahrain',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Riyadh',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Minsk',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Addis_Ababa',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Kuwait',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Dar_es_Salaam',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Simferopol',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Kampala',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Djibouti',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Kirov',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Volgograd',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Indian/Mayotte',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Indian/Comoro',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Istanbul',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Baghdad',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Asmara',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Aden',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Indian/Antananarivo',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Europe/Moscow',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Asia/Qatar',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Mogadishu',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:00) Africa/Nairobi',
    value: '%2B03:00',
  },
  {
    name: '(UTC+03:30) Asia/Tehran',
    value: '%2B03:30',
  },
  {
    name: '(UTC+04:00) Europe/Ulyanovsk',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Indian/Reunion',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Asia/Dubai',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Indian/Mauritius',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Indian/Mahe',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Asia/Baku',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Europe/Saratov',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Asia/Muscat',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Europe/Samara',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Asia/Tbilisi',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Europe/Astrakhan',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:00) Asia/Yerevan',
    value: '%2B04:00',
  },
  {
    name: '(UTC+04:30) Asia/Kabul',
    value: '%2B04:30',
  },
  {
    name: '(UTC+05:00) Asia/Tashkent',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Oral',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Karachi',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Aqtau',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Aqtobe',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Ashgabat',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Atyrau',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Antarctica/Mawson',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Yekaterinburg',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Dushanbe',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Indian/Maldives',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Indian/Kerguelen',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Samarkand',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:00) Asia/Qyzylorda',
    value: '%2B05:00',
  },
  {
    name: '(UTC+05:30) Asia/Colombo',
    value: '%2B05:30',
  },
  {
    name: '(UTC+05:30) Asia/Kolkata',
    value: '%2B05:30',
  },
  {
    name: '(UTC+05:45) Asia/Kathmandu',
    value: '%2B05:45',
  },
  {
    name: '(UTC+06:00) Indian/Chagos',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Asia/Thimphu',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Asia/Urumqi',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Antarctica/Vostok',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Asia/Almaty',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Asia/Dhaka',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Asia/Omsk',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:00) Asia/Bishkek',
    value: '%2B06:00',
  },
  {
    name: '(UTC+06:30) Indian/Cocos',
    value: '%2B06:30',
  },
  {
    name: '(UTC+06:30) Asia/Yangon',
    value: '%2B06:30',
  },
  {
    name: '(UTC+07:00) Asia/Pontianak',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Phnom_Penh',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Tomsk',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Vientiane',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Novosibirsk',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Novokuznetsk',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Indian/Christmas',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Ho_Chi_Minh',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Hovd',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Jakarta',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Antarctica/Davis',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Bangkok',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Barnaul',
    value: '%2B07:00',
  },
  {
    name: '(UTC+07:00) Asia/Krasnoyarsk',
    value: '%2B07:00',
  },
  {
    name: '(UTC+08:00) Asia/Kuala_Lumpur',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Australia/Perth',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Ulaanbaatar',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Macau',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Taipei',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Brunei',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Makassar',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Choibalsan',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Singapore',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Kuching',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Shanghai',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Irkutsk',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Manila',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:00) Asia/Hong_Kong',
    value: '%2B08:00',
  },
  {
    name: '(UTC+08:45) Australia/Eucla',
    value: '%2B08:45',
  },
  {
    name: '(UTC+09:00) Asia/Pyongyang',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Tokyo',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Pacific/Palau',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Khandyga',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Yakutsk',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Chita',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Jayapura',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Dili',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:00) Asia/Seoul',
    value: '%2B09:00',
  },
  {
    name: '(UTC+09:30) Australia/Darwin',
    value: '%2B09:30',
  },
  {
    name: '(UTC+09:30) Australia/Broken_Hill',
    value: '%2B09:30',
  },
  {
    name: '(UTC+09:30) Australia/Adelaide',
    value: '%2B09:30',
  },
  {
    name: '(UTC+10:00) Pacific/Guam',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Pacific/Saipan',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Antarctica/DumontDUrville',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Asia/Ust',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Asia/Vladivostok',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Pacific/Chuuk',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Australia/Lindeman',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Australia/Brisbane',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Pacific/Port_Moresby',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Australia/Sydney',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Australia/Melbourne',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Australia/Currie',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Australia/Hobart',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:00) Antarctica/Macquarie',
    value: '%2B10:00',
  },
  {
    name: '(UTC+10:30) Australia/Lord_Howe',
    value: '%2B10:30',
  },
  {
    name: '(UTC+11:00) Asia/Srednekolymsk',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Guadalcanal',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Bougainville',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Kosrae',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Pohnpei',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Efate',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Asia/Magadan',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Asia/Sakhalin',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Norfolk',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Pacific/Noumea',
    value: '%2B11:00',
  },
  {
    name: '(UTC+11:00) Antarctica/Casey',
    value: '%2B11:00',
  },
  {
    name: '(UTC+12:00) Pacific/Wallis',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Nauru',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Kwajalein',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Asia/Anadyr',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Asia/Kamchatka',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Majuro',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Tarawa',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Wake',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Funafuti',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Fiji',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Pacific/Auckland',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:00) Antarctica/McMurdo',
    value: '%2B12:00',
  },
  {
    name: '(UTC+12:45) Pacific/Chatham',
    value: '%2B12:45',
  },
  {
    name: '(UTC+13:00) Pacific/Tongatapu',
    value: '%2B13:00',
  },
  {
    name: '(UTC+13:00) Pacific/Fakaofo',
    value: '%2B13:00',
  },
  {
    name: '(UTC+13:00) Pacific/Enderbury',
    value: '%2B13:00',
  },
  {
    name: '(UTC+13:00) Pacific/Apia',
    value: '%2B13:00',
  },
  {
    name: '(UTC+14:00) Pacific/Kiritimati',
    value: '%2B14:00',
  },
];
