import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isAdminSelector } from 'store/selectors';
import { SORTBYLIST, TEMPLATE_STATUS } from 'appConstants';
import { useQuery } from 'hooks';
import Button from 'components/Button';
import TextField from 'components/Forms/TextField';
import Select from 'components/Forms/Select';
import BulkActions from './BulkActions/BulkActions';
import TemplatesView from './TemplatesView/TemplatesView';
import './Filters.sass';

const messages = defineMessages({
  searchInputLabel: { id: 'template.search-label' },
  searchInputPlaceholder: { id: 'template.search-input-placeholder' },
  systemSearchInputPlaceholder: {
    id: 'template.system-search-input-placeholder',
  },
  sortByPlaceholder: { id: 'template.sortBy' },
  filtersUserTemplatesTitle: { id: 'editor-header.user-templates-title' },
  filtersUserSharedTemplatesTitle: {
    id: 'editor-header.user-shared-templates-title',
  },
  filtersUserFavoritedTemplatesTitle: {
    id: 'editor-header.user-favorited-templates-title',
  },
  filtersSystemTemplatesTitle: { id: 'editor-header.system-templates-title' },
});

const Filters = ({
  status,
  sortBy,
  searchTerm,
  onChange,
  isSystem,
  onOpenNewTemplateModal,
  numberRemovedItems,
  onRemoveAllClick,
  bulkActionTemplatesList,
  setBulkActionTemplatesList,
  bulkAction,
  setBulkAction,
  templatesView,
  setTemplatesView,
  handleBulkUnpublishTemplatesClick,
  handleBulkSoftDeleteTemplatesClick,
  handleBulkHardDeleteTemplatesClick,
  handleBulkSoftDeleteSystemTemplatesClick,
  handleBulkHardDeleteSystemTemplatesClick,
}) => {
  const { system_templates, status: queryStatus } = useQuery();
  const intl = useIntl();
  const searchInputRef = useRef(null);
  const isAdmin = useSelector(isAdminSelector);

  return (
    <div className="templates-filters">
      <div className="templates-filters__title">
        {queryStatus === 'shared' && (
          <span className="icon icon-folder-shared" />
        )}
        {queryStatus === 'favorited' && (
          <span className="icon icon-folder-special" />
        )}
        {intl.formatMessage(
          isSystem || system_templates === 'true'
            ? messages.filtersSystemTemplatesTitle
            : queryStatus === 'favorited'
            ? messages.filtersUserFavoritedTemplatesTitle
            : queryStatus === 'shared'
            ? messages.filtersUserSharedTemplatesTitle
            : messages.filtersUserTemplatesTitle,
        )}
      </div>
      {queryStatus !== 'shared' && queryStatus !== 'favorited' && (
        <div className="templates-filters__inner">
          <div className="templates-filters__actions">
            {!bulkAction && (
              <Select
                className="templates-filters__actions__sort"
                label={intl.formatMessage(messages.sortByPlaceholder)}
                name="sortBy"
                value={sortBy}
                onChange={onChange}>
                {(status === TEMPLATE_STATUS.DRAFT
                  ? [...SORTBYLIST, 'isShared']
                  : SORTBYLIST
                ).map((value, i) => (
                  <FormattedMessage id={'sort.' + value} key={i}>
                    {(message) => <option value={value}>{message}</option>}
                  </FormattedMessage>
                ))}
              </Select>
            )}
            {!isAdmin && isSystem ? (
              '' // hide BulkActions for normal users to access system templates
            ) : (
              <BulkActions
                status={status}
                isSystem={isSystem || system_templates === 'true'}
                bulkActionTemplatesList={bulkActionTemplatesList}
                setBulkActionTemplatesList={setBulkActionTemplatesList}
                bulkAction={bulkAction}
                setBulkAction={setBulkAction}
                handleBulkUnpublishTemplatesClick={
                  handleBulkUnpublishTemplatesClick
                }
                handleBulkSoftDeleteTemplatesClick={
                  handleBulkSoftDeleteTemplatesClick
                }
                handleBulkHardDeleteTemplatesClick={
                  handleBulkHardDeleteTemplatesClick
                }
                handleBulkSoftDeleteSystemTemplatesClick={
                  handleBulkSoftDeleteSystemTemplatesClick
                }
                handleBulkHardDeleteSystemTemplatesClick={
                  handleBulkHardDeleteSystemTemplatesClick
                }
              />
            )}
            {!bulkAction && (
              <>
                <div className="templates-filters__actions__search-wrapper">
                  <TextField
                    className="templates-filters__actions__search"
                    placeholder={intl.formatMessage(
                      isSystem || system_templates === 'true'
                        ? messages.systemSearchInputPlaceholder
                        : messages.searchInputPlaceholder,
                    )}
                    name="searchTerm"
                    value={searchTerm}
                    onChange={onChange}
                    before={
                      <span className="icon icon-search" ref={searchInputRef} />
                    }
                  />
                  {searchInputRef && (
                    <Button
                      onClick={() => {
                        searchInputRef.current.value = '';
                        onChange({ target: { name: 'searchTerm', value: '' } });
                      }}
                      className="templates-filters__actions__clear-search icon icon-close"
                    />
                  )}
                </div>
                <TemplatesView
                  templatesView={templatesView}
                  setTemplatesView={setTemplatesView}
                />
              </>
            )}
          </div>
          {numberRemovedItems > 0 &&
          status === TEMPLATE_STATUS.DELETED &&
          system_templates !== 'true' ? (
            <Button
              className="delete-forever"
              type="button"
              variant="contained"
              onClick={onRemoveAllClick}>
              <FormattedMessage id="template.button-delete-all-forever" />
              <i className="icon icon-delete" />
            </Button>
          ) : (
            <Button
              className="create-new"
              type="button"
              onClick={onOpenNewTemplateModal}>
              <FormattedMessage id="template.button-create" />
              <i className="icon icon-add" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

Filters.propTypes = {
  status: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired,
  isSystem: PropTypes.bool.isRequired,
  onOpenNewTemplateModal: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  numberRemovedItems: PropTypes.number,
  onRemoveAllClick: PropTypes.func.isRequired,
  bulkActionTemplatesList: PropTypes.array.isRequired,
  setBulkActionTemplatesList: PropTypes.func.isRequired,
  bulkAction: PropTypes.bool.isRequired,
  setBulkAction: PropTypes.func.isRequired,
  templatesView: PropTypes.string.isRequired,
  setTemplatesView: PropTypes.func.isRequired,
  handleBulkUnpublishTemplatesClick: PropTypes.func.isRequired,
  handleBulkSoftDeleteTemplatesClick: PropTypes.func.isRequired,
  handleBulkHardDeleteTemplatesClick: PropTypes.func.isRequired,
  handleBulkSoftDeleteSystemTemplatesClick: PropTypes.func.isRequired,
  handleBulkHardDeleteSystemTemplatesClick: PropTypes.func.isRequired,
};

export default Filters;
