import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { TEMPLATE_STATUS, VERSION } from 'appConstants';
import { useModal, useQuery } from 'hooks';
import { buildQueryString } from 'helpers';
import {
  categoriesWithCountSelector,
  isAdminSelector,
  templatesNumbersSelector,
} from 'store/selectors';
import IconButton from 'components/IconButton';
import BaseModal from 'components/Modals/BaseModal';
import CategoryModal from 'components/Modals/CategoryModal';
import SidebarGroupLinks from './SidebarGroupLinks';
import SidebarLink from './SidebarLink';
import SidebarLinkCategoryButtons from './SidebarLinkCategoryButtons';
import './Sidebar.sass';

function Sidebar({
  status,
  category: categoryId,
  setBulkAction,
  setBulkActionTemplatesList,
}) {
  const isAdmin = useSelector(isAdminSelector);

  const { isOpen, open, close } = useModal();

  const { embed, advanced, system_templates } = useQuery();

  const templatesNumbers = useSelector(templatesNumbersSelector);

  let categories = useSelector(categoriesWithCountSelector);

  if (!isAdmin) {
    categories = categories.filter((item) => item.group !== 'layouts');
  }

  const publishedSearch = buildQueryString({
    status: TEMPLATE_STATUS.PUBLISHED,
    embed,
    advanced,
  });
  const draftSearch = buildQueryString({
    status: TEMPLATE_STATUS.DRAFT,
    embed,
    advanced,
  });
  const deletedSearch = buildQueryString({
    status: TEMPLATE_STATUS.DELETED,
    embed,
    advanced,
  });
  const sharedSearch = new URLSearchParams({
    status: TEMPLATE_STATUS.SHARED,
  });
  const favoritedSearch = new URLSearchParams({
    status: TEMPLATE_STATUS.FAVORITED,
  });
  const allSearch = buildQueryString({
    category: 'all',
    embed,
    advanced,
  });

  const deletedSystemTemplates = new URLSearchParams({
    system_templates: true,
    status: TEMPLATE_STATUS.DELETED,
  });

  const categoriesNames = categories.map((category) => category.name);
  const isSystem = Boolean(categoryId);

  useEffect(() => {
    setBulkAction(false);
    setBulkActionTemplatesList([]);
  }, [status, categoryId, setBulkAction, setBulkActionTemplatesList]);

  return (
    <aside id="menu" className="sidebar">
      <div className="center">
        <SidebarGroupLinks groupTitle="template.projects">
          <SidebarLink
            to={{ search: publishedSearch }}
            iconClass="icon icon-bookmark"
            after={
              <span className="sidebar__link-item__number">
                {templatesNumbers.published}
              </span>
            }
            active={
              !isSystem &&
              system_templates !== 'true' &&
              status === TEMPLATE_STATUS.PUBLISHED
            }
            hidden={templatesNumbers.published === 0}>
            <FormattedMessage id="template.published" />
          </SidebarLink>
          <SidebarLink
            to={{ search: draftSearch }}
            iconClass="icon icon-draft"
            after={
              <span className="sidebar__link-item__number">
                {templatesNumbers.draft}
              </span>
            }
            active={
              !isSystem &&
              system_templates !== 'true' &&
              status === TEMPLATE_STATUS.DRAFT
            }
            hidden={templatesNumbers.draft === 0}>
            <FormattedMessage id="template.draft" />
          </SidebarLink>
          <SidebarLink
            to={{ search: deletedSearch }}
            iconClass="icon icon-delete"
            after={
              <span className="sidebar__link-item__number">
                {templatesNumbers.deleted}
              </span>
            }
            active={
              !isSystem &&
              system_templates !== 'true' &&
              status === TEMPLATE_STATUS.DELETED
            }
            hidden={templatesNumbers.deleted === 0}>
            <FormattedMessage id="template.deleted" />
          </SidebarLink>
          <SidebarLink
            className="sidebar__link-item__shared-templates"
            to={{ search: String(sharedSearch) }}
            iconClass="icon icon-folder-shared"
            after={
              <span className="sidebar__link-item__number">
                {templatesNumbers.shared}
              </span>
            }
            active={
              !isSystem &&
              system_templates !== 'true' &&
              status === TEMPLATE_STATUS.SHARED
            }
            hidden={templatesNumbers.shared === 0}>
            <FormattedMessage id="template.shared" />
          </SidebarLink>
          <SidebarLink
            className="sidebar__link-item__favorited-templates"
            to={{ search: String(favoritedSearch) }}
            iconClass="icon icon-folder-special"
            after={
              <span className="sidebar__link-item__number">
                {templatesNumbers.favorited}
              </span>
            }
            active={
              !isSystem &&
              system_templates !== 'true' &&
              status === TEMPLATE_STATUS.FAVORITED
            }
            hidden={templatesNumbers.favorited === 0}>
            <FormattedMessage id="template.favorite" />
          </SidebarLink>
        </SidebarGroupLinks>
        <SidebarGroupLinks
          groupTitle="template.quick-start"
          buttons={
            isAdmin && (
              <IconButton
                className="sidebar__create-new-category"
                aria-label="create new category"
                variant="contained"
                color="secondary"
                onClick={open}>
                <span className="icon icon-add" />
              </IconButton>
            )
          }>
          <SidebarLink
            to={{ search: allSearch }}
            after={
              <span className="sidebar__link-item__number">
                {templatesNumbers.system}
              </span>
            }
            active={categoryId === 'all'}
            hidden={templatesNumbers.system === 0}>
            <FormattedMessage id="template.all" />
          </SidebarLink>
          {isAdmin && (
            <SidebarLink
              className="sidebar__link-item__admin-action"
              to={{ search: String(deletedSystemTemplates) }}
              after={
                <span className="sidebar__link-item__number">
                  {templatesNumbers.deletedSystem}
                </span>
              }
              active={
                system_templates === 'true' &&
                status === TEMPLATE_STATUS.DELETED
              }
              hidden={templatesNumbers.deletedSystem === 0}>
              <FormattedMessage id="template.deleted-systems" />
            </SidebarLink>
          )}
          {categories.map((category) => {
            const search = buildQueryString({
              category: category.id,
              embed,
              advanced,
            });

            return (
              <SidebarLink
                key={category.id}
                to={{ search }}
                value={category.count}
                active={categoryId === category.id}
                hidden={category.hidden || category.count === 0}
                after={
                  isAdmin && category.group !== 'layouts' ? (
                    <SidebarLinkCategoryButtons
                      category={category}
                      categoriesNames={categoriesNames}
                    />
                  ) : (
                    <span className="sidebar__link-item__number">
                      {category.count}
                    </span>
                  )
                }>
                {category.name}
              </SidebarLink>
            );
          })}
        </SidebarGroupLinks>
      </div>

      {/* The mode switcher is hidden for the next version, when the dark mode implements */}
      {/* <div id="interface-switcher" className="dark-theme">
          <FormattedMessage id="interface-switcher" />
          <div className="switcher">
            <div className="icon icon-dark-mode" onClick={() => setTheme('dark')} />
            <div className="toggle" onClick={handleTheme} />
            <div
              className="icon icon-light-mode"
              onClick={() => setTheme('light')}
            />
          </div>
        </div>*/}
      {VERSION ? <div className="sidebar__version">v{VERSION}</div> : null}
      {isAdmin && (
        <BaseModal
          headerTitle="category-modal.title"
          contentClass="category-modal"
          style={{
            maxWidth: 700,
          }}
          isOpen={isOpen}
          onClose={close}>
          <CategoryModal categoriesNames={categoriesNames} onClose={close} />
        </BaseModal>
      )}
    </aside>
  );
}

Sidebar.propTypes = {
  category: PropTypes.string,
  status: PropTypes.string.isRequired,
  setBulkAction: PropTypes.func.isRequired,
  setBulkActionTemplatesList: PropTypes.func.isRequired,
};

export default Sidebar;
