import { useEffect, useCallback, useMemo } from 'react';
import { useDebounce } from 'use-debounce';
import { LOAD_STATUS } from 'appConstants';
import {
  clearUnsplash,
  getUnsplash,
  setUnsplashNextPage,
  setUnsplashSearchTerm,
} from 'store/actions';
import { unsplashSelector } from 'store/selectors';
import { useDispatch, useSelector } from 'react-redux';

export const useUnsplash = () => {
  const state = useSelector(unsplashSelector);

  const dispatch = useDispatch();

  const debouncedValue = useMemo(
    () => ({
      searchTerm: state.searchTerm,
      page: state.page,
    }),
    [state.page, state.searchTerm],
  );

  // debouncing page to prevent bugs when user clears input
  // when user clears input, page sets to 1 and because of this
  // effect is running with old debounced searchTerm and page = 1
  const [{ searchTerm, page }] = useDebounce(debouncedValue, 500);

  useEffect(() => {
    return () => {
      dispatch(clearUnsplash());
    };
  }, [dispatch]);

  useEffect(() => {
    let promise;

    if (searchTerm) {
      promise = dispatch(
        getUnsplash({
          url: `https://api.unsplash.com/search/photos`,
          params: {
            query: searchTerm,
            page,
            per_page: state.perPage,
          },
        }),
      );
    }

    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [state.perPage, searchTerm, page, dispatch]);

  const setSearchTerm = useCallback(
    (searchTerm) => {
      dispatch(
        setUnsplashSearchTerm({
          searchTerm,
          // setting loading manually to show loader immediately for better UX
          status: searchTerm === '' ? LOAD_STATUS.IDLE : LOAD_STATUS.LOADING,
        }),
      );
    },
    [dispatch],
  );

  const loadNextPage = useCallback(() => {
    dispatch(setUnsplashNextPage());
  }, [dispatch]);

  const downloadImage = useCallback(
    (url) => {
      dispatch(getUnsplash({ url }));
    },
    [dispatch],
  );

  return { state, setSearchTerm, loadNextPage, downloadImage };
};
