import React, { useState } from 'react';
import { showErrorMessage, showSuccessMessage } from 'helpers';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import Button from 'components/Button';
import './CodeMirrorAllButtons.sass';

function CodeMirrorAllButtons({
  editor,
  onClose,
  htmlEditor,
  cssEditor,
  disableCopy = false,
  disablePaste = false,
  disableUpload = false,
}) {
  const intl = useIntl();

  const [file, setFile] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const messages = defineMessages({
    copyAllTitle: { id: 'code-mirror.all-clipboard-copy' },
    pasteAllTitle: { id: 'code-mirror.all-clipboard-paste' },
  });

  const handleCopy = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({
          name: 'clipboard-write',
        })
        .then(async ({ state }) => {
          if (state !== 'denied') {
            try {
              await navigator.clipboard.writeText(
                cssEditor?.current.getValue() + htmlEditor?.current.getValue(),
              );

              showSuccessMessage('Copied successfully');
            } catch (error) {
              showErrorMessage('Something went wrong. Could not copy');
            }
          }
        })
        .catch(() => {});
    }
  };

  const handlePaste = () => {
    if (navigator.permissions) {
      navigator.permissions
        .query({
          name: 'clipboard-read',
        })
        .then(async ({ state }) => {
          if (state !== 'denied') {
            try {
              const clipboardText = await navigator.clipboard.readText();
              const matchCSS = clipboardText.match(
                /<style[\s\S]*?>[\s\S]*?<\/style[\s\S]*?>/gm,
                '',
              );

              if (matchCSS) {
                cssEditor.current.setValue(matchCSS.join(''));
              } else {
                cssEditor.current.setValue('');
              }

              htmlEditor.current.setValue(
                clipboardText.replace(
                  /<style[\s\S]*?>[\s\S]*?<\/style[\s\S]*?>/gm,
                  '',
                ),
              );
            } catch (error) {
              showErrorMessage('Something went wrong. Could not paste');
            }
          }
        })
        .catch(() => {});
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 2097152) {
        showErrorMessage('The file size can not exceed 2MB');
      } else if (/\.(html)$/i.test(e.target.files[0].name) === false) {
        showErrorMessage(
          `Sorry, ${e.target.files[0].name} is invalid, allowed extension is: .html`,
        );
        setFile('');
      } else {
        setFile(e.target.files[0]);
      }
    }

    return;
  };

  const handleFileUpload = () => {
    setIsLoading(true);

    if (!file) {
      return;
    }

    let reader = new FileReader();

    reader.readAsText(file);

    reader.onload = (event) => {
      setTimeout(() => {
        editor.setComponents(event.target.result);
      }, 1000);

      setTimeout(() => {
        onClose();
        editor.refresh();

        setIsLoading(false);
      }, 2000);
    };

    reader.onerror = () => {
      showErrorMessage('An error occurred while attempting to read the file.');
    };
  };

  return (
    <div className="code-mirror-all-buttons">
      {!disableUpload && (
        <div className="code-mirror-all-buttons__upload-button">
          <input
            className="code-mirror-all-buttons__upload-button__input"
            type="file"
            title={file && `${file.name} - ${file.type}`}
            accept=".html"
            onChange={handleFileChange}
          />
          <Button
            onClick={handleFileUpload}
            disabled={!file || isLoading}
            loading={isLoading}
            className="code-mirror-all-buttons__upload-button__load"
            variant="contained">
            {intl.formatMessage({ id: 'button.load-file' })}
          </Button>
        </div>
      )}
      {!disableCopy && (
        <Button
          className="code-mirror-all-buttons__copy-all"
          onClick={handleCopy}>
          {intl.formatMessage(messages.copyAllTitle)}
        </Button>
      )}
      {!disablePaste && (
        <Button
          className="code-mirror-all-buttons__paste-all"
          onClick={handlePaste}>
          {intl.formatMessage(messages.pasteAllTitle)}
        </Button>
      )}
    </div>
  );
}

CodeMirrorAllButtons.propTypes = {
  editor: PropTypes.shape({
    setComponents: PropTypes.func.isRequired,
    refresh: PropTypes.func.isRequired,
  }),
  onClose: PropTypes.func,
  htmlEditor: PropTypes.object,
  cssEditor: PropTypes.object,
  disableCopy: PropTypes.bool,
  disablePaste: PropTypes.bool,
  disableUpload: PropTypes.bool,
};

export default CodeMirrorAllButtons;
