import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  dashboardTourStep1,
  dashboardTourStep2,
  dashboardTourStep3NewTemplate,
  dashboardTourStep3Templates,
} from 'appConstants';
import Button from 'components/Button';
import './DashboardTourModal.sass';
import { useQuery } from 'hooks';
import { buildQueryString } from 'helpers';

function DashboardTourStep1({ onNextClick }) {
  return (
    <>
      <div className="dashboard-tour__left">
        <img
          src={dashboardTourStep1()}
          alt="dnd builder preview with waymore logo"
        />
      </div>
      <div className="dashboard-tour__right">
        <h4 className="dashboard-tour__title">
          <FormattedMessage id="dashboard-tour.step-1.title" />
        </h4>
        <div className="dashboard-tour__description">
          <FormattedMessage id="dashboard-tour.step-1.message" />
        </div>
        <div className="dashboard-tour__buttons-wrapper">
          <Button variant="contained" onClick={onNextClick}>
            <div className="dashboard-tour__button-label">
              <FormattedMessage id="button.continue" />
              <span className="icon icon-chevron-right" />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

DashboardTourStep1.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

function DashboardTourStep2({ onNextClick }) {
  return (
    <>
      <div className="dashboard-tour__left">
        <img src={dashboardTourStep2()} alt="dnd builder preview" />
        <h4 className="dashboard-tour__title">
          &#8220;
          <FormattedMessage id="dashboard-tour.step-2.title-1" />
          &#8221;
        </h4>
      </div>
      <div className="dashboard-tour__right">
        <h4 className="dashboard-tour__title">
          <FormattedMessage id="dashboard-tour.step-2.title-2" />
        </h4>
        <div className="dashboard-tour__description">
          <div className="dashboard-tour__article">
            <FormattedMessage id="dashboard-tour.step-2.message-1" />
          </div>
          <div className="dashboard-tour__article">
            <FormattedMessage id="dashboard-tour.step-2.message-2" />
          </div>
        </div>
        <div className="dashboard-tour__buttons-wrapper">
          <Button variant="contained" onClick={onNextClick}>
            <div className="dashboard-tour__button-label">
              <FormattedMessage id="button.continue" />
              <span className="icon icon-chevron-right" />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

DashboardTourStep2.propTypes = {
  onNextClick: PropTypes.func.isRequired,
};

function DashboardTourStep3({ onCreateNewClick, onBrowseTemplatesClick }) {
  return (
    <>
      <div className="dashboard-tour__left">
        <h4 className="dashboard-tour__title">
          <FormattedMessage id="dashboard-tour.step-3.title-1" />
        </h4>
        <img src={dashboardTourStep3NewTemplate()} alt="dnd builder preview" />
        <div className="dashboard-tour__description">
          <FormattedMessage id="dashboard-tour.step-3.message-1" />
        </div>
        <div className="dashboard-tour__buttons-wrapper">
          <Button
            className="dashboard-tour__create-new"
            variant="contained"
            onClick={onCreateNewClick}>
            <div className="dashboard-tour__button-label">
              <FormattedMessage id="dashboard-tour.button.create-new" />
              <span className="icon icon-chevron-right" />
            </div>
          </Button>
        </div>
      </div>
      <div className="dashboard-tour__right">
        <h4 className="dashboard-tour__title">
          <FormattedMessage id="dashboard-tour.step-3.title-2" />
        </h4>
        <img src={dashboardTourStep3Templates()} alt="templates" />
        <div className="dashboard-tour__description">
          <FormattedMessage id="dashboard-tour.step-3.message-2" />
        </div>
        <div className="dashboard-tour__buttons-wrapper">
          <Button variant="contained" onClick={onBrowseTemplatesClick}>
            <div className="dashboard-tour__button-label">
              <FormattedMessage id="dashboard-tour.button.browse-templates" />
              <span className="icon icon-chevron-right" />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
}

DashboardTourStep3.propTypes = {
  onCreateNewClick: PropTypes.func.isRequired,
  onBrowseTemplatesClick: PropTypes.func.isRequired,
};

function DashboardTourModal({ onFinishDashboardTour, onOpenNewTemplateModal }) {
  const history = useHistory();
  const { embed, 'create-new': createNewTemplate, advanced } = useQuery();

  const [step, setStep] = useState(createNewTemplate ? 3 : 1);

  const handleNextClick = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleCreateNewClick = () => {
    const search = buildQueryString({
      category: 'all',
      embed,
      advanced,
    });

    onOpenNewTemplateModal();
    onFinishDashboardTour();
    history.push({ pathname: '/', search });
  };

  const handleBrowseTemplatesClick = () => {
    const search = buildQueryString({
      category: 'all',
      embed,
      advanced,
    });

    onFinishDashboardTour();
    history.push({ pathname: '/', search });
  };

  return (
    <div className={cx('dashboard-tour', `step-${step}`)}>
      {step === 1 && <DashboardTourStep1 onNextClick={handleNextClick} />}
      {step === 2 && <DashboardTourStep2 onNextClick={handleNextClick} />}
      {step >= 3 && (
        <DashboardTourStep3
          onCreateNewClick={handleCreateNewClick}
          onBrowseTemplatesClick={handleBrowseTemplatesClick}
        />
      )}
    </div>
  );
}

DashboardTourModal.propTypes = {
  onFinishDashboardTour: PropTypes.func.isRequired,
  onOpenNewTemplateModal: PropTypes.func.isRequired,
};

export default DashboardTourModal;
