import { cellStyle, tableStyle } from 'appConstants';

const tableType = 'table';
const tableCellType = 'cell';
const prodcutType = 'waymore-product';
const buttonTitle = 'Buy Now';
const buttonType = 'button';

const getProductItemsStyle = (oldProduct, itemClass) => {
  let productItemStylesString = '';

  const productItem = oldProduct.find(itemClass);
  const productItemStyle =
    productItem.length > 0 ? productItem[0].getStyle() : '';

  Object.keys(productItemStyle).map(
    (objectKey) =>
      (productItemStylesString += `${objectKey}: ${productItemStyle[
        objectKey
      ].replaceAll('"', '&quot;')};`),
  );

  return productItemStylesString;
};

export default (editor) => {
  const cmd = editor.Commands;

  cmd.add('set-waymore-product', {
    run(
      editor,
      sender,
      {
        layout,
        showCategory,
        showDescription,
        product: {
          imageLink,
          productName,
          productCategories,
          price,
          productLink,
          productDescription,
        },
        oldProduct,
      },
    ) {
      const selected = editor?.getSelected();

      const selectedHtml = selected?.view?.el.innerHTML;

      const productImageDefaultStyle = 'width: 100%; height: auto;';
      const productTitleDefaultStyle =
        'margin: 0; padding: 0 0 15px; font-size: 24px; font-weight: 700; font-family: Arial, Helvetica, sans-serif;';
      const productDescriptionDefaultStyle = 'padding: 0 0 15px; margin: 0';
      const productCategoryDefaultStyle = 'padding: 0 0 15px; margin: 0';
      const productPriceDefaultStyle =
        'padding: 0 0 15px; margin: 0; color: rgb(126, 140, 224); font-size: 28px; font-weight: 700; font-family: Arial, Helvetica, sans-serif;';
      const productButtonDefaultStyle =
        'display: block; padding: 10px 20px; font-weight: 700; text-transform: uppercase; text-decoration: none; font-size: 16px; color: rgba(255, 255, 255); background-color: rgb(126, 140, 224); text-align: center; font-family: Arial, Helvetica, sans-serif;';

      const productSectionStyle = 'text-align: center';
      const productImageStyle = !selectedHtml
        ? productImageDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__image');
      const productTitleStyle = !selectedHtml
        ? productTitleDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__title');
      const productDescriptionStyle = !selectedHtml
        ? productDescriptionDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__description');
      const productPriceStyle = !selectedHtml
        ? productPriceDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__price');
      const productButtonStyle = !selectedHtml
        ? productButtonDefaultStyle
        : getProductItemsStyle(oldProduct, '.product-item__button');

      const categories =
        showCategory && productCategories
          ? `<div class="product-item__categories" style="${productCategoryDefaultStyle}">${productCategories.join(
              ' / ',
            )}</div>`
          : '';

      const description =
        showDescription && productDescription
          ? `<div className="product-item__description" style="${productDescriptionStyle}">${productDescription}</div>`
          : '';

      let content;

      if (layout === 'vertical') {
        content = `
          <img src="${imageLink}" alt="${productName}" class="product-item__image" style="${productImageStyle}" />
          <h3 class="product-item__title" style="${productTitleStyle}">${productName}</h3>
          ${categories}
          ${description}
          <div class="product-item__price" style="${productPriceStyle}">${price}</div>
          <a href="${productLink}" data-type="${buttonType}" data-gjs-type="${buttonType}" class="product-item__button" style="${productButtonStyle}">${buttonTitle}</a>
        `;
      } else {
        content = `
          <table style="${tableStyle}" data-type="${tableType}">
            <td data-type="${tableCellType}" style="${cellStyle} width: 50%">
            <img src="${imageLink}" alt="${productName}" class="product-item__image" style="${productImageStyle}" />
            </td>
            <td data-type="${tableCellType}" style="${cellStyle} width: 50%">
              <h3 class="product-item__title" style="${productTitleStyle}">${productName}</h3>
              ${categories}
              ${description}
              <div class="product-item__price" style="${productPriceStyle}">${price}</div>
              <a href="${productLink}" data-type="${buttonType}" data-gjs-type="${buttonType}" class="product-item__button" style="${productButtonStyle}">${buttonTitle}</a>
            </td>
          </table>
        `;
      }

      selected.replaceWith(`
        <div data-gjs-type="${prodcutType}" data-type="${prodcutType}" data-product-name="${productName}" class="product-section" style="${productSectionStyle}" verticalalign="default" overflow="visible">
          ${content}
        </div>
      `);
    },
  });
};
