import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { principalIdSelector } from 'store/selectors';
import { copyFromGlobalToTemplateAssets } from 'store/actions';
import { useDebounce } from 'use-debounce';
import { useDropzone } from 'react-dropzone';
import { buildAssetsUrl, buildGlobalAssetsUrl } from 'helpers';
import ConfirmModal from 'components/Modals/ConfirmModal';
import BaseModal from 'components/Modals/BaseModal';
import Button from 'components/Button';
import TextField from 'components/Forms/TextField';
import ImagesContent from 'components/Images/ImagesContent';
import ImageItem from 'components/Images/ImageItem';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';

function GlobalImages({
  closeConfirmGlobalDeleteModal,
  data,
  handleDeleteGlobalImage,
  handleUploadImage,
  id,
  isConfirmGlobalDeleteModalOpen,
  isGlobalImageDeleteLoading,
  loading,
  onSetImageUrl,
  openConfirmGlobalDeleteModal,
  selectedImageUrl,
}) {
  const intl = useIntl();

  const dispatch = useDispatch();

  const userId = useSelector(principalIdSelector);

  const [savedNameForDelete, setSavedNameForDelete] = useState('');
  const [url, setUrl] = useState('');
  const [urlError, setUrlError] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueDebounce] = useDebounce(searchValue, 600);

  const { isDragActive, open, getRootProps, getInputProps } = useDropzone({
    onDrop: handleUploadImage,
    accept: 'image/*',
    noClick: Boolean(data.length),
  });

  const handleItemClick = async (name) => {
    await dispatch(copyFromGlobalToTemplateAssets({ id, imageName: name }));

    onSetImageUrl(buildAssetsUrl(id, name), name);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    onSetImageUrl(url);
  };

  return (
    <div className="image-modal__content global-images">
      <form className="image-modal__add-image-form" onSubmit={handleSubmit}>
        <fieldset disabled={loading}>
          <div className="image-modal__add-image-form-wrapper">
            <TextField
              label={intl.formatMessage({
                id: 'image-modal.global-images.input-label',
              })}
              className="image-modal__add-image-input"
              placeholder={intl.formatMessage({
                id: 'image-modal.global-images.input-placeholder',
              })}
              error={urlError}
              helperText={intl.formatMessage(
                urlError
                  ? {
                      id: 'image-modal.global-images.input-validation',
                    }
                  : { id: 'image-modal.global-images.input-description' },
              )}
              value={url}
              onChange={(e) => {
                const urlValue = e.target.value;

                if (!urlValue || urlValue.trim() === '') {
                  setUrlError(true);
                } else {
                  setUrlError(false);
                }

                setUrl(urlValue);
              }}
              fullWidth
            />
            <Button
              disabled={!url || urlError}
              type="submit"
              variant="contained"
              className="image-modal__add-image-button">
              <FormattedMessage id="image-modal.global-images.submit-button" />
            </Button>
          </div>
        </fieldset>
      </form>
      <div
        {...getRootProps({
          className: cx('image-modal__images-wrapper', { isDragActive }),
        })}>
        <input {...getInputProps()} />
        <div className="image-modal__search-box">
          <input
            className="image-modal__search-box__input"
            type="search"
            placeholder={intl.formatMessage({
              id: 'image-modal.search-box.global-input-placeholder',
            })}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
        <ImagesContent showBigSpinner={loading}>
          {data.length ? (
            <>
              <button
                key="upload-global-image"
                data-tip
                data-for="upload-global-image"
                className={cx('image-modal__drop-image-button', {
                  hidden: isDragActive,
                })}
                type="button"
                onClick={open}>
                <span
                  role="img"
                  aria-label="upload to server"
                  className="icon icon-cloud-upload"
                />
              </button>
              <ReactTooltip
                id="upload-global-image"
                type="dark"
                aria-haspopup="true"
                place="top"
                effect="solid"
                backgroundColor="#000000">
                <FormattedMessage id="image-modal.global-images.upload-button-tooltip" />
              </ReactTooltip>
            </>
          ) : null}
          {data
            ?.filter((img) => img.savedName.includes(searchValueDebounce))
            ?.map(({ originalName, savedName }) => (
              <Fragment key={`global-image-${savedName}`}>
                <ImageItem
                  data-tip
                  data-for={`global-image-${savedName}`}
                  thumbUrl={buildGlobalAssetsUrl(userId, savedName)}
                  description={originalName}
                  selected={selectedImageUrl.includes(savedName)}
                  onItemClick={() => handleItemClick(savedName)}
                  onDelete={() => {
                    setSavedNameForDelete(savedName);
                    openConfirmGlobalDeleteModal();
                  }}
                />
                <ReactTooltip
                  id={`global-image-${savedName}`}
                  type="dark"
                  aria-haspopup="true"
                  place="top"
                  effect="solid"
                  backgroundColor="#000000">
                  {originalName}
                </ReactTooltip>
              </Fragment>
            ))}
        </ImagesContent>
        <BaseModal
          isOpen={isConfirmGlobalDeleteModalOpen}
          headerTitle="image-modal.global-images.confirm-modal-header"
          maxWidth="600px"
          onClose={closeConfirmGlobalDeleteModal}>
          <ConfirmModal
            loading={isGlobalImageDeleteLoading}
            buttonsColor="secondary"
            onClose={closeConfirmGlobalDeleteModal}
            onConfirm={() => {
              handleDeleteGlobalImage(savedNameForDelete);
              setSavedNameForDelete('');
            }}>
            <FormattedMessage id="image-modal.global-images.confirm-modal-header.confirm-modal-description" />
          </ConfirmModal>
        </BaseModal>
        {!loading && (
          <div
            className={cx('image-modal__drop-message', {
              visible: !data.length,
              active: isDragActive,
            })}>
            <span
              role="img"
              aria-label="upload to server"
              className="icon icon-cloud-upload"
            />
            {data.length ? (
              <FormattedMessage id="message.drop-to-upload" />
            ) : (
              <FormattedMessage id="message.drop-to-upload-or-click" />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

GlobalImages.propTypes = {
  closeConfirmGlobalDeleteModal: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  handleDeleteGlobalImage: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isConfirmGlobalDeleteModalOpen: PropTypes.bool.isRequired,
  isGlobalImageDeleteLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSetImageUrl: PropTypes.func.isRequired,
  openConfirmGlobalDeleteModal: PropTypes.func.isRequired,
  selectedImageUrl: PropTypes.string,
};

export default GlobalImages;
