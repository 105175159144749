import axios from 'axios';
import { ASSETS_API_HOST } from 'appConstants';

const httpCopyFromGlobalToTemplateAssets = (id, imageName) => {
  return axios.get(`/api/v2/assets/template/${id}/${imageName}`);
};

const httpGetGlobalAssets = () => {
  return axios.get('/api/v2/assets/user');
};

const httpGetAssets = (id) => {
  return axios.get(`/api/v2/assets/template/${id}`);
};

const httpUploadAssets = (payload) => {
  return axios.post('/api/v2/assets', payload);
};

const httpDeleteGlobalAsset = (assetName) => {
  return axios.delete(`/api/v2/assets/${assetName}`);
};

const httpDeleteAsset = (id, payload) => {
  return axios.delete(`/api/v2/assets/template/${id}`, { data: payload });
};

const httpUnsplash = (url) => {
  const payload = { method: 'GET', url };

  return axios.post('/unsplash', payload, { baseURL: ASSETS_API_HOST });
};

const httpAiImages = (payload) => {
  return axios.post('/api/v2/ai/image', payload);
};

const httpSearchIcons = (url) => {
  const payload = { method: 'GET', url };

  return axios.post('/icons', payload, { baseURL: ASSETS_API_HOST });
};

export {
  httpCopyFromGlobalToTemplateAssets,
  httpGetGlobalAssets,
  httpGetAssets,
  httpUploadAssets,
  httpDeleteGlobalAsset,
  httpDeleteAsset,
  httpUnsplash,
  httpAiImages,
  httpSearchIcons,
};
