import React from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './Logo.sass';

function Logo({ className }) {
  return (
    <div
      role="banner"
      aria-label="email editor"
      className={cx('dnd-logo', className)}>
      <span className="icon icon-send" />
      <div>
        <FormattedMessage id="logo.title" />
      </div>
    </div>
  );
}

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
