import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabList, TabPanels, TabPanel, Tab } from '@reach/tabs';

import Button from '../../Button/Button';
import { EmailBody, General, Headings } from './components';
import './GeneralSettingsModal.sass';

function GeneralSettingsModal({ onClose, onSaveSettings }) {
  return (
    <div className="general-settings-modal">
      <div className="general-settings-modal__content-wrapper">
        <Tabs>
          <TabList>
            <Tab as="div">
              <FormattedMessage id="general-settings-modal.general-tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="general-settings-modal.email-body-tab" />
            </Tab>
            <Tab as="div">
              <FormattedMessage id="general-settings-modal.headings-tab" />
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <General />
            </TabPanel>
            <TabPanel>
              <EmailBody />
            </TabPanel>
            <TabPanel>
              <Headings />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      <div className="general-settings-modal__buttons">
        <Button type="button" onClick={onClose}>
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button type="button" variant="contained" onClick={onSaveSettings}>
          <FormattedMessage id="button.apply" />
        </Button>
      </div>
    </div>
  );
}

GeneralSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSaveSettings: PropTypes.func.isRequired,
};

export default GeneralSettingsModal;
