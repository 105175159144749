export const setRuleStyle = (editor, selector, style) => {
  const css = editor.CssComposer;

  const rule = css.getRule(selector);

  if (rule) {
    const ruleStyle = rule.get('style');
    rule.set('style', { ...ruleStyle, ...style });
  } else {
    css.setRule(selector, style);
  }
};

/**
 *
 * @param {object} selected Selected component provided by grapesjs
 * @returns {string} Returns src attribute of the image or background-image property
 */
export const getSelectedImageUrl = (selected) => {
  if (!selected) {
    return '';
  }

  if (selected.get('type') === 'image') {
    return selected.get('src');
  }

  return selected.getStyle()['background-image'] || '';
};

/**
 * An utility function to help determine if the selected is a valid anchor node
 * @param {object} rte Grapesjs rich text editor object
 */
export const isValidAnchor = (rte) => {
  const anchor = rte.selection().anchorNode;

  if (!anchor) {
    return false;
  }

  const { parentNode, nextSibling } = anchor;

  return parentNode?.nodeName === 'A' || nextSibling?.nodeName === 'A';
};
