export const socialTypes = [
  'facebook',
  'instagram',
  'linkedin',
  'x',
  'vk',
  'youtube',
  'pin',
];

export const socialShapes = ['square', 'round'];

export const socialColors = ['color', 'black', 'grey', 'white', 'transparent'];
