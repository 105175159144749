import components from './components';
import blocks from './blocks';

export default (editor, waymoreIntegration) => {
  const config = {
    wayMoreProductType: 'waymore-product',
    waymoreIntegration,
  };

  components(editor, config);
  blocks(editor, config);
};
