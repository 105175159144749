import { COMPONENT_CHANGE_STATUSES } from 'appConstants';
import { checkComponentType } from 'helpers';
import { socialTypes, socialShapes, socialColors } from './constants';
import { getSocialImageUrl } from './utils';

const addSocialFollow = (
  editor,
  { baseUrl, socialLinkTraitType, socialFollowType },
) => {
  let traits = [
    {
      type: 'select',
      label: 'Network',
      name: 'network',
      changeProp: true,
      options: socialTypes.map((social) => {
        return { value: social, name: social === 'pin' ? 'pinterest' : social };
      }),
    },
    {
      type: socialLinkTraitType,
      label: 'Social URL or ID',
      name: 'url',
      changeProp: true,
    },
  ];

  editor.DomComponents.addType(socialFollowType, {
    extend: 'link',
    model: {
      defaults: {
        name: 'Social Follow Item',
        type: socialFollowType,
        tagName: 'a',
        network: null,
        traits,
        droppable: false,
        editable: false,
        draggable: false,
        openTraitsOnSelect: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:status', this.initializeTraits);
        this.listenTo(this.model, 'change:network', this.updateNetwork);
      },
      initializeTraits(model, status) {
        if (status !== COMPONENT_CHANGE_STATUSES.SELECTED) {
          return;
        }

        const { network } = model.getAttributes();

        model.attributes.network = network;
      },
      updateNetwork(model, network) {
        model.addAttributes({
          'data-type': `${socialFollowType}-${network}`,
          network,
        });

        const { shape, color, size } = model.parent().getAttributes();

        const components = model.components();
        components.models.forEach((itemModel) => {
          itemModel.setAttributes({
            'data-gjs-editable': 'false',
            'data-gjs-selectable': 'false',
            'data-gjs-removable': 'false',
            width: size,
            height: size,
            src: getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          });
        });
      },
    },
    isComponent(el) {
      const elementType = el.getAttribute && el.getAttribute('data-type');

      const isSocial = socialTypes.some(
        (type) => elementType === `${socialFollowType}-${type}`,
      );

      if (el.tagName === 'A' && isSocial) {
        return { type: socialFollowType };
      }
    },
  });
};

const addSocialFollowBlock = (
  editor,
  { baseUrl, color, shape, size, socialFollowBlockType },
) => {
  let traits = [
    {
      type: 'number',
      label: 'Size (px)',
      name: 'size',
      changeProp: true,
      min: 0,
      max: 94,
    },
    {
      type: 'select',
      label: 'Shape',
      name: 'shape',
      changeProp: true,
      options: socialShapes.map((shape) => {
        return { value: shape, name: shape };
      }),
    },
    {
      type: 'select',
      label: 'Color',
      name: 'color',
      changeProp: true,
      options: socialColors.map((color) => {
        return { value: color, name: color };
      }),
    },
  ];

  editor.DomComponents.addType(socialFollowBlockType, {
    extend: 'default',
    model: {
      defaults: {
        name: 'Social Block',
        type: socialFollowBlockType,
        traits,
        droppable: false,
        color,
        shape,
        size,
        openTraitsOnSelect: true,
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'change:status', this.initializeTraits);
        this.listenTo(this.model, 'change:size', this.updateSize);
        this.listenTo(this.model, 'change:shape', this.updateShape);
        this.listenTo(this.model, 'change:color', this.updateColor);
      },
      initializeTraits(model, status) {
        if (status !== COMPONENT_CHANGE_STATUSES.SELECTED) {
          return;
        }

        const { size, shape, color } = model.getAttributes();

        model.attributes.size = size;
        model.attributes.shape = shape;
        model.attributes.color = color;
      },
      updateSize(model, size) {
        model.addAttributes({ size });

        const components = model.components();
        const { color, shape } = model.getAttributes();

        components.models.forEach((itemModel) => {
          const itemImageModel = itemModel.components().models[0];
          const { network } = itemModel.getAttributes();

          itemModel.addStyle({ width: `${size}px`, height: `${size}px` });
          itemImageModel.setAttributes({
            ...itemImageModel.getAttributes(),
            'data-gjs-editable': 'false',
            'data-gjs-selectable': 'false',
            'data-gjs-removable': 'false',
            width: size,
            height: size,
            src: getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          });
          itemImageModel.set(
            'src',
            getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          );
        });
      },
      updateShape(model, shape) {
        model.addAttributes({ shape });
        const components = model.components();
        const { color, size } = model.getAttributes();

        components.models.forEach((itemModel) => {
          const itemImageModel = itemModel.components().models[0];
          const { network } = itemModel.getAttributes();

          itemModel.addStyle({ width: `${size}px`, height: `${size}px` });
          itemImageModel.setAttributes({
            ...itemImageModel.getAttributes(),
            'data-gjs-editable': 'false',
            'data-gjs-selectable': 'false',
            'data-gjs-removable': 'false',
            width: size,
            height: size,
            src: getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          });
          itemImageModel.set(
            'src',
            getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          );
        });

        editor.refresh();
      },
      updateColor(model, color) {
        model.addAttributes({ color });

        const components = model.components();
        const { shape, size } = model.getAttributes();

        components.models.forEach((itemModel) => {
          const itemImageModel = itemModel.components().models[0];
          const { network } = itemModel.getAttributes();

          itemModel.addStyle({ width: `${size}px`, height: `${size}px` });
          itemImageModel.setAttributes({
            ...itemImageModel.getAttributes(),
            'data-gjs-editable': 'false',
            'data-gjs-selectable': 'false',
            'data-gjs-removable': 'false',
            width: size,
            height: size,
            src: getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          });
          itemImageModel.set(
            'src',
            getSocialImageUrl({
              baseUrl,
              shape,
              color,
              network,
            }),
          );
        });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, socialFollowBlockType)) {
        return { type: socialFollowBlockType };
      }
    },
  });
};

export default (editor, config) => {
  addSocialFollow(editor, config);
  addSocialFollowBlock(editor, config);
};
