import { useReducer, useCallback } from 'react';

const initialState = {
  clean: false,
  generalSettings: false,
  showInfoModal: false,
  showImageModal: false,
  showCustomModulCategoryModal: false,
  showIconModal: false,
  showPersonalNotesModal: false,
  showExportModal: false,
  showVersionHistory: false,
  publishedModal: false,
  testEmailModal: false,
  wayMoreProductModal: false,
  blockModal: false,
  codeImportModal: false,
  blockModalPayload: null,
};

export const useToggleTypes = {
  showClean: 'showClean',
  showGeneralSettings: 'showGeneralSettings',
  showImageModal: 'showImageModal',
  showInfoModal: 'showInfoModal',
  showCustomModulCategoryModal: 'showCustomModulCategoryModal',
  showIconModal: 'showIconModal',
  showPersonalNotesModal: 'showPersonalNotesModal',
  showExportModal: 'showExportModal',
  showVersionHistory: 'showVersionHistory',
  showPublishedModal: 'showPublishedModal',
  showTestEmailModal: 'showTestEmailModal',
  showWayMoreProductModal: 'showWayMoreProductModal',
  showBlockModal: 'showBlockModal',
  showCodeImportModal: 'showCodeImportModal',
  showViewCodeModal: 'showViewCodeModal',
  showCustomCodeModal: 'showCustomCodeModal',
};

const reducer = (state, action) => {
  switch (action.type) {
    case useToggleTypes.showClean:
      return { ...state, clean: !state.clean };
    case useToggleTypes.showGeneralSettings:
      return { ...state, generalSettings: !state.generalSettings };
    case useToggleTypes.showInfoModal:
      return { ...state, showInfoModal: !state.showInfoModal };
    case useToggleTypes.showImageModal:
      return { ...state, showImageModal: !state.showImageModal };
    case useToggleTypes.showCustomModulCategoryModal:
      return {
        ...state,
        showCustomModulCategoryModal: !state.showCustomModulCategoryModal,
      };
    case useToggleTypes.showIconModal:
      return { ...state, showIconModal: !state.showIconModal };
    case useToggleTypes.showPersonalNotesModal:
      return {
        ...state,
        showPersonalNotesModal: !state.showPersonalNotesModal,
      };
    case useToggleTypes.showExportModal:
      return {
        ...state,
        showExportModal: !state.showExportModal,
      };
    case useToggleTypes.showVersionHistory:
      return {
        ...state,
        showVersionHistory: !state.showVersionHistory,
      };
    case useToggleTypes.showPublishedModal:
      return { ...state, publishedModal: !state.publishedModal };
    case useToggleTypes.showTestEmailModal:
      return { ...state, testEmailModal: !state.testEmailModal };
    case useToggleTypes.showWayMoreProductModal:
      return { ...state, wayMoreProductModal: !state.wayMoreProductModal };
    case useToggleTypes.showBlockModal:
      return {
        ...state,
        blockModal: !state.blockModal,
        blockModalPayload: action.payload || null,
      };
    case useToggleTypes.showCodeImportModal:
      return { ...state, codeImportModal: !state.codeImportModal };
    case useToggleTypes.showViewCodeModal:
      return { ...state, viewCodeModal: !state.viewCodeModal };
    case useToggleTypes.showCustomCodeModal:
      return { ...state, customCodeModal: !state.customCodeModal };
    default:
      throw new Error();
  }
};

export const useToggle = () => {
  const [show, dispatch] = useReducer(reducer, initialState);

  const handleShow = useCallback(
    (type, payload) => {
      dispatch({ type, payload });
    },
    [dispatch],
  );

  return {
    show,
    handleShow,
  };
};
