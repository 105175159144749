import { useEffect, useRef, useState, useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import produce from 'immer';

import {
  buildPreviewUrl,
  buildQueryString,
  isValidStatus,
  showErrorMessage,
} from 'helpers';
import {
  getPrincipal,
  getSystemTemplates,
  getDeletedSystemTemplates,
  getTemplates,
  getFavoritedTemplates,
  setNextTemplatesPage,
  setNextDeletedSystemTemplatesPage,
  createTemplate,
  changeStatusTemplate,
  duplicateTemplate,
  searchTemplates,
  searchSystemTemplates,
  updateTemplate,
  updateSystemTemplate,
  deleteTemplate,
  deleteTemplates,
  deleteSystemTemplate,
  getCategories,
  bulkUnpublishTemplates,
  bulkSoftDeleteTemplates,
  bulkHardDeleteTemplates,
  bulkSoftDeleteSystemTemplates,
  bulkHardDeleteSystemTemplates,
  getSharedTemplates,
  updateSharedTemplateCollaborators,
  updateSharedTemplateLockStatus,
  sendUnlockRequestSharedTemplate,
} from 'store/actions';
import {
  isAdminSelector,
  isAllTemplatesLoadedSelector,
  isTemplatesErrorSelector,
  isTemplatesLoadingSelector,
  paginationSelector,
  templatesSelector,
  tokenSelector,
} from 'store/selectors';
import { SORTBYLIST, TEMPLATE_STATUS } from 'appConstants';
import { useQuery } from 'hooks';

const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE';
const REMOVE_ALL_TEMPLATES = 'REMOVE_ALL_TEMPLATES';
const REMOVE_SYSTEM_TEMPLATE = 'REMOVE_SYSTEM_TEMPLATE';
const RESET_REMOVE_TEMPLATES_STATE = 'RESET_REMOVE_TEMPLATES_STATE';

const removeTemplatesInitialState = {
  id: null,
  systemTemplateId: null,
  shouldRemoveAll: false,
  isConfirmRemoveOpen: false,
  deleteType: 'soft_delete',
};

const removeTemplatesReducer = produce(
  (draft, { type, payload, deleteType }) => {
    switch (type) {
      case REMOVE_TEMPLATE: {
        draft.id = payload;
        draft.isConfirmRemoveOpen = true;

        break;
      }
      case REMOVE_ALL_TEMPLATES: {
        draft.shouldRemoveAll = true;
        draft.isConfirmRemoveOpen = true;

        break;
      }
      case REMOVE_SYSTEM_TEMPLATE: {
        draft.systemTemplateId = payload;
        draft.isConfirmRemoveOpen = true;
        draft.deleteType = deleteType;

        break;
      }
      case RESET_REMOVE_TEMPLATES_STATE: {
        return removeTemplatesInitialState;
      }
      default: {
        throw new Error('Invalid type for removeTemplatesReducer');
      }
    }
  },
);

export function useTemplates() {
  let {
    system_templates,
    category,
    status = TEMPLATE_STATUS.PUBLISHED,
    embed,
    advanced,
  } = useQuery();

  const isSystem = Boolean(category);

  const history = useHistory();

  const intl = useIntl();

  const [showBigLoader, setShowBigLoader] = useState(false);
  const [values, setValues] = useState({
    sortBy: SORTBYLIST[0],
    searchTerm: '',
  });

  const isTemplatesLoading = useSelector(isTemplatesLoadingSelector);
  const isAllTemplatesLoaded = useSelector(isAllTemplatesLoadedSelector);
  const isError = useSelector(isTemplatesErrorSelector);
  const items = useSelector((state) =>
    templatesSelector(
      state,
      status,
      category,
      values?.searchTerm,
      system_templates,
    ),
  );

  const { lastPage, currentPage } = useSelector((state) =>
    paginationSelector(state, status, isSystem, system_templates),
  );

  const dispatch = useDispatch();

  const [
    {
      id: idToRemove,
      systemTemplateId: systemTemplateIdToRemove,
      isConfirmRemoveOpen,
      shouldRemoveAll,
      deleteType,
    },
    removeTemplatesDispatch,
  ] = useReducer(removeTemplatesReducer, removeTemplatesInitialState);
  const isMounted = useRef(false);

  const isAdmin = useSelector(isAdminSelector);
  const token = useSelector(tokenSelector);

  useEffect(() => {
    if (!isValidStatus(status)) {
      history.replace('/');
    }
  }, [status, history]);

  useEffect(() => {
    async function fetchCategoriesAndTemplates() {
      await dispatch(getCategories({ hidden: isAdmin ? null : false }));

      await Promise.all([
        dispatch(getTemplates()),
        dispatch(getFavoritedTemplates()),
        dispatch(getSharedTemplates()),
        dispatch(getSystemTemplates()),
        dispatch(getDeletedSystemTemplates()),
        dispatch(getPrincipal()),
      ]);
    }

    if (token) {
      fetchCategoriesAndTemplates();
    }
  }, [token, isAdmin, dispatch]);

  const debouncedRefetchTemplates = useDebouncedCallback(
    async ({ searchTerm, sortBy }) => {
      setShowBigLoader(true);

      if (isSystem) {
        if (searchTerm) {
          await dispatch(
            searchSystemTemplates({
              sortBy,
              searchTerm,
              status,
            }),
          );
        } else {
          await dispatch(getSystemTemplates({ sortBy }));
        }
      } else if (system_templates === 'true') {
        if (searchTerm) {
          await dispatch(
            searchSystemTemplates({
              sortBy,
              searchTerm,
              status,
            }),
          );
        } else {
          await dispatch(getDeletedSystemTemplates());
        }
      } else if (searchTerm) {
        await dispatch(
          searchTemplates({
            sortBy,
            searchTerm,
            status,
          }),
        );
      } else {
        await dispatch(
          getTemplates({
            sortBy,
            status,
          }),
        );
      }

      setShowBigLoader(false);
    },
    500,
  );

  // this effect is responsible only for fetching next pages
  useEffect(() => {
    // preventing refetching on initial render and on first page
    if (isSystem || !isMounted.current || currentPage <= 0) {
      return;
    }

    if (system_templates === 'true') {
      if (values.searchTerm) {
        dispatch(
          searchSystemTemplates({
            sortBy: values.sortBy,
            offset: currentPage,
          }),
        );
      } else {
        dispatch(
          getDeletedSystemTemplates({
            sortBy: values.sortBy,
            offset: currentPage,
          }),
        );
      }
    }

    if (values.searchTerm) {
      dispatch(
        searchTemplates({
          sortBy: values.sortBy,
          searchTerm: values.searchTerm,
          status,
          offset: currentPage,
        }),
      );
    } else {
      dispatch(
        getTemplates({
          sortBy: values.sortBy,
          offset: currentPage,
          status,
        }),
      );
    }
    // since this effect responsible only for fetching next pages
    // it should be rerun only on currentPage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, isSystem, dispatch]);

  // this effect is responsible only for refetching on status changes and only
  // if search term or sort by are different from initial values
  useEffect(() => {
    if (
      (category && values.searchTerm !== '') ||
      values.sortBy !== SORTBYLIST[0]
    ) {
      setValues({ sortBy: SORTBYLIST[0], searchTerm: '' });
    }

    if (
      isMounted.current &&
      (values.searchTerm !== '' || values.sortBy !== SORTBYLIST[0])
    ) {
      setValues({ sortBy: SORTBYLIST[0], searchTerm: '' });

      setShowBigLoader(true);

      dispatch(getTemplates()).then(() => {
        setShowBigLoader(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dispatch, category]);

  useEffect(() => {
    isMounted.current = true;
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setShowBigLoader(true);

    const newValues = { ...values, [name]: value };

    setValues(newValues);
    debouncedRefetchTemplates(newValues);
  };

  const handleSetNextTemplatesPage = useCallback(() => {
    dispatch(setNextTemplatesPage({ isSystem, status }));
  }, [dispatch, status, isSystem]);

  const handleSetNextDeletedSystemTemplatesPage = useCallback(() => {
    dispatch(setNextDeletedSystemTemplatesPage({ isSystem }));
  }, [dispatch, isSystem]);

  const handleEditTemplateClick = (id, isSystem) => {
    history.push({
      pathname: `/edit/${id}`,
      search: buildQueryString({
        embed,
        advanced,
        system: isSystem ? true : null,
      }),
    });
  };

  const handleSelectSystemTemplateClick = async (template) => {
    setShowBigLoader(true);

    try {
      const payload = {
        name: template.name,
        tags: template.tags,
        description: template.description,
        categories: [],
        id: template.id,
      };

      const newTemplate = await dispatch(createTemplate({ payload })).unwrap();

      const id = newTemplate.templateId;

      history.push({
        pathname: `/edit/${id}`,
        search: buildQueryString({ embed, advanced }),
      });
    } catch {
      // catching error and doing nothing
    } finally {
      setShowBigLoader(false);
    }
  };

  const dispatchWithRefetchingTemplates = async (callback) => {
    setShowBigLoader(true);

    try {
      await dispatch(callback).unwrap();

      setValues({ sortBy: SORTBYLIST[0], searchTerm: '' });

      await Promise.all([
        dispatch(getTemplates()),
        dispatch(getFavoritedTemplates()),
        dispatch(getSharedTemplates()),
        dispatch(getSystemTemplates()),
        dispatch(getDeletedSystemTemplates()),
      ]);
    } catch (error) {
      showErrorMessage(intl.formatMessage({ id: 'toast.error-message' }));
    } finally {
      setShowBigLoader(false);
    }
  };

  const handleDeleteClick = async (id) => {
    dispatchWithRefetchingTemplates(
      changeStatusTemplate({ id, status: TEMPLATE_STATUS.DELETED }),
    );
  };

  const handleRestoreClick = async (id) => {
    dispatchWithRefetchingTemplates(
      changeStatusTemplate({ id, status: TEMPLATE_STATUS.DRAFT }),
    );
  };

  const handleDuplicateClick = async (id, copySharedInfo = false) => {
    dispatchWithRefetchingTemplates(duplicateTemplate({ id, copySharedInfo }));
  };

  const handleCloseRemoveModal = () => {
    removeTemplatesDispatch({ type: RESET_REMOVE_TEMPLATES_STATE });
  };

  const handleConfirmRemove = () => {
    if (idToRemove) {
      dispatchWithRefetchingTemplates(deleteTemplate(idToRemove));
    }

    if (systemTemplateIdToRemove) {
      dispatchWithRefetchingTemplates(
        deleteSystemTemplate({ id: systemTemplateIdToRemove, deleteType }),
      );
    }

    if (shouldRemoveAll) {
      dispatchWithRefetchingTemplates(deleteTemplates());
    }

    removeTemplatesDispatch({ type: RESET_REMOVE_TEMPLATES_STATE });
  };

  const handleUpdateTemplateClick = ({ id, isSystem, payload }) => {
    if (isSystem) {
      dispatchWithRefetchingTemplates(updateSystemTemplate({ id, payload }));
      return;
    }

    dispatchWithRefetchingTemplates(updateTemplate({ id, payload }));
  };

  const handleRemoveClick = (id) => {
    removeTemplatesDispatch({ type: REMOVE_TEMPLATE, payload: id });
  };

  const handleRemoveSystemTemplateClick = (id) => {
    dispatchWithRefetchingTemplates(
      deleteSystemTemplate({ id, deleteType: 'soft_delete' }),
    );
  };

  const handleRemoveForeverSystemTemplateClick = (id) => {
    removeTemplatesDispatch({
      type: REMOVE_SYSTEM_TEMPLATE,
      payload: id,
      deleteType: 'hard_delete',
    });
  };

  const handlePreviewTemplateClick = (id) => {
    const previewUrl = buildPreviewUrl(id);

    let params = `status=no,location=no,toolbar=no,menubar=no,width=1376,height=768,left=200,top=100`;

    window.open(previewUrl, 'Preview', params);
  };

  const handleRemoveAllClick = () => {
    removeTemplatesDispatch({ type: REMOVE_ALL_TEMPLATES });
  };

  const handleBulkUnpublishTemplatesClick = (payload) => {
    dispatchWithRefetchingTemplates(bulkUnpublishTemplates({ payload }));
  };

  const handleBulkSoftDeleteTemplatesClick = (payload) => {
    dispatchWithRefetchingTemplates(bulkSoftDeleteTemplates({ payload }));
  };

  const handleBulkHardDeleteTemplatesClick = (
    payload,
    closeConfirmDeleteModal,
  ) => {
    dispatchWithRefetchingTemplates(
      bulkHardDeleteTemplates({ payload, closeConfirmDeleteModal }),
    );
  };

  const handleBulkSoftDeleteSystemTemplatesClick = (payload) => {
    dispatchWithRefetchingTemplates(bulkSoftDeleteSystemTemplates({ payload }));
  };

  const handleBulkHardDeleteSystemTemplatesClick = (payload) => {
    dispatchWithRefetchingTemplates(bulkHardDeleteSystemTemplates({ payload }));
  };

  const handleUpdateSharedTemplateCollaborators = ({ id, payload }) => {
    dispatchWithRefetchingTemplates(
      updateSharedTemplateCollaborators({ id, payload }),
    );
  };

  const handleUpdateSharedTemplateLockStatus = ({ id, lockStatus }) => {
    dispatchWithRefetchingTemplates(
      updateSharedTemplateLockStatus({
        id,
        lockStatus,
      }),
    );
  };

  const handleUnpublishTemplate = (id) => {
    dispatchWithRefetchingTemplates(
      changeStatusTemplate({
        id,
        status: TEMPLATE_STATUS.DRAFT,
      }),
    );
  };

  const handleUpdateFavoriteStatus = ({ id, payload }) => {
    dispatchWithRefetchingTemplates(
      updateTemplate({
        id,
        payload,
      }),
    );
  };

  const handleSendUnlockRequestSharedTemplateClick = async (
    collaboratorId,
    templateId,
  ) => {
    await dispatch(
      sendUnlockRequestSharedTemplate({ collaboratorId, templateId }),
    );
  };

  return {
    status,
    category,
    sortBy: values.sortBy,
    searchTerm: values.searchTerm,
    lastPage,
    showSystemTemplateRemoveDescription: Boolean(systemTemplateIdToRemove),
    isLoading: !isAllTemplatesLoaded,
    isLoadingMore: isTemplatesLoading,
    isError,
    showBigLoader,
    items,
    isConfirmRemoveOpen,
    handleChange,
    handleSetNextTemplatesPage,
    handleSetNextDeletedSystemTemplatesPage,
    handleEditTemplateClick,
    handleSelectSystemTemplateClick,
    handleDeleteClick,
    handleRestoreClick,
    handleDuplicateClick,
    handleUpdateTemplateClick,
    handleRemoveClick,
    handleCloseRemoveModal,
    handleConfirmRemove,
    handleRemoveAllClick,
    handleRemoveSystemTemplateClick,
    handleRemoveForeverSystemTemplateClick,
    handlePreviewTemplateClick,
    handleBulkUnpublishTemplatesClick,
    handleBulkSoftDeleteTemplatesClick,
    handleBulkHardDeleteTemplatesClick,
    handleBulkSoftDeleteSystemTemplatesClick,
    handleBulkHardDeleteSystemTemplatesClick,
    handleUpdateSharedTemplateCollaborators,
    handleUpdateSharedTemplateLockStatus,
    handleUnpublishTemplate,
    handleUpdateFavoriteStatus,
    handleSendUnlockRequestSharedTemplateClick,
  };
}
