import { uploadAssets, copyFromGlobalToTemplateAssets } from 'store/actions';
import {
  setRuleStyle,
  getFormDataTableSelector,
  getFormDataTableRowSelector,
  showErrorMessage,
  getErrorText,
  buildAssetsUrl,
} from 'helpers';
import QRCode from 'qrcode';
import { checkComponentType } from '../../../../../helpers/common';

const addCustomCode = (editor, { customCodeType, editCustomCode }) => {
  const domc = editor.DomComponents;
  const defaultType = domc.getType('default');
  const customCodeKey = 'custom-code-plugin__code';
  let timedInterval;

  domc.addType(customCodeType, {
    model: {
      defaults: {
        name: 'Custom Code',
        editable: true,
      },

      /**
       * Initilize the component
       */
      init() {
        this.listenTo(this, `change:${customCodeKey}`, this.onCustomCodeChange);

        const initialCode =
          this.get(customCodeKey) ||
          `<span>Insert here your custom code</span>`;
        const toolbar = this.get('toolbar');
        const id = 'custom-code';

        if (!this.components().length) {
          this.components(initialCode);
        }

        // Add the custom code toolbar button if requested and it's not already in
        if (!toolbar.filter((tlb) => tlb.id === id).length) {
          toolbar.unshift({
            id,
            attributes: {
              title: '',
            },
            command: editCustomCode ? 'custom-code-modal' : 'custom-code-lock',
            label: `<span class="icon ${
              editCustomCode ? 'icon-code' : 'icon-code lock-status'
            }"></span>`,
          });
        }
      },

      /**
       * Callback to launch on customCodeKey change
       */
      onCustomCodeChange() {
        this.components(this.get(customCodeKey));
      },
      isComponent() {
        return false;
      },
    },
    view: defaultType.view.extend({
      events: {
        dblclick: 'onActive',
      },

      init() {
        this.listenTo(
          this.model.components(),
          'add remove reset',
          this.onComponentsChange,
        );
        this.onComponentsChange();
      },

      /**
       * Things to do once inner components of custom code are changed
       */
      onComponentsChange() {
        if (timedInterval) {
          clearInterval(timedInterval);
        }

        timedInterval = setTimeout(() => {
          const { model } = this;
          const content = model.get(customCodeKey) || '';
          let droppable = true;

          // Avoid rendering codes with scripts
          if (content.indexOf('<script') >= 0) {
            this.el.innerHTML = this.el.innerHTML.replace(
              /<script[\s\S]*?>[\s\S]*?<\/script[\s\S]*?>/gm,
              `<div style="pointer-events: none; padding: 10px;">
            <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
              <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
              </svg>
            Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
          </div>`,
            );
            droppable = false;
          }

          model.set({ droppable });
        }, 0);
      },

      onActive() {
        const target = this.model;
        editCustomCode &&
          this.em.get('Commands').run('custom-code-modal', { target });
      },
    }),
  });
};

const addFormDataTable = (editor, { formDataTableType }) => {
  const domc = editor.DomComponents;

  domc.addType(formDataTableType, {
    model: {
      defaults: {
        name: 'Form Data Table',
        attributes: { class: 'form-data-table' },
        type: formDataTableType,
        openTraitsOnSelect: false,
        droppable: false,
        stylable: false,
        copyable: false,
        editable: false,
        layerable: false,
        hoverable: false,
        resizable: false,
        traits: [
          {
            name: 'leftcolumntext',
            label: 'Left column text',
          },
          {
            name: 'rightcolumntext',
            label: 'Right column text',
          },
          {
            type: 'color',
            name: 'bordercolor',
            label: 'Border color',
          },
          {
            type: 'number',
            name: 'borderwidth',
            label: 'Border width',
            min: 1,
            max: 10,
            step: 1,
          },
          {
            type: 'number',
            name: 'rowspadding',
            label: 'Rows padding',
            min: 10,
            max: 100,
            step: 1,
          },
          {
            type: 'number',
            name: 'fontsize',
            label: 'Font size',
            min: 11,
            max: 48,
            step: 1,
          },
          {
            type: 'select',
            label: 'Text align',
            name: 'textalign',
            default: 'center',
            options: [
              { id: 'center', name: 'Center' },
              { id: 'left', name: 'Left' },
              { id: 'right', name: 'Right' },
            ],
          },
          {
            type: 'color',
            name: 'textcolor',
            label: 'Text color',
          },
          {
            type: 'number',
            name: 'margintop',
            label: 'Margin top',
            min: 0,
            max: 400,
            step: 1,
          },
          {
            type: 'number',
            name: 'marginbottom',
            label: 'Margin bottom',
            min: 0,
            max: 400,
            step: 1,
          },
        ],
      },
    },
    view: {
      init() {
        this.listenTo(this.model, 'remove', this.RemoveBlock);
        this.listenTo(
          this.model,
          'change:attributes:leftcolumntext',
          this.updateLeftColText,
        );
        this.listenTo(
          this.model,
          'change:attributes:rightcolumntext',
          this.updateRightColText,
        );
        this.listenTo(
          this.model,
          'change:attributes:bordercolor',
          this.updateBorderColor,
        );
        this.listenTo(
          this.model,
          'change:attributes:borderwidth',
          this.updateBorderWidth,
        );
        this.listenTo(
          this.model,
          'change:attributes:rowspadding',
          this.updateRowsPadding,
        );
        this.listenTo(
          this.model,
          'change:attributes:fontsize',
          this.updateFontSize,
        );
        this.listenTo(
          this.model,
          'change:attributes:textalign',
          this.updateTextAlign,
        );
        this.listenTo(
          this.model,
          'change:attributes:textcolor',
          this.updateTextColor,
        );
        this.listenTo(
          this.model,
          'change:attributes:margintop',
          this.updateMarginTop,
        );
        this.listenTo(
          this.model,
          'change:attributes:marginbottom',
          this.updateMarginBottom,
        );
      },
      RemoveBlock() {
        const wrapper = editor?.getWrapper();
        const countFormDataTable =
          wrapper.findType('form-data-table').length + 1;
        const css = editor?.Css;

        if (countFormDataTable === 1) {
          css.remove(css.getRule('.form-data-table'));
          css.remove(css.getRule('.form-data-table th'));
          css.remove(css.getRule('.form-data-table th, .form-data-table td'));
          css.remove(
            css.getRule('div[data-gjs-type="wrapper"] .form-data-table'),
          );
          css.remove(
            css.getRule('div[data-gjs-type="wrapper"] .form-data-table *'),
          );
          css.remove(
            css.getRule(
              '.form-data-table th, .form-data-table tr:not(:last-child) td, .form-data-table tr:last-child td:first-child',
              {
                atRuleType: 'media',
                atRuleParams: '(max-width: 768px)',
              },
            ),
          );
        }
      },
      updateLeftColText(model, leftcolumntext) {
        model.addAttributes({ leftcolumntext });

        const selectedItem = editor.getSelected();

        selectedItem.find('th')[0].components(leftcolumntext);
      },
      updateRightColText(model, rightcolumntext) {
        model.addAttributes({ rightcolumntext });

        const selectedItem = editor.getSelected();

        selectedItem.find('th')[1].components(rightcolumntext);
      },
      updateBorderColor(model, bordercolor) {
        model.addAttributes({ bordercolor });

        const selector = getFormDataTableRowSelector(model.getId());

        const style = {
          'border-top-color': bordercolor,
          'border-bottom-color': bordercolor,
          'border-left-color': bordercolor,
          'border-right-color': bordercolor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateBorderWidth(model, borderwidth) {
        model.addAttributes({ borderwidth });

        const selector = getFormDataTableRowSelector(model.getId());

        const style = {
          'border-top-width': `${borderwidth}px`,
          'border-bottom-width': `${borderwidth}px`,
          'border-left-width': `${borderwidth}px`,
          'border-right-width': `${borderwidth}px`,
        };

        setRuleStyle(editor, selector, style);
      },
      updateRowsPadding(model, rowspadding) {
        model.addAttributes({ rowspadding });

        const selector = getFormDataTableRowSelector(model.getId());

        const style = {
          'padding-top': `${rowspadding}px`,
          'padding-bottom': `${rowspadding}px`,
          'padding-right': `${rowspadding}px`,
          'padding-left': `${rowspadding}px`,
        };

        setRuleStyle(editor, selector, style);
      },
      updateFontSize(model, fontsize) {
        model.addAttributes({ fontsize });

        const selector = getFormDataTableRowSelector(model.getId());

        const style = {
          'font-size': `${fontsize}px`,
        };

        setRuleStyle(editor, selector, style);
      },
      updateTextAlign(model, textalign) {
        model.addAttributes({ textalign });

        const selector = getFormDataTableRowSelector(model.getId());

        const style = {
          'text-align': textalign,
        };

        setRuleStyle(editor, selector, style);
      },
      updateTextColor(model, textcolor) {
        model.addAttributes({ textcolor });

        const selector = getFormDataTableRowSelector(model.getId());

        const style = {
          color: textcolor,
        };

        setRuleStyle(editor, selector, style);
      },
      updateMarginTop(model, margintop) {
        model.addAttributes({ margintop });

        const selector = getFormDataTableSelector(model.getId());

        const style = {
          'margin-top': `${margintop}px`,
        };

        setRuleStyle(editor, selector, style);
      },
      updateMarginBottom(model, marginbottom) {
        model.addAttributes({ marginbottom });

        const selector = getFormDataTableSelector(model.getId());

        const style = {
          'margin-bottom': `${marginbottom}px`,
        };

        setRuleStyle(editor, selector, style);
      },
    },
    isComponent(el) {
      if (checkComponentType(el, formDataTableType)) {
        return { type: formDataTableType };
      }
    },
  });
};

const addQrCode = (editor, { qrCodeType }, dispatch, templateId) => {
  const domc = editor.DomComponents;

  domc.addType(qrCodeType, {
    model: {
      defaults: {
        name: 'QR Code',
        attributes: {
          class: 'qr-code',
          'qr-code-url': '{$QRCODE}',
          'waymore-use': true,
        },
        type: qrCodeType,
        openTraitsOnSelect: false,
        droppable: false,
        stylable: false,
        editable: false,
        resizable: false,
        traits: [
          {
            type: 'checkbox',
            label: 'Use QR code for Waymore Email/Campaign manager',
            name: 'waymore-use',
            valueTrue: true,
            valueFalse: false,
          },
          {
            name: 'qr-code-url',
            label: 'URL',
            attributes: { style: 'display: none' },
          },
        ],
      },
      init() {
        const waymoreUseTrait = this.getTrait('waymore-use');
        const qrcodeurlTrait = this.getTrait('qr-code-url');

        if (qrcodeurlTrait) {
          qrcodeurlTrait.set('attributes', {
            style: `display: ${
              waymoreUseTrait.get('value') ? 'none' : 'block'
            }`,
          });
        }

        this.on('change:attributes:qr-code-url', this.updateQrUrl);
        this.on('change:attributes:waymore-use', this.updateWaymoreUse);
      },
      async updateQrUrl(model, qrCodeUrl) {
        model.addAttributes({ 'qr-code-url': qrCodeUrl });
        const { 'waymore-use': waymoreUse } = model.getAttributes();
        const res = qrCodeUrl.match(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g,
        );

        if (!waymoreUse) {
          if (res == null) {
            model.addAttributes({
              src: 'https://cdn11.waymore.io/dnd/images/qr-code.png',
            });
            showErrorMessage('Invalid URL. Please enter a valid web address.');
          } else {
            try {
              const base64Image = await QRCode.toDataURL(qrCodeUrl);
              const rndNumber = new Date().valueOf();
              const randomQrImageName = `qr_image_${rndNumber}.jpg`;
              const base64Response = await fetch(base64Image);
              const blob = await base64Response.blob();
              const fileResult = new File([blob], randomQrImageName, {
                type: blob.type,
                lastModified: new Date(),
              });
              const formData = new FormData();
              formData.append('file', fileResult);
              await dispatch(uploadAssets({ payload: formData }));
              await dispatch(
                copyFromGlobalToTemplateAssets({
                  id: templateId,
                  imageName: randomQrImageName,
                }),
              );
              model.addAttributes({
                src: buildAssetsUrl(templateId, randomQrImageName),
              });
            } catch (err) {
              showErrorMessage(getErrorText());
            }
          }
        }
      },
      updateWaymoreUse(model, wmUse) {
        const qrcodeurlTrait = model.getTrait('qr-code-url');

        if (qrcodeurlTrait) {
          qrcodeurlTrait.view.$el[0].style.display = wmUse ? 'none' : 'block';
          qrcodeurlTrait.setTargetValue(wmUse ? '{$QRCODE}' : '');
        }

        if (wmUse) {
          model.addAttributes({
            src: 'https://cdn11.waymore.io/dnd/images/qr-code.png',
          });
        }

        model.addAttributes({ 'waymore-use': wmUse });
      },
    },
    isComponent(el) {
      if (checkComponentType(el, qrCodeType)) {
        return { type: qrCodeType };
      }
    },
  });
};

const adddiscountCode = (editor, { discountCodeType }) => {
  const domc = editor.DomComponents;

  domc.addType(discountCodeType, {
    model: {
      defaults: {
        name: 'Discount code',
        droppable: false,
        draggable: true,
        resizable: false,
        openTraitsOnSelect: true,
        discountCode: 'SAMPLECODE',
        width: '',
        codeFontSize: 24,
        codeColor: '#000',
        codeBgColor: '#edf2ff',
        codeBorderButtonColor: '#3258b3',
        codeButtonTextColor: '#fff',
        traits: [
          {
            type: 'text',
            name: 'discountCode',
            label: 'Discount code',
            placeholder: 'Enter your code',
            changeProp: true,
          },
          {
            type: 'number',
            name: 'width',
            label: 'Width',
            changeProp: true,
          },
          {
            type: 'number',
            name: 'codeFontSize',
            label: 'Discount font size',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeColor',
            label: 'Code color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeBgColor',
            label: 'Background color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeBorderButtonColor',
            label: 'Border/button color',
            changeProp: true,
          },
          {
            type: 'color',
            name: 'codeButtonTextColor',
            label: 'Button text color',
            changeProp: true,
          },
        ],
        attributes: {
          class: 'discount-code',
          'data-type': 'discount-code',
        },
        components: `
          <input type="text" class="discount-code__code" readonly value="SAMPLECODE">
        `,
        styles: `
          .discount-code {
            font-size: 24px;
            width: fit-content;
            max-width: 100%;
            text-transform: uppercase;
            padding: 6px;
            color: #000;
            background-color: #edf2ff;
            border: 1px dashed #3258b3;
            border-radius: 4px;
          }
          .discount-code__code {
            vertical-align: middle;
            margin: 0 4px;
            text-align: center;
            padding: 0;
            border: 0;
            background: transparent;
            border-radius: 0;
            overflow: hidden;
            letter-spacing: 6px;
            font-weight: bold;
            font-size: 1em;
            width: auto
            text-transform: uppercase;
            color: inherit;
          }
          .discount-code__code:focus {
            outline: none !important;
            border: none !important;
          }
          .discount-code__btn {
            vertical-align: middle;
            text-transform: uppercase;
            color: #fff;
            background-color: #3258b3;
            border: none;
            border-radius: 4px;
            padding: 4px 16px;
            margin: 0 0 0 8px;
            font-size: 0.6em;
            font-weight: bold;
            line-height: 24px;
            box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            transition: all 0.5s;
          }
          .discount-code__btn:hover {
            background-color: #487eff;
          }
          @media (max-width: 480px) {
            .discount-code,
            .discount-code__code {
              width: 100%;
              max-width: 100%;
              text-align: center;
            }
            .discount-code__btn {
              margin-top: 8px
            }
          }
        `,
      },
      init() {
        this.on('change:discountCode', this.updateDiscountCode);
        this.on('change:width', this.updateWidth);
        this.on('change:codeFontSize', this.updateCodeSize);
        this.on('change:codeColor', this.updateCodeColor);
        this.on('change:codeBgColor', this.updateCodeBgColor);
        this.on('change:codeButtonTextColor', this.updateCodeButtonTextColor);
        this.on(
          'change:codeBorderButtonColor',
          this.updateCodeBorderButtonColor,
        );
      },
      updateDiscountCode(cmp, value) {
        const discountCodeInput = this.find('input');

        if (discountCodeInput && discountCodeInput?.length > 0) {
          discountCodeInput[0].addAttributes({ value });
        }
      },
      updateWidth(cmp, value) {
        const discountCodeInput = this.find('input');
        const style = {
          width: `${value}px`,
        };

        if (discountCodeInput && discountCodeInput?.length > 0) {
          setRuleStyle(editor, `#${discountCodeInput[0].getId()}`, style);
        }
      },
      updateCodeSize(cmp, value) {
        const style = {
          'font-size': `${value}px`,
        };
        const buttonStyle = {
          'line-height': `${value}px`,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
        setRuleStyle(editor, `#${cmp.getId()} > button`, buttonStyle);
      },
      updateCodeColor(cmp, value) {
        const style = {
          color: value,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
      },
      updateCodeBgColor(cmp, value) {
        const style = {
          'background-color': value,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
      },
      updateCodeBorderButtonColor(cmp, value) {
        const style = {
          border: `1px dashed ${value}`,
        };
        const buttonStyle = {
          'background-color': value,
        };

        setRuleStyle(editor, `#${cmp.getId()}`, style);
        setRuleStyle(editor, `#${cmp.getId()} > button`, buttonStyle);
      },
      updateCodeButtonTextColor(cmp, value) {
        const style = {
          color: value,
        };

        setRuleStyle(editor, `#${cmp.getId()} > button`, style);
      },
    },
    isComponent(el) {
      if (checkComponentType(el, discountCodeType)) {
        return { type: discountCodeType };
      }
    },
  });
};

export default (editor, config, dispatch, templateId) => {
  addCustomCode(editor, config);
  addFormDataTable(editor, config);
  addQrCode(editor, config, dispatch, templateId);
  adddiscountCode(editor, config);
};
