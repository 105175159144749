import React from 'react';
import { FONTS } from 'appConstants';
import { FormattedMessage } from 'react-intl';
import { useEditorContext } from 'routes/Editor/EditorContext';
import Switch from 'react-switch';

function General() {
  const {
    generalSettings: {
      headerFonts,
      paragraphFonts,
      lineSpacing,
      paragraphBottomSpace,
      underlineLinks,
    },
    onChangeGeneralSettings,
  } = useEditorContext();

  const handleChange = ({ target: { name, value } }) => {
    onChangeGeneralSettings(name, value);
  };

  return (
    <div className="general-settings-modal__content">
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.general-tab.header-fonts.title" />
          </div>
          <div className="general-settings-modal__form-item font-select">
            <label>
              <FormattedMessage id="general-settings-modal.general-tab.header-fonts.select-title" />
              <select
                name="headerFonts"
                value={headerFonts}
                onBlur={handleChange}
                onChange={handleChange}>
                {FONTS.map((font) => (
                  <option key={font.name} value={font.value}>
                    {font.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.general-tab.header-fonts.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.general-tab.header-fonts.helper-description" />
          </p>
        </div>
      </div>
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.general-tab.paragraph-fonts.title" />
          </div>
          <div className="general-settings-modal__form-item font-select">
            <label>
              <FormattedMessage id="general-settings-modal.general-tab.paragraph-fonts.select-title" />
              <select
                name="paragraphFonts"
                value={paragraphFonts}
                onBlur={handleChange}
                onChange={handleChange}>
                {FONTS.map((font) => (
                  <option key={font.name} value={font.value}>
                    {font.name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.general-tab.paragraph-fonts.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.general-tab.paragraph-fonts.helper-description" />
          </p>
        </div>
      </div>
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.general-tab.line-spacing-fonts.title" />
          </div>
          <div className="general-settings-modal__form-item line-spacing">
            {['100%', '120%', '150%', '200%'].map((item, index) => (
              <label
                key={index}
                className={lineSpacing === item ? 'active' : ''}>
                {item.label}
                <span className={`icon icon-line-spacing-${index + 1}x`} />
                <input
                  name="lineSpacing"
                  type="radio"
                  value={item}
                  checked={lineSpacing === item}
                  onChange={handleChange}
                />
              </label>
            ))}
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.general-tab.line-spacing-fonts.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.general-tab.line-spacing-fonts.helper-description" />
          </p>
        </div>
      </div>
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.general-tab.paragraph-bottom-spacing.title" />
          </div>
          <div className="general-settings-modal__form-item paragraph-bottom-space">
            <Switch
              onChange={(checked) =>
                onChangeGeneralSettings('paragraphBottomSpace', checked)
              }
              checked={paragraphBottomSpace}
              onColor="#f1f9ff"
              onHandleColor="#cbe9ff"
              boxShadow="0 1px 2px rgba(0, 0, 0, 0.3)"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.general-tab.paragraph-bottom-spacing.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.general-tab.paragraph-bottom-spacing.helper-description" />
          </p>
        </div>
      </div>
      <div className="general-settings-modal__content-item">
        <div className="general-settings-modal__form-item-wrapper">
          <div className="general-settings-modal__form-item-label">
            <FormattedMessage id="general-settings-modal.general-tab.underline-links.title" />
          </div>
          <div className="general-settings-modal__form-item paragraph-bottom-space">
            <Switch
              onChange={(checked) =>
                onChangeGeneralSettings('underlineLinks', checked)
              }
              checked={underlineLinks}
              onColor="#f1f9ff"
              onHandleColor="#cbe9ff"
              boxShadow="0 1px 2px rgba(0, 0, 0, 0.3)"
              handleDiameter={30}
              uncheckedIcon={false}
              checkedIcon={false}
              height={20}
              width={48}
            />
          </div>
        </div>
        <div className="general-settings-modal__description-wrapper">
          <h4 className="general-settings-modal__description-header">
            <FormattedMessage id="general-settings-modal.general-tab.underline-links.helper-title" />
          </h4>
          <p className="general-settings-modal__description">
            <FormattedMessage id="general-settings-modal.general-tab.underline-links.helper-description" />
          </p>
        </div>
      </div>
    </div>
  );
}

export default General;
