import { isValidURL } from 'helpers';

const addSocialLinkTrait = (editor, { socialLinkTraitType }) => {
  editor.TraitManager.addType(socialLinkTraitType, {
    eventCapture: ['input'],

    onEvent({ elInput, component }) {
      const value = elInput.value;
      const network = component.attributes.attributes.network;
      let href = '';

      if (!isValidURL(value)) {
        if (network) {
          if (network === 'facebook') {
            href = 'https://www.facebook.com/' + value;
          }
          if (network === 'x') {
            href = 'http://x.com/' + value;
          }
          if (network === 'vk') {
            href = 'https://vk.com/' + value;
          }
          if (network === 'instagram') {
            href = 'https://instagram.com/' + value;
          }
          if (network === 'linkedin') {
            href = 'https://linkedin.com/' + value;
          }
          if (network === 'youtube') {
            href = 'https://youtube.com/' + value;
          }
          if (network === 'pin') {
            href = 'https://pinterest.com/' + value;
          }
        }
      } else {
        href = value;
      }

      component.addAttributes({ href, target: '_blank' });
    },
  });
};

export default (editor, config) => {
  addSocialLinkTrait(editor, config);
};
