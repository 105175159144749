import { REDIRECT_URL } from 'appConstants';

export default (editor, { wayMoreProductType, waymoreIntegration }) => {
  const bm = editor.BlockManager;

  bm.add(wayMoreProductType, {
    label: 'Add products directly from your website',
    activate: true,
    category: 'WayMore Products',
    content: { type: wayMoreProductType },
    attributes: {
      class: `bpreview-products-integration gjs-thumb-label--big ${
        !waymoreIntegration ? 'gjs-block--lock' : ''
      }`,
    },
    disable: !waymoreIntegration,
    media: !waymoreIntegration
      ? `<a target="_blank" title="Not enabled in your plan" href="${REDIRECT_URL}/settings/subscriptions" draggable="false"><span class="icon icon-lock"></span></a>`
      : '',
  });
};
