import {
  SOCIAL_MESSENGER_ICONS,
  SOCIAL_OTHER_ICONS,
  SOCIAL_PREVIEW_ICONS,
  SOCIAL_SOCIAL_ICONS,
  SOCIAL_STYLES,
} from 'appConstants';

export const iconTraits = () => {
  return [
    {
      type: 'checkbox',
      label: `Enable icon`,
      name: 'data-button-icon-enable',
      default: false,
    },
    {
      id: 'change-button-icon',
      name: 'changeButtonIcon',
      type: 'button',
      text: 'Select icon',
      full: true,
      command: (editor) => {
        editor.runCommand('open-assets', {
          isIcon: true,
          isButtonIcon: true,
        });
      },
    },
    {
      type: 'select',
      label: 'Icon align',
      name: 'data-button-icon-align',
      default: 'left',
      options: [
        { name: 'Left', value: 'left' },
        { name: 'Right', value: 'right' },
      ],
    },
    {
      type: 'number',
      label: 'Icon size',
      name: 'data-button-icon-size',
      default: 32,
    },
    {
      type: 'number',
      label: 'Icon size indent',
      name: 'data-button-icon-indent',
      default: 0,
    },
  ];
};

export const linkTraits = () => {
  return [
    {
      type: 'text',
      name: 'href',
      label: 'Link (URL)',
    },
    {
      type: 'select',
      name: 'target',
      label: 'target',
      default: '_self',
      options: [
        { id: '_self', name: 'This window' },
        { id: '_blank', name: 'New window' },
      ],
    },
  ];
};

export const changeHrefTarget = (el) => {
  if (el.parent().getView().model.get('tagName') === 'a') {
    let model = el.parent().getView().model;
    let attributes = {
      href: el.changed.attributes.href,
      target: el.changed.attributes.target || '_self',
    };

    let newLink = {
      type: 'link',
      attributes,
      components: [el.clone()],
    };

    model.replaceWith(newLink);
  } else {
    let model = el.getView().model;
    let attributes = {
      href: el.changed.attributes.href,
      target: el.changed.attributes.target,
    };

    let newLink = {
      type: 'link',
      attributes,
      components: [model.clone()],
    };

    model.replaceWith(newLink);
  }
};

export const addCustomTypes = (editor) => {
  editor.TraitManager.addType('text-next', {
    templateInput: `<div class="custom-input-wrapper">
      <div data-input></div>
    </div>`,
    eventCapture: ['input'],
  });

  editor.TraitManager.addType('content', {
    events: {
      keyup: 'onChange',
    },
    getInputEl() {
      if (!this.inputEl) {
        const input = document.createElement('textarea');
        const targetElementValue = this.target
          .getView()
          .model.components()
          .models[0].get('content');

        input.value = targetElementValue;
        input.setAttribute('placeholder', '');
        input.style.background = 'white';
        input.style.color = 'black';
        input.style.width = '100%';

        this.inputEl = input;
      }

      return this.inputEl;
    },
    onValueChange() {
      this.target
        .getView()
        .model.components()
        .models[0].set('content', this.model.get('value'));

      editor.getSelected().view.render();
    },
  });

  editor.TraitManager.addType('full-width', {
    events: {
      click: 'onClick',
      keyup: 'onChange',
    },
    getInputEl() {
      if (!this.inputEl) {
        const button = document.createElement('button');
        const input = document.createElement('input');
        const div = document.createElement('div');

        button.innerText = 'Full';

        div.appendChild(button);
        div.appendChild(input);

        input.value = this.target.get('content');
        this.inputEl = div;
      }
      return this.inputEl;
    },
    onClick(e) {
      if (e.target.tagName === 'BUTTON') {
        this.target.setStyle({
          ...this.target.getStyle(),
          width: '100%',
          height: '100%',
        });
      }
    },
    onChange(e) {
      if (e.target.tagName === 'INPUT') {
        const val = e.target.value.replace(/\D/g, '');

        if (e.target.value !== val) {
          e.target.value = val;
        }

        this.target.setStyle({
          ...this.target.getStyle(),
          width: `${e.target.value}px`,
          height: `${e.target.value}px`,
        });
      }
    },
  });

  editor.TraitManager.addType('video-play-buttons', {
    eventCapture: ['click'],
    createInput() {
      const el = document.createElement('div');
      el.className = 'video-play-buttons-component';
      el.innerHTML = `
          <button data-video-play-button="none" class="selected">
            <span class="icon icon-close"></span>
          </button>
          ${[
            'video-placement-1',
            'video-placement-2',
            'video-placement-3',
            'video-placement-4',
            'video-placement-5',
            'video-placement-6',
            'video-placement-7',
            'video-placement-8',
          ]
            .map(
              (item, index) =>
                `<button data-video-play-button="${item}">
                  <img
                    src="https://cdn11.waymore.io/dnd/images/video_images/${item}.png"
                    alt="Play button #${index + 1}"
                  />
                </button>`,
            )
            .join('')}
      `;

      const allVideoPlayIcons = el.querySelectorAll(
        '.video-play-buttons-component > button',
      );
      allVideoPlayIcons.forEach((item) => {
        item.addEventListener('click', function () {
          allVideoPlayIcons.forEach((item) =>
            item.classList.remove('selected'),
          );
          this.classList.add('selected');
        });
      });

      return el;
    },
    onEvent({ elInput, component, trait }) {
      const name = trait?.get('name');
      const allVideoPlayIcons = elInput.querySelectorAll(
        '.video-play-buttons-component > button',
      );

      allVideoPlayIcons.forEach((item) => {
        if (item.classList.contains('selected')) {
          if (name) {
            component.addAttributes({
              [name]: item?.dataset?.videoPlayButton,
            });
          }
        }
      });
    },
    onUpdate({ elInput, component, trait }) {
      const name = trait?.get('name');

      if (name) {
        const videoPlayIcon = component.getAttributes()[name];
        const allVideoPlayIcons = elInput.querySelectorAll(
          '.video-play-buttons-component > button',
        );

        if (videoPlayIcon) {
          allVideoPlayIcons.forEach((item) => {
            if (item?.dataset?.videoPlayButton === videoPlayIcon) {
              item.classList.add('selected');
            } else {
              item.classList.remove('selected');
            }
          });
        }
      }
    },
  });

  editor.TraitManager.addType('hide-element', {
    eventCapture: ['click'],
    createInput() {
      const el = document.createElement('div');
      el.className = 'hide-element-component';
      el.innerHTML = `
        ${['no', 'tablet', 'mobile']
          .map(
            (item) =>
              `
              <button
                value="${item}"
                data-hide-elem-button="${item}">
                <span class="icon icon-${
                  item === 'tablet'
                    ? 'tablet-mac'
                    : item === 'mobile'
                    ? 'phone-iphone'
                    : 'close'
                }"></span>
              </button>
              `,
          )
          .join('')}
      `;

      const allDeviceButtons = el.querySelectorAll(
        '.hide-element-component > button',
      );
      allDeviceButtons.forEach((item) => {
        item.addEventListener('click', function () {
          allDeviceButtons.forEach((item) => item.classList.remove('selected'));
          this.classList.add('selected');
        });
      });

      return el;
    },
    onEvent({ elInput, component, trait }) {
      const name = trait?.get('name');
      const allDeviceButtons = elInput.querySelectorAll(
        '.hide-element-component > button',
      );

      allDeviceButtons.forEach((item) => {
        if (item.classList.contains('selected')) {
          const selectedDevice = item?.dataset?.hideElemButton;

          if (name) {
            component.set(`${name}`, selectedDevice);
          }

          editor
            .getSelected()
            .removeClass([
              'dnd-hide-element-mobile',
              'dnd-hide-element-tablet',
            ]);

          if (selectedDevice === 'mobile' || selectedDevice === 'tablet') {
            editor.getSelected().addClass(`dnd-hide-element-${selectedDevice}`);
          }
        }
      });
    },
    onUpdate({ elInput, component, trait }) {
      const name = trait?.get('name');

      if (name) {
        const deviceName = component.get(`${name}`) || 'no';
        const allDeviceButtons = elInput.querySelectorAll(
          '.hide-element-component > button',
        );

        if (deviceName) {
          allDeviceButtons.forEach((item) => {
            if (item?.dataset?.hideElemButton === deviceName) {
              item.classList.add('selected');
            } else {
              item.classList.remove('selected');
            }
          });
        }
      }
    },
  });

  editor.TraitManager.addType('add-social-icons', {
    eventCapture: ['click'],
    createInput() {
      const el = document.createElement('div');
      el.className = 'add-social-icons-component';
      el.innerHTML = `
        <div class="tabset">
          <input type="radio" name="tabset" id="tab1" aria-controls="social-icons" checked>
          <label for="tab1">Social</label>
          <input type="radio" name="tabset" id="tab2" aria-controls="messenger-icons">
          <label for="tab2">Chat</label>
          <input type="radio" name="tabset" id="tab3" aria-controls="other-icons">
          <label for="tab3">Other</label>
          <div class="tab-panels">
            <section id="social-icons" class="tab-panel">
            ${SOCIAL_SOCIAL_ICONS.map(
              ({ iconName, iconTitle }) =>
                `<button data-add-social-icon-button="${iconName}" data-social-cat="social-icons" title="${iconTitle}">
                      <img
                        src="https://cdn11.waymore.io/dnd/images/social/social-icons/${SOCIAL_STYLES[2]}/${iconName}-${SOCIAL_STYLES[2]}.png"
                        alt="${iconTitle}"
                      />
                  </button>`,
            ).join('')}
            </section>
            <section id="messenger-icons" class="tab-panel">
            ${SOCIAL_MESSENGER_ICONS.map(
              ({ iconName, iconTitle }) =>
                `<button data-add-social-icon-button="${iconName}" data-social-cat="messenger-icons" title="${iconTitle}">
                      <img
                        src="https://cdn11.waymore.io/dnd/images/social/messenger-icons/${SOCIAL_STYLES[2]}/${iconName}-${SOCIAL_STYLES[2]}.png"
                        alt="${iconTitle}"
                      />
                  </button>`,
            ).join('')}
            </section>
            <section id="other-icons" class="tab-panel">
              ${SOCIAL_OTHER_ICONS.map(
                ({ iconName, iconTitle }) =>
                  `<button data-add-social-icon-button="${iconName}" data-social-cat="other-icons" title="${iconTitle}">
                        <img
                          src="https://cdn11.waymore.io/dnd/images/social/other-icons/${SOCIAL_STYLES[2]}/${iconName}-${SOCIAL_STYLES[2]}.png"
                          alt="${iconTitle}"
                        />
                    </button>`,
              ).join('')}
            </section>
          </div>
        </div>
      `;

      const allAddSocialIcons = el.querySelectorAll(
        '.add-social-icons-component .tab-panel > button',
      );

      if (allAddSocialIcons) {
        allAddSocialIcons.forEach((button) =>
          button.addEventListener('click', function () {
            const iconName = this?.dataset?.addSocialIconButton;
            const iconCat = this?.dataset?.socialCat;
            const defaultIconSize =
              editor
                .getSelected()
                .getTrait('data-social-icon-size')
                .get('value') || '32';
            const iconCurrentStyle = editor
              .getSelected()
              .get('attributes')
              ['data-social-icon-style'].replace('preview-32-', '');

            const newAddedItem = editor
              .getSelected()
              .components()
              .add(
                {
                  type: 'social-icon',
                  attributes: {
                    'data-type': 'social-icon',
                    href: '',
                    target: '_blank',
                    title: '',
                    style: 'display: inline-block',
                  },
                  components: {
                    type: 'image',
                    attributes: {
                      width: defaultIconSize,
                      height: defaultIconSize,
                      src: `https://cdn11.waymore.io/dnd/images/social/${iconCat}/${iconCurrentStyle}/${iconName}-${iconCurrentStyle}.png`,
                      alt: `${iconName}`,
                    },
                  },
                },
                { at: -1 },
              );

            newAddedItem.setStyle({
              display: 'inline-block',
              'margin-left': '4px',
              'margin-right': '4px',
            });
            newAddedItem.findType('image')[0].set({
              editable: false,
              collection: false,
              droppable: false,
              draggable: false,
              badgable: false,
              copyable: false,
              removable: false,
              selectable: false,
              hoverable: false,
              resizable: false,
            });

            editor.refresh();
          }),
        );
      }

      return el;
    },
  });

  editor.TraitManager.addType('style-social-icons', {
    eventCapture: ['click'],
    createInput() {
      const el = document.createElement('div');
      el.className = 'style-social-icons-component';
      el.innerHTML = `
          ${SOCIAL_PREVIEW_ICONS.map(
            ({ iconName, iconTitle }) =>
              `<button data-style-social-icon-button="${iconName}">
                  <img
                    src="https://cdn11.waymore.io/dnd/images/social/preview/${iconName}.png"
                    alt="${iconTitle}"
                  />
                  <span>${iconTitle}</span>
                </button>`,
          ).join('')}
      `;

      const allSocialStyleIcons = el.querySelectorAll(
        '.style-social-icons-component > button',
      );
      allSocialStyleIcons.forEach((item) => {
        item.addEventListener('click', function () {
          allSocialStyleIcons.forEach((item) =>
            item.classList.remove('selected'),
          );
          this.classList.add('selected');
        });
      });

      return el;
    },
    onEvent({ elInput, component, trait }) {
      const name = trait?.get('name');
      const allSocialStyleIcons = elInput.querySelectorAll(
        '.style-social-icons-component > button',
      );

      allSocialStyleIcons.forEach((item) => {
        if (item.classList.contains('selected')) {
          if (name) {
            component.addAttributes({
              [name]: item?.dataset?.styleSocialIconButton,
            });
          }
        }
      });
    },
    onUpdate({ elInput, component, trait }) {
      const name = trait?.get('name');

      if (name) {
        const socialStyleIcon = component.getAttributes()[name];
        const allSocialStyleIcons = elInput.querySelectorAll(
          '.style-social-icons-component > button',
        );

        if (socialStyleIcon) {
          allSocialStyleIcons.forEach((item) => {
            if (item?.dataset?.styleSocialIconButton === socialStyleIcon) {
              item.classList.add('selected');
            } else {
              item.classList.remove('selected');
            }
          });
        }
      }
    },
  });

  editor.TraitManager.addType('custom-datetime', {
    createInput({ trait }) {
      const { name } = trait.attributes;
      const el = document.createElement('input');

      el.type = 'datetime-local';
      el.name = name;
      el.step = '1';
      el.value = trait.target.get(trait.name) || '';
      el.addEventListener('change', (event) => {
        trait.target.set(trait.name, event.target.value);
      });

      return el;
    },
    onEvent({ component, event }) {
      component.addAttributes({ value: event.target.value });
    },
    onUpdate({ elInput, component }) {
      if (component.get('attributes')) {
        elInput.value = component.get('attributes')?.value || '';
      }
    },
  });
};
